import React, { Component } from 'react'

class TempTable extends Component {
  render() {
    let displayTable = null
    if (this.props.entriesData.length > 0) {
      displayTable = (
        <div id="temp-table">
          {this.props.entriesData.map(item => {
            const isInvoices =
              item.name === 'Accounts Receivables Invoices' ||
              item.name === 'Accounts Payable Invoices'
            if (item.type === 'sub') {
              return (
                <div
                  key={item.name}
                  className={`temp-table-container ${
                    isInvoices ? '' : 'row-display'
                  }`}
                >
                  <div className="temp-table-text">{item.name} (sub)</div>
                  <div className="sub-options">
                    <div
                      className="icon edit"
                      onClick={() => this.props.editSubHandler(item)}
                      title="Edit this sub-category"
                    >
                      <i className="fas fa-pencil-alt" />
                    </div>
                    <div
                      className="icon edit"
                      onClick={() => this.props.deleteSubHandler(item)}
                      title="Delete this sub-category"
                    >
                      <i className="fas fa-trash-alt" />
                    </div>
                    {isInvoices ? (
                      <button
                        onClick={() =>
                          this.props.editListHandler(item, isInvoices)
                        }
                        className="add-sub"
                      >
                        Edit List
                      </button>
                    ) : (
                      <div
                        className="icon add"
                        onClick={() =>
                          this.props.editListHandler(item, isInvoices)
                        }
                        title="Add Transaction"
                      >
                        <i className="fas fa-plus" />
                      </div>
                    )}
                  </div>
                </div>
              )
            } else {
              return null
            }
          })}
        </div>
      )
    }

    return <div>{displayTable}</div>
  }
}

export default TempTable
