import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import classnames from 'classnames'

import {
  loginUser,
  clearErrors,
  setCurrentUser,
  setLoginCount
} from '../../store/actions'

import { connectingBaseLink, _storageActiveUser } from '../../helpers/constants'
import { GoogleLogin } from 'react-google-login'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { categoryData } from '../../helpers/data'
import AuthHeader from './AuthHeader'

class SignIn extends Component {
  constructor() {
    super()
    this.state = {
      signInEmail: '',
      signInPassword: '',
      showPasswordError: false,
      showEmailError: false,
      emailErrorMessage: '',
      passwordErrorMessage: '',
      validEmail: false,
      validPassword: false,
      showErrorLogin: false,
      errorMessage: '',
      disableButton: false
    }
    this.onEmailChange = this.onEmailChange.bind(this)
  }

  componentDidMount() {
    this.props.clearErrors()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push('/dashboard')
    }
    if (nextProps.errors.message) {
      let data = nextProps.errors.message

      if (data === 'wrong') {
        this.setState({
          showErrorLogin: true,
          errorMessage: 'Either email or password is incorrect'
        })
      } else if (data === 'nosuch') {
        this.setState({
          showErrorLogin: true,
          errorMessage: 'That user is not in our database. Go to Sign Up'
        })
      } else {
        this.setState({
          showErrorLogin: true,
          errorMessage: "Couldn't connect to server. Try again later"
        })
      }
    }
    this.setState({ disableButton: false })
  }

  onEmailChange = event => {
    let email = event.target.value

    if (email === '') {
      this.setState({
        showEmailError: true,
        emailErrorMessage: "Don't leave email field empty",
        validEmail: false
      })
    } else {
      this.setState({
        signInEmail: email,
        showEmailError: false,
        emailErrorMessage: '',
        validEmail: true
      })
    }
  }

  onPasswordChange = event => {
    let password = event.target.value

    if (password.length === 0) {
      this.setState({
        showPasswordError: true,
        passwordErrorMessage: "Don't leave password field empty",
        validPassword: false
      })
    } else {
      this.setState({
        signInPassword: password,
        showPasswordError: false,
        passwordErrorMessage: '',
        validPassword: true
      })
    }
  }

  onSubmitSignIn = event => {
    event.preventDefault()
    let validForm = this.state.validEmail && this.state.validPassword
    this.setState({ disableButton: true })

    if (validForm) {
      this.props.loginUser(this.state.signInEmail, this.state.signInPassword)
    }
  }

  /*********** GOOGLE LOGIN *************/

  onSuccess = response => {
    let profile = response.getBasicProfile()
    let loginEmail = profile.getEmail()
    let idToken = response.getAuthResponse().id_token

    this.loginWithEmail(loginEmail, idToken, true)
  }

  onFailure = response => {
    // do nothing
  }

  /*********** FACEBOOK LOGIN *************/
  fbResponse = response => {
    let loginEmail = response.email
    let authToken = response.accessToken

    if (
      typeof loginEmail !== 'undefined' &&
      loginEmail !== '' &&
      loginEmail.indexOf('@') > -1
    ) {
      this.loginWithEmail(loginEmail, authToken, false)
    } else {
      // do nothing
    }
  }

  loginWithEmail = (loginEmail, token, isGoogle) => {
    let categoryList = []
    for (let i = 0; i < categoryData.length; i++) {
      let temp = { user: loginEmail, category: categoryData[i].name }
      categoryList.push(temp)
    }

    let link = ''
    let errorString = ''
    if (isGoogle) {
      link = `${connectingBaseLink}home/createG.php`
      errorString = "Couldn't verify the validity of Google Login"
    } else {
      link = `${connectingBaseLink}home/createF.php`
      errorString = "Couldn't verify the validity of Facebook Login"
    }

    fetch(link, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email: loginEmail,
        id: token,
        categories: categoryList
      })
    })
      .then(response => response.json())
      .then(result => {
        let data = result.message
        if (data.hasOwnProperty('token')) {
          this.setState({
            showErrorLogin: false,
            errorMessage: ''
          })
          localStorage.setItem(_storageActiveUser, JSON.stringify(data.token))
          this.props.setCurrentUser(data.token)
          this.props.setLoginCount(data.token)
          this.props.history.push('/dashboard')
        } else if (data === 'invalid') {
          this.setState({
            showErrorLogin: true,
            errorMessage: errorString
          })
        } else {
          this.setState({
            showErrorLogin: true,
            errorMessage:
              'Some error occurred. Try again later or use our login system.'
          })
        }
      })
  }

  render() {
    const { showEmailError, showPasswordError, showErrorLogin } = this.state

    let classEmailWrap = 'input-wrap'
    let classPasswordWrap = 'input-wrap'
    if (showEmailError) {
      classEmailWrap = 'input-wrap error-color'
    }
    if (showPasswordError) {
      classPasswordWrap = 'input-wrap error-color'
    }

    return (
      <AuthHeader>
        <main id="loginForm">
          <span className="login-title">Login Form</span>

          <form onSubmit={event => this.onSubmitSignIn(event)} method="POST">
            {showErrorLogin ? (
              <div className="login-error">{this.state.errorMessage}</div>
            ) : null}

            <span className="label-input">Enter email</span>
            <div className={classEmailWrap}>
              <input
                type="email"
                className="input"
                name="email"
                required
                onChange={this.onEmailChange}
              />
            </div>
            {showEmailError ? (
              <div className="error-message">
                {this.state.emailErrorMessage}
              </div>
            ) : null}

            <span className="label-input">Enter password</span>
            <div className={classPasswordWrap}>
              <input
                className="input"
                type="password"
                name="pass"
                required
                onChange={this.onPasswordChange}
                autoComplete="off"
              />
            </div>
            {showPasswordError ? (
              <div className="error-message">
                {this.state.passwordErrorMessage}
              </div>
            ) : null}

            <div className="frpass">
              {/* <div className="checkbox">
								<input id="inputchk" type="checkbox" name="remember" />
								<label className="label-check" htmlFor="inputchk">
									Remember me
								</label>
							</div> */}
              <div>
                <Link to="/forgotpassword" className="link">
                  Forgot Password?
                </Link>
              </div>
            </div>

            <div className="button-container" style={{ paddingTop: '2rem' }}>
              <input
                type="submit"
                className={classnames('button-login', {
                  'button-disable': this.state.disableButton
                })}
                value="Login"
              />
            </div>
          </form>

          <div style={{ textAlign: 'center', paddingTop: '4rem' }}>
            <span style={{ pointerEvents: 'none' }}>Or Sign Up using</span>
          </div>

          <div
            className="social-button-container"
            style={{ paddingTop: '1rem' }}
          >
            <GoogleLogin
              className="social-button-login"
              render={renderProps => (
                <button
                  onClick={renderProps.onClick}
                  className="social-button-login"
                  style={{ background: '#6f9acd' }}
                >
                  Google
                </button>
              )}
              clientId="1007007465370-68vib21qkukc8osf4mtd47fi5ti6dso2.apps.googleusercontent.com"
              onSuccess={this.onSuccess}
              onFailure={this.onFailure}
            />
          </div>

          <div
            className="social-button-container"
            style={{ paddingTop: '1rem' }}
          >
            <FacebookLogin
              appId="624193737981352"
              fields="email"
              render={renderProps => (
                <button
                  className="social-button-login"
                  onClick={renderProps.onClick}
                  style={{ background: '#5b7ba0' }}
                >
                  Facebook
                </button>
              )}
              callback={this.fbResponse}
            />
          </div>

          <div style={{ textAlign: 'center', paddingTop: '10rem' }}>
            <p>
              Don't have an account?&nbsp;
              <Link to="/register" className="link">
                SIGN UP HERE
              </Link>
            </p>
          </div>
        </main>
      </AuthHeader>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
})

export default connect(
  mapStateToProps,
  { loginUser, clearErrors, setCurrentUser, setLoginCount }
)(withRouter(SignIn))
