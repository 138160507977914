import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-responsive-modal'
import Tour from 'reactour'

import {
  addScenario,
  editSettings,
  setActiveScenarios,
  logoutUser,
  toggleNavigation,
  editOpeningDate,
  continueGuidedTour,
  openTransactionForm,
  deleteTransactionById
} from '../../store/actions'
import {
  connectingXeroLink,
  getActiveUser,
  _setOpeningDate
} from '../../helpers/constants'

import IntroVideo from '../modals/IntroVideo'
import HelpSection from '../modals/HelpSection'
import XeroWindow from '../modals/XeroWindow'

import SettingsChanger from '../settings/SettingsChanger'
import ReminderDisplay from '../modals/ReminderDisplay'
import Snackbar from '../common/Snackbar'
import AddForm from '../layout/AddForm'
import ConfirmationDialog from '../dialogs/ConfirmationDialog'
import Scenarios from '../scenarios/Scenarios'
import AddScenario from '../scenarios/AddScenario'

class Toolbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showMenu: false,
      openIntro: false,
      openHelp: false,
      showOptions: false,
      showSettings: false,
      showReminder: false,
      showTForm: false,
      run: false,
      showMaxLimit: false,
      showDeleteConfirmation: false,
      openScenario: false,
      selectedReminders: [],
      reminderTransactions: [],
      newScenario: false,
      steps: [
        {
          selector: '#instructional-video',
          content: 'Click on this icon to see informational videos',
          position: 'left'
          // disableBeacon: true
        },
        {
          selector: '#scenario-selection',
          content:
            'Tick the Scenarios / Bank accounts you want included in your forecasts and graphs',
          position: 'bottom'
          // disableBeacon: true
        },
        {
          selector: '#scenario-selection',
          content:
            'Tick the Scenarios / Bank accounts you want included in your forecasts and graphs',
          position: 'bottom'
          // disableBeacon: true
        }
      ],
      xeroAuthLink: '',
      showXeroWindow: false
    }

    this.setDDRef = this.setDDRef.bind(this)
    this.setMenuRef = this.setMenuRef.bind(this)
    this.showOptionHandler = this.showOptionHandler.bind(this)
    this.closeOptionHandler = this.closeOptionHandler.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.connectToXero) {
      this.connectToXero()
    }
    if (
      nextProps.ui.guidedTour.status &&
      nextProps.ui.guidedTour.element === 1
    ) {
      this.tooltipTimeout = setTimeout(() => this.setState({ run: true }), 2000)
    }
    this.setState({
      reminderTransactions: nextProps.misc.reminders
    })
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
    if (this.tooltipTimeout) {
      clearTimeout(this.tooltipTimeout)
      this.tooltipTimeout = 0
    }
    if (this.loadRemindersTimeout) clearTimeout(this.loadRemindersTimeout)
  }

  setMenuRef = node => {
    this.menuRef = node
  }

  setDDRef = node => {
    this.ddRef = node
  }

  handleClickOutside = event => {
    if (
      this.menuRef &&
      !this.menuRef.contains(event.target) &&
      !this.ddRef.contains(event.target)
    ) {
      this.setState({ showMenu: false })
    }
  }

  onScenarioClicked = () => {
    let newValue = this.state.showMenu
    this.setState({ showMenu: !newValue })
  }

  toggleCheckBox = name => {
    let listStatus = document.getElementById(`${name}`).checked
    if (listStatus) {
      document.getElementById(`${name}`).checked = false
    } else {
      document.getElementById(`${name}`).checked = true
    }
  }

  submitSelectedScenarios = e => {
    e.stopPropagation()
    let temp = []
    const { scenarios } = this.props.user
    for (let i = 0, len = scenarios.length; i < len; i++) {
      let status = document.getElementById(`${scenarios[i].name}`).checked
      if (status) {
        temp.push(scenarios[i].id)
      }
    }

    if (temp.length < 1) temp.push(scenarios[0].id)
    this.setState({ showMenu: false })
    this.onScenarioChange(temp)
  }

  onSignOutClicked = () => {
    this.props.logoutUser()
  }

  /******** PLAYING INTRO VIDEO ************/
  playVideo = () => {
    // let videoLink = `${connectingBaseLink}assets/videos/1AUZ0.mp4`;
    this.setState({ openIntro: true })
  }

  introCancelled = () => {
    this.setState({ openIntro: false })
  }

  /************ HELP SECTION *********/
  onHelpClicked = () => {
    this.setState({ openHelp: true })
  }

  helpCancelled = () => {
    this.setState({ openHelp: false })
  }

  /*********** NARROW MENU *********/
  showOptionHandler = () => {
    this.setState({ showOptions: true }, () => {
      document.addEventListener('click', this.closeOptionHandler)
    })
  }

  closeOptionHandler = event => {
    this.setState({ showOptions: false }, () => {
      document.removeEventListener('click', this.closeOptionHandler)
    })
  }

  /************ React Tour *********/
  closeTour = () => {
    this.setState({ run: false })
    this.props.continueGuidedTour(6)
  }

  getCurrentStep = data => {
    if (data === 2) {
      this.setState({ run: false })
      this.props.continueGuidedTour(2)
    }
  }

  /**************** Connect to Xero API  *************/
  connectToXero = () => {
    this.setState({ showMenu: false })
    this.props.showLoading()
    fetch(`${connectingXeroLink}`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        user: getActiveUser()
      })
    })
      .then(response => response.json())
      .then(result => {
        this.props.hideLoading()
        let data = result.message
        if (data !== 'no') {
          this.setState({
            xeroAuthLink: data,
            showXeroWindow: true
          })
        } else {
          this.setState({
            xeroAuthLink: '',
            showXeroWindow: false
          })
        }
      })
  }

  closeXeroWindow = () => {
    this.setState({ showXeroWindow: false })
  }

  /******************* Added Later **************/
  onScenarioChange = scenarioIds => {
    this.props.setActiveScenarios(scenarioIds)
  }

  /**************** SETTING POP **********/
  startSettings = () => {
    this.setState({ showSettings: true })
  }

  settingsCancelHandler = () => {
    this.setState({ showSettings: false })
  }

  settingsDoneHandler = (chosenDate, title) => {
    const { headerTitle, openingDate, activeScIds } = this.props.user
    _setOpeningDate(chosenDate)
    if (headerTitle === title) {
      if (openingDate !== chosenDate) {
        this.props.editOpeningDate(chosenDate, activeScIds)
      }
    } else {
      this.props.editSettings(headerTitle, title, chosenDate, activeScIds)
    }
    this.setState({ showSettings: false })
  }

  /**************** REMINDER RELATED  *****************/
  seeReminders = () => {
    this.setState({ showReminder: true })
  }

  reminderConfirmHandler = selectedReminders => {
    if (selectedReminders.length > 0) {
      this.setState({ showDeleteConfirmation: true, selectedReminders })
    } else {
      this.setState({ showReminder: false })
    }
  }

  onDeleteConfirmClicked = () => {
    let activeScIds = this.props.user.activeScIds
    this.props.deleteTransactionById(this.state.selectedReminders, activeScIds)
    this.setState({ showDeleteConfirmation: false, showReminder: false })
  }

  onDeleteCancelClicked = () => {
    this.setState({ showDeleteConfirmation: false })
  }

  transactionClicked = transaction => {
    let mode = 'edit'
    if (transaction.transactionType === 'X' && transaction.typeId !== 0) {
      mode = 'view'
    }
    this.props.openTransactionForm(mode, transaction)
    this.setState({ showTForm: true })
  }

  tFormConfirm = () => {
    this.setState({
      showTForm: false
    })
  }

  tFormEdited = () => {
    this.setState({
      showTForm: false,
      showReminder: false
    })
  }

  /**
   * Listener to scenario handling opener called from Settings
   */
  openScenario = () => {
    this.setState({ openScenario: true, showSettings: false })
  }

  closeScenarioHandler = () => {
    this.setState({ openScenario: false, showSettings: false })
  }

  /*************** ADDING NEW SCENARIO RELATED *************/
  onAddNewScenario = () => {
    if (this.props.user.scenarios.length < 20) {
      this.setState({
        newScenario: true
      })
    } else {
      this.setState({ showMaxLimit: true })
      setTimeout(() => this.setState({ showMaxLimit: false }), 2000)
    }
  }

  newScenarioCancel = () => {
    this.setState({
      newScenario: false
    })
  }

  newScenarioDone = (scenarioName, scenarioValue) => {
    this.props.addScenario(scenarioName, scenarioValue)
    this.setState({ newScenario: false })
  }
  /*******************************************************/

  render() {
    const {
      scenarios,
      headerTitle,
      activeScNames,
      openingDate,
      activeScIds
    } = this.props.user
    let tbActives = ''
    let currentScenarios = []

    const { steps, run, showOptions } = this.state

    for (let i = 0; i < scenarios.length; i++) {
      if (scenarios[i].status === '1') {
        tbActives += scenarios[i].name + ' / '
        currentScenarios.push(scenarios[i].name)
      }
    }
    tbActives = tbActives.slice(0, -3)

    return (
      <div className="toolbar">
        <Modal
          open={this.state.newScenario}
          onClose={this.newScenarioCancel}
          center
          classNames={{ modal: 'modal-add-scenario' }}
        >
          <AddScenario
            addScenarioDone={this.newScenarioDone}
            addScenarioCancel={this.newScenarioCancel}
          />
        </Modal>
        <Modal
          classNames={{ modal: 'modal-settings-changer' }}
          open={this.state.showSettings}
          center
          onClose={this.settingsCancelHandler}
        >
          <SettingsChanger
            openScenario={this.openScenario}
            settingsDone={this.settingsDoneHandler}
            settingsCancel={this.settingsCancelHandler}
          />
        </Modal>

        <Modal
          classNames={{ modal: 'modal-reminder' }}
          open={this.state.showReminder}
          center
          onClose={this.reminderConfirmHandler}
        >
          <ReminderDisplay
            transactions={this.state.reminderTransactions}
            reminderConfirmHandler={this.reminderConfirmHandler}
            transactionClicked={this.transactionClicked}
          />
        </Modal>

        <Modal
          open={this.state.showDeleteConfirmation}
          onClose={this.onDeleteCancelClicked}
          center
          classNames={{ modal: 'modal-confirm-dialog' }}
        >
          <ConfirmationDialog
            dialogMessage={`You have selected some transactions as paid. Hitting Confirm will remove them.`}
            dialogPositive={'Confirm'}
            dialogNegative={'Go Back'}
            onDeleteConfirmClicked={this.onDeleteConfirmClicked}
            onDeleteCancelClicked={this.onDeleteCancelClicked}
          />
        </Modal>

        <Modal
          classNames={{ modal: 'modal-toolbar-tform' }}
          open={this.state.showTForm}
          center
          onClose={this.tFormConfirm}
        >
          <AddForm
            scenarioIds={activeScIds}
            scenarios={scenarios}
            newTransactionCancelled={this.tFormConfirm}
            newTransactionEdited={this.tFormEdited}
            currentScenario={activeScNames}
            openingDate={openingDate}
          />
        </Modal>

        <Modal
          open={this.state.openIntro}
          onClose={this.introCancelled}
          center
          classNames={{ modal: 'modal-toolbar-intro' }}
        >
          <IntroVideo />
        </Modal>

        <Modal
          open={this.state.openHelp}
          onClose={this.helpCancelled}
          center
          classNames={{ modal: 'modal-toolbar-help' }}
        >
          <HelpSection />
        </Modal>

        <Modal
          open={this.state.showXeroWindow}
          onClose={this.closeXeroWindow}
          center
          classNames={{ modal: 'modal-toolbar-xero' }}
        >
          <XeroWindow
            link={this.state.xeroAuthLink}
            onClose={this.closeXeroWindow}
          />
        </Modal>

        <Modal
          open={this.state.openScenario}
          onClose={this.closeScenarioHandler}
          center
          classNames={{ modal: 'modal-reminder' }}
        >
          <Scenarios
            currentScenario={activeScNames}
            scenarios={scenarios}
            closeScenarioHandler={this.closeScenarioHandler}
          />
        </Modal>

        <Tour
          steps={steps}
          isOpen={run}
          showNavigationNumber={false}
          showNumber={false}
          showNavigation={false}
          prevButton={<div style={{ display: 'none' }} />}
          nextButton={<div className={'tour-next-button'}>Next</div>}
          onRequestClose={this.closeTour}
          accentColor="#365478"
          getCurrentStep={this.getCurrentStep}
        />

        <div
          className="drawer-toggle"
          onClick={() => this.props.toggleNavigation(!this.props.navigationOn)}
        >
          <div className="bar-icon">
            <i className="fas fa-bars" />
          </div>
        </div>

        <div className="toolbar-nav">
          <div className="current-scenario" ref={this.setDDRef}>
            <button
              id="scenario-selection"
              className="dd-button"
              onClick={() => this.onScenarioClicked()}
            >
              {tbActives}
            </button>
            {this.state.showMenu ? (
              <div className="scenario-menu" ref={this.setMenuRef}>
                <div className="scenario-name-wrapper">
                  {scenarios.map(item => {
                    return (
                      <div key={item.name} className="input-dropdown">
                        <div
                          onClick={() => this.toggleCheckBox(item.name)}
                          className="input-name"
                        >
                          {item.name}
                        </div>
                        {currentScenarios.includes(item.name) ? (
                          <input
                            id={item.name}
                            type="checkbox"
                            name="scenario"
                            value={item.name}
                            defaultChecked
                          />
                        ) : (
                          <input
                            id={item.name}
                            type="checkbox"
                            name="scenario"
                            value={item.name}
                          />
                        )}
                      </div>
                    )
                  })}
                </div>

                <button
                  className="button-scenario-confirm"
                  onClick={event => this.submitSelectedScenarios(event)}
                >
                  Confirm
                </button>
                <span onClick={() => this.connectToXero()}>
                  <img
                    src="./img/xeroButtons/connect_xero_button_white.png"
                    style={{ marginLeft: '50%', transform: 'translateX(-50%)' }}
                    alt="connect to xero"
                  />
                </span>
                <button
                  className="button-scenario-new"
                  onClick={() => this.onAddNewScenario()}
                >
                  + Add New Scenario
                </button>
              </div>
            ) : null}
          </div>
          <div className="toolbar-title" title="Go to ZentaFlow Homepage">
            <a href="https://zentaflow.com.au">{headerTitle}</a>
          </div>
          <div className="toolbar-menu">
            <ul>
              <li onClick={() => this.onSignOutClicked()} title="Log Out">
                <div className="show-icon">
                  <i className="fas fa-sign-out-alt" />
                </div>
              </li>
              <li onClick={() => this.onHelpClicked()} title="Help">
                <div className="show-icon">
                  <i className="fas fa-question" />
                </div>
              </li>
              <li onClick={() => this.startSettings()} title="Settings">
                <div className="show-icon">
                  <i className="fas fa-cog" />
                </div>
              </li>
              <li
                onClick={() => this.seeReminders()}
                title="See your marked transactions"
              >
                <div className="show-icon" style={{ position: 'relative' }}>
                  <i className="fas fa-bell" />
                  {this.state.reminderTransactions && (
                    <span className="reminder-number">
                      {this.state.reminderTransactions.length}
                    </span>
                  )}
                </div>
              </li>
              <li
                onClick={() => this.playVideo()}
                title="See Instructional video"
                id="instructional-video"
              >
                <div className="show-icon">
                  <i className="fas fa-video" />
                </div>
              </li>
            </ul>
          </div>
          <div className="menu-opener" onClick={this.showOptionHandler}>
            <i className="fas fa-ellipsis-v" />
            {showOptions ? (
              <div className="my-dd-menu">
                <div onClick={() => this.playVideo()}>
                  <i className="fas fa-video" />
                  <span>Instructions</span>
                </div>
                <div onClick={() => this.startSettings()}>
                  <i className="fas fa-cog" />
                  <span>Settings</span>
                </div>
                <div onClick={() => this.seeReminders()}>
                  <i className="fas fa-bell" />
                  <span>Marked Transactions</span>
                </div>
                <div onClick={() => this.onHelpClicked()}>
                  <i className="fas fa-question" />
                  <span>Help</span>
                </div>
                <div onClick={() => this.onSignOutClicked()}>
                  <i className="fas fa-sign-out-alt" />
                  <span>Sign Out</span>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="clearfix" />
        {this.state.showMaxLimit ? (
          <Snackbar
            message={'A maximum of 20 scenarios are allowed'}
            show={this.state.showMaxLimit}
          />
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  ui: state.ui,
  misc: state.misc
})

export default connect(mapStateToProps, {
  addScenario,
  editSettings,
  logoutUser,
  setActiveScenarios,
  toggleNavigation,
  editOpeningDate,
  continueGuidedTour,
  openTransactionForm,
  deleteTransactionById
})(Toolbar)
