import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import userReducer from "./userReducer";
import uiReducer from "./uiReducer";
import miscReducer from "./miscReducer";

export default combineReducers({
	auth: authReducer,
	errors: errorReducer,
	user: userReducer,
	ui: uiReducer,
	misc: miscReducer
});
