import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import classnames from "classnames";

import { registerUser, clearErrors } from "../../store/actions";
import { categoryData, homeCategoryData } from "../../helpers/data";
import AuthHeader from "./AuthHeader";

class Register extends Component {
	constructor(props) {
		super(props);
		this.state = {
			registerEmail: "",
			registerPass: "",
			registerConfirm: "",
			showEmailError: false,
			showPasswordError: false,
			showConfirmError: false,
			validEmail: false,
			validPassword: false,
			validConfirm: false,
			emailErrorMessage: "",
			passwordErrorMessage: "",
			confirmErrorMessage: "",
			showErrorRegister: false,
			errorMessage: "",
			disableButton: false
		};
		this.onEmailChange = this.onEmailChange.bind(this);
	}

	componentDidMount() {
		this.props.clearErrors();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.errors.message) {
			let data = nextProps.errors.message;
			if (data === "present") {
				this.setState({
					showErrorRegister: true,
					errorMessage: "That user is already present in our database"
				});
			} else if (data === "no") {
				this.setState({
					showErrorRegister: true,
					errorMessage: "Error in registration. Try again later"
				});
			} else {
				this.setState({
					showErrorRegister: true,
					errorMessage: "Couldn't connect to the server. Try again later"
				});
			}
		}
		this.setState({ disableButton: false });
	}

	onEmailChange = event => {
		let email = event.target.value;

		if (email === "") {
			this.setState({
				showEmailError: true,
				emailErrorMessage: "Don't leave email field empty",
				validEmail: false
			});
		} else {
			this.setState({
				registerEmail: email,
				showEmailError: false,
				emailErrorMessage: "",
				validEmail: true
			});
		}
	};

	onPasswordChange = event => {
		let password = event.target.value;

		if (password.length < 6) {
			this.setState({
				showPasswordError: true,
				passwordErrorMessage: "Password should be at least 6 characters long",
				validPassword: false
			});
		} else {
			this.setState({
				registerPass: password,
				showPasswordError: false,
				passwordErrorMessage: "",
				validPassword: true
			});
		}
	};

	onConfirmPassChange = event => {
		let firstPass = this.state.registerPass;
		let password = event.target.value;
		if (password !== firstPass) {
			this.setState({
				showConfirmError: true,
				confirmErrorMessage: "Both passwords should be same",
				validConfirm: false
			});
		} else {
			this.setState({
				registerConfirm: password,
				showConfirmError: false,
				confirmErrorMessage: "",
				validConfirm: true
			});
		}
	};

	onRegisterClick = event => {
		event.preventDefault();
		this.setState({ disableButton: true });
		let validForm = this.state.validEmail && this.state.validPassword && this.state.validConfirm;
		if (validForm) {
			let categoryList = [];
			for (let i = 0; i < categoryData.length; i++) {
				let temp = { category: categoryData[i].name };
				categoryList.push(temp);
			}

			let homeCategories = [];
			for (let i = 0; i < homeCategoryData.length; i++) {
				let temp = { category: homeCategoryData[i].name };
				homeCategories.push(temp);
			}

			this.props.registerUser(
				this.state.registerEmail,
				this.state.registerPass,
				categoryList,
				homeCategories,
				this.props.history
			);
		} else {
			this.setState({
				showErrorRegister: true,
				errorMessage: "Make sure to fill all fields with no errors."
			});
		}
	};

	render() {
		const { showEmailError, showPasswordError, showConfirmError, showErrorRegister } = this.state;

		let classEmailWrap = "input-wrap";
		let classPasswordWrap = "input-wrap";
		let classConfirmWrap = "input-wrap";
		if (showEmailError) {
			classEmailWrap = "input-wrap error-color";
		}
		if (showPasswordError) {
			classPasswordWrap = "input-wrap error-color";
		}
		if (showConfirmError) {
			classConfirmWrap = "input-wrap error-color";
		}

		return (
			<AuthHeader>
				<div>
					<main id="loginForm">
						<span className="login-title">New User Registration</span>
						{showErrorRegister ? <div className="login-error">{this.state.errorMessage}</div> : null}

						<form onSubmit={this.onRegisterClick} method="POST">
							<span className="label-input">Email</span>
							<div className={classEmailWrap}>
								<input className="input" type="email" name="email" onChange={this.onEmailChange} />
							</div>
							{showEmailError ? (
								<div className="error-message">{this.state.emailErrorMessage}</div>
							) : null}

							<span className="label-input">Password</span>
							<div className={classPasswordWrap}>
								<input
									className="input"
									type="password"
									name="pass"
									onChange={this.onPasswordChange}
									autoComplete="off"
								/>
							</div>
							{showPasswordError ? (
								<div className="error-message">{this.state.passwordErrorMessage}</div>
							) : null}

							<span className="label-input">Confirm password</span>
							<div className={classConfirmWrap}>
								<input
									className="input"
									type="password"
									name="conpass"
									onChange={this.onConfirmPassChange}
									autoComplete="off"
								/>
							</div>
							{showConfirmError ? (
								<div className="error-message">{this.state.confirmErrorMessage}</div>
							) : null}

							<div className="button-container" style={{ paddingTop: "4rem" }}>
								<input
									type="submit"
									className={classnames("button-login", {
										"button-disable": this.state.disableButton
									})}
									value="Register"
								/>
							</div>
						</form>
						<div style={{ textAlign: "center", paddingTop: "4rem" }}>
							<p>
								Already have an account?&nbsp;
								<Link to="/login" className="link">
									GO TO SIGN IN
								</Link>
							</p>
						</div>
					</main>
				</div>
			</AuthHeader>
		);
	}
}

const mapStatetoProps = state => ({
	auth: state.auth,
	errors: state.errors
});

export default connect(
	mapStatetoProps,
	{ registerUser, clearErrors }
)(withRouter(Register));
