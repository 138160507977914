import axios from "axios";
import { getActiveUser, connectingBaseLink } from "../../helpers/constants";
import { ADD_CATEGORY } from "./types";
import { showSuccessToaster, showErrorToaster, showWarningToaster } from "./uiActions";
import { getCategoryData } from "./userActions";

export const addCategory = (category, ordering, categoryNames, sIds) => dispatch => {
	const data = { user: getActiveUser(), category, ordering };

	axios
		.post(`${connectingBaseLink}categories/addC.php`, data)
		.then(res => {
			if (res.data.success) {
				categoryNames.push(category);
				dispatch({
					type: ADD_CATEGORY,
					payload: categoryNames
				});
				dispatch(getCategoryData(sIds));
				dispatch(showSuccessToaster(`Category '${category}' added`));
			} else if (res.data.message === "present") {
				dispatch(showWarningToaster("That category is already present"));
			} else {
				dispatch(showErrorToaster("Error adding new category. Try again later."));
			}
		})
		.catch(err => dispatch(showErrorToaster(err.response.data)));
};

export const editCategory = (category, newName, categoryNames, sIds) => dispatch => {
	const data = { user: getActiveUser(), category, newName };

	axios
		.post(`${connectingBaseLink}categories/editC.php`, data)
		.then(res => {
			if (res.data.success) {
				const index = categoryNames.indexOf(category);
				categoryNames[index] = res.data.success;
				dispatch({
					type: ADD_CATEGORY,
					payload: categoryNames
				});
				dispatch(getCategoryData(sIds));
				dispatch(showSuccessToaster(`Category '${category}' edited`));
			} else if (res.data.message === "present") {
				dispatch(showWarningToaster("Name already taken by another category"));
			} else {
				dispatch(showErrorToaster("Error editing this category. Try again later."));
			}
		})
		.catch(err => dispatch(showErrorToaster(err.response.data)));
};

export const deleteCategory = (category, categoryNames, sIds) => dispatch => {
	const data = { user: getActiveUser(), category };

	axios
		.post(`${connectingBaseLink}categories/deleteC.php`, data)
		.then(res => {
			if (res.data.message === "done") {
				const index = categoryNames.indexOf(category);
				if (index > -1) categoryNames.splice(index, 1);

				dispatch({
					type: ADD_CATEGORY,
					payload: categoryNames
				});
				dispatch(getCategoryData(sIds));
				dispatch(showSuccessToaster(`Category '${category}' deleted successfully`));
			} else if (res.data.message === "transactions") {
				dispatch(
					showWarningToaster("This category either has active transactions or invoices. It can't be deleted.")
				);
			} else {
				dispatch(showErrorToaster("Error in deleting this category. Please, Try again."));
			}
		})
		.catch(err => dispatch(showErrorToaster(err.response.data)));
};

export const reorderCategory = (cIds, cNames, categoryNames, sIds) => dispatch => {
	const data = { user: getActiveUser(), cIds, cNames };

	axios
		.post(`${connectingBaseLink}categories/reorderC.php`, data)
		.then(res => {
			if (res.data.success) {
				let response = res.data.success;
				let temp = response.map(item => item.category_name);

				dispatch({
					type: ADD_CATEGORY,
					payload: temp
				});
				dispatch(getCategoryData(sIds));
			} else {
				dispatch(showErrorToaster("Some error in reordering. Refreshing categories..."));
				dispatch({
					type: ADD_CATEGORY,
					payload: categoryNames
				});
			}
		})
		.catch(err => {
			dispatch(showErrorToaster("Some error in reordering. Refreshing categories..."));
			dispatch({
				type: ADD_CATEGORY,
				payload: categoryNames
			});
		});
};

export const addSubCategory = (scenarioId, category, sub, sIds) => dispatch => {
	const data = { user: getActiveUser(), scenarioId, category, sub };

	axios
		.post(`${connectingBaseLink}categories/addS.php`, data)
		.then(res => {
			if (res.data.success) {
				dispatch(getCategoryData(sIds));
				dispatch(showSuccessToaster(`New Sub Category '${sub}' added`));
			} else if (res.data.message === "present") {
				dispatch(showWarningToaster(`'${sub}' sub-category already present`));
			} else if (res.data.message === "unable") {
				dispatch(showWarningToaster("Please enter a valid name"));
			} else {
				dispatch(showErrorToaster("Error adding new sub-category, Please try again."));
			}
		})
		.catch(err => dispatch(showErrorToaster(err.response.data)));
};

export const editSubCategory = (subId, newName, scenarioId, category, sIds) => dispatch => {
	const data = { user: getActiveUser(), subId, newName, scenarioId, category };

	axios
		.post(`${connectingBaseLink}categories/editSub.php`, data)
		.then(res => {
			let response = res.data.message;
			if (response === "done") {
				dispatch(getCategoryData(sIds));
				dispatch(showSuccessToaster("SubCategory name edited."));
			} else if (response === "present") {
				dispatch(showWarningToaster("That name is already taken by another sub-category"));
			} else {
				dispatch(showErrorToaster("Error editing sub-category name. Please try again."));
			}
		})
		.catch(err => dispatch(showErrorToaster(err.response.data)));
};

export const deleteSubCategory = (subData, sIds) => dispatch => {
	const data = { user: getActiveUser(), subData };

	axios
		.post(`${connectingBaseLink}categories/deleteSub.php`, data)
		.then(res => {
			let response = res.data.message;
			if (response === "done") {
				dispatch(getCategoryData(sIds));
				dispatch(showSuccessToaster("SubCategory deleted."));
			} else {
				dispatch(showErrorToaster("Error deleting sub-category. Please try again."));
			}
		})
		.catch(err => dispatch(showErrorToaster(err.response.data)));
};
