import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Modal from 'react-responsive-modal'
import moment from 'moment'

import { _getMonthLocation, setCategoryData } from '../../helpers/constants'
import {
  addCategory,
  editCategory,
  deleteCategory,
  reorderCategory,
  addSubCategory
} from '../../store/actions'

import CategoryTable from './CategoryTable'
import AddNewSub from './sub-data/AddNewSub'
import AddCategory from './AddCategory'
import ReorderCategory from './ReorderCategory'
import ModifyCategory from './ModifyCategory'
import ConfirmationDialog from '../dialogs/ConfirmationDialog'
import Snackbar from '../common/Snackbar'

let groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

let setYearChange = false

class CategoryData extends Component {
  constructor(props) {
    super(props)
    this.state = {
      categories: [],
      initialData: [],
      queriedData: [],
      fullTransactions: [],
      subCategoryData: [],
      selectedOption: 'M',
      selectedCategory: '',
      categoriesLength: 0,
      showMenu: false,
      addSub: false,
      addCategory: false,
      editCategory: false,
      reorderCategory: false,
      showDeleteConfirmation: false,
      showMaxCategory: false,
      showMinCategory: false,
      tableTitle: 'Monthly Balances By Category',
      newMode: true,
      selectedDay: undefined,
      queriedFromYearChange: false,
      categorySelected: '',
      entrySelected: '',
      listSubCategories: [],
      customPreClass: 'year-icon hide-icon',
      customNextClass: 'year-icon',
      editSelected: false,
      entryDetails: '',
      subForScenario: [],
      cToObj: [],
      categoryArray: [],
      openingDate: this.props.user.openingDate
    }
    this.handleDayChange = this.handleDayChange.bind(this)
    this.showMenu = this.showMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    const { categoryData, categoryNames, openingDate } = nextProps.user
    if (categoryData.length > 0) {
      let categoryArray = []
      let response = categoryData
      let responseLength = response.length
      let dataList = []
      let subData = []
      let entryData = []

      for (let i = 0; i < responseLength; i++) {
        if ('category_name' in response[i]) {
          categoryArray.push(response[i])
        } else if ('sub_id' in response[i]) {
          subData.push(response[i])
        } else if ('entry_id' in response[i]) {
          entryData.push(response[i])
        } else {
          dataList.push(response[i])
        }
      }

      setCategoryData(categoryArray)

      let setAllData = dataList.slice(0)

      let subCategoryData = []
      for (let i = 0; i < categoryNames.length; i++) {
        subCategoryData.push({ name: categoryNames[i], sub: [] })
      }
      let listSubCategories = []
      for (let i = 0; i < subData.length; i++) {
        listSubCategories.push({
          sub_id: subData[i].sub_id,
          sub_name: subData[i].sub_name,
          sub_category: subData[i].sub_category,
          sub_scenario: subData[i].sub_scenario
        })
        if (
          categoryNames.includes(subData[i].sub_category) &&
          this.props.currentScenario.includes(subData[i].sub_scenario)
        ) {
          let index = categoryNames.indexOf(subData[i].sub_category)
          subCategoryData[index].sub.push({
            type: 'sub',
            subId: subData[i].sub_id,
            name: subData[i].sub_name,
            scenario: subData[i].sub_scenario,
            category: subData[i].sub_category
          })
        }
      }

      for (let i = 0; i < entryData.length; i++) {
        if (
          categoryNames.includes(entryData[i].entry_category) &&
          this.props.currentScenario.includes(entryData[i].entry_scenario)
        ) {
          let index = categoryNames.indexOf(entryData[i].entry_category)
          subCategoryData[index].sub.push({
            type: 'entry',
            id: entryData[i].entry_id,
            name: entryData[i].entry_name,
            sub: entryData[i].entry_sub,
            subId: entryData[i].entry_sub_id,
            category: entryData[i].entry_category,
            scenario: entryData[i].entry_scenario,
            percentage: entryData[i].percentage,
            symbol: entryData[i].symbol_code,
            ahead: entryData[i].days_ahead,
            end: entryData[i].entry_end
          })
        }
      }

      dataList = dataList.filter(function(item) {
        return item.start_date >= openingDate
      })

      /* Setting up category object for reordering */
      let cToObj = []
      for (let i = 0, len = categoryNames.length; i < len; i++) {
        cToObj.push({
          id: `category_${i + 1}`,
          content: categoryNames[i]
        })
      }

      /* Checking if subs and entries exists */
      if (listSubCategories.length > 0) {
        let subForScenario = []
        for (let i = 0; i < listSubCategories.length; i++) {
          for (let j = 0; j < this.props.currentScenario.length; j++) {
            let key = this.props.currentScenario[j]
            // let temp = {name: this.props.currentScenario[j], sub: []}
            if (
              this.props.currentScenario[j] ===
              listSubCategories[i].sub_scenario
            ) {
              if (key in subForScenario) {
                let preId = subForScenario[key].subId
                preId.push(listSubCategories[i].sub_id)
                subForScenario[key].subId = preId
                let preSub = subForScenario[key].sub
                preSub.push(listSubCategories[i].sub_name)
                subForScenario[key].sub = preSub
                let preCat = subForScenario[key].categories
                preCat.push(listSubCategories[i].sub_category)
                subForScenario[key].categories = preCat
              } else {
                subForScenario[key] = {}
                subForScenario[key].subId = [listSubCategories[i].sub_id]
                subForScenario[key].sub = [listSubCategories[i].sub_name]
                subForScenario[key].categories = [
                  listSubCategories[i].sub_category
                ]
              }
            }
          }
        }

        let tempForSub = []
        for (let key in subForScenario) {
          if (subForScenario.hasOwnProperty(key)) {
            tempForSub.push({
              name: key,
              subId: subForScenario[key].subId,
              sub: subForScenario[key].sub,
              cat: subForScenario[key].categories
            })
          }
        }

        this.setState({
          subCategoryData: subCategoryData,
          listSubCategories: listSubCategories,
          fullTransactions: setAllData,
          initialData: dataList,
          queriedData: dataList,
          categories: categoryNames,
          subForScenario: tempForSub,
          cToObj: cToObj,
          categoryArray: categoryArray,
          openingDate
        })
      } else {
        this.setState({
          subCategoryData: subCategoryData,
          listSubCategories: listSubCategories,
          fullTransactions: setAllData,
          initialData: dataList,
          queriedData: dataList,
          categories: categoryNames,
          cToObj: cToObj,
          categoryArray: categoryArray,
          openingDate
        })
      }
    }
  }

  componentDidUpdate() {
    if (setYearChange) {
      setYearChange = false
    }
  }

  showMenu = event => {
    event.preventDefault()

    this.setState({ showMenu: true }, () => {
      document.addEventListener('click', this.closeMenu)
    })
  }

  closeMenu = event => {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener('click', this.closeMenu)
    })
  }

  categoryDistributionTypeHandler = type => {
    let initialData = this.state.initialData.slice(0)
    if (type === 'W') {
      this.setState({
        selectedOption: 'W',
        tableTitle: 'Weekly Balances By Category',
        queriedData: initialData
      })
    } else if (type === 'Y') {
      this.setState({
        selectedOption: 'Y',
        tableTitle: 'Yearly Balances By Category',
        queriedData: initialData
      })
    } else if (type === 'M') {
      this.setState({
        selectedOption: 'M',
        tableTitle: 'Monthly Balances By Category',
        queriedData: initialData
      })
    }
  }

  handleDayChange(day) {
    this.setState({ selectedDay: day })
  }

  columnDataClicked = (object, valueId, distributionBy) => {
    let toCompareYear = this.props.selectedYear.toString()

    if (distributionBy === 'W') {
      let weekId = Number(valueId.substring(5))
      let tempData = this.state.queriedData.slice(0)

      tempData = tempData.filter(item => {
        let weekNumber = moment(item.start_date, 'YYYY-MM-DD').isoWeek()
        let year = item.start_date.substring(0, 4)
        return (
          weekId === weekNumber &&
          object.category === item.category &&
          toCompareYear === year &&
          this.props.currentScenario.includes(item.scenario)
        )
      })

      this.setDataBeforeBar(tempData)
    } else if (distributionBy === 'M') {
      let monthId = _getMonthLocation(valueId)
      let tempData = this.state.queriedData.slice()
      tempData = tempData.filter(item => {
        let month = item.start_date.substring(5, 7)
        let year = item.start_date.substring(0, 4)
        return (
          object.category === item.category &&
          monthId === month &&
          toCompareYear === year &&
          this.props.currentScenario.includes(item.scenario)
        )
      })

      this.setDataBeforeBar(tempData)
    } else if (distributionBy === 'Y') {
      let yearId = valueId.substring(5)
      let tempData = this.state.queriedData.slice()

      tempData = tempData.filter(item => {
        let yearNumber = item.start_date.substring(2, 4)
        return (
          yearId === yearNumber &&
          // && item.scenario === this.props.currentScenario
          this.props.currentScenario.includes(item.scenario) &&
          object.category === item.category
        )
      })

      this.setDataBeforeBar(tempData)
    }
  }

  setDataBeforeBar = tempData => {
    let dataLength = tempData.length
    let amountDescription = []
    for (let i = 0; i < dataLength; i++) {
      amountDescription.push({
        id: i,
        amount: tempData[i].amount,
        description: tempData[i].description
      })
    }

    let returnedSample = groupBy(amountDescription, 'description')

    let sortedAD = []
    let minAmount = 0
    let maxAmount = 0
    for (let key in returnedSample) {
      if (returnedSample.hasOwnProperty(key)) {
        let tempArray = returnedSample[key]
        let adder = 0
        for (let i = 0, len = tempArray.length; i < len; i++) {
          adder += parseFloat(tempArray[i].amount)
        }
        if (adder < minAmount) minAmount = adder
        if (adder > maxAmount) maxAmount = adder
        sortedAD.push({ description: key, amount: adder })
      }
    }
    this.props.onCategoryDataClicked(sortedAD, tempData, minAmount, maxAmount)
  }

  /***************** YEAR CHANGER ********************/
  leftClicked = () => {
    let preYear = this.props.selectedYear
    preYear--
    let customPreClass = 'year-icon'
    let customNextClass = 'year-icon'
    if (preYear === moment(this.state.openingDate, 'YYYY-MM-DD').year()) {
      customPreClass = 'year-icon hide-icon'
    }
    setYearChange = true
    this.setState({ customPreClass, customNextClass })
    this.props.onYearChanged(preYear)
  }

  rightClicked = () => {
    let nextYear = this.props.selectedYear
    nextYear++
    let customNextClass = 'year-icon'
    let customPreClass = 'year-icon'
    if (nextYear === 2029) {
      customNextClass = 'year-icon hide-icon'
    }

    setYearChange = true
    this.setState({ customNextClass, customPreClass })
    this.props.onYearChanged(nextYear)
  }
  /********************************************************/

  addSubHandler = category => {
    this.setState({
      addSub: true,
      categorySelected: category
    })
  }

  addSubCancel = () => {
    this.setState({ addSub: false })
  }

  addSubConfirm = (name, scenarioName) => {
    let scenarioId = this.props.user.scenarios.filter(
      item => item.name === scenarioName
    )
    scenarioId = scenarioId[0].id

    this.props.addSubCategory(
      scenarioId,
      this.state.categorySelected,
      name,
      this.props.user.activeScIds
    )
    this.setState({ addSub: false })
  }

  addTransactionHandler = () => {}

  /***************** ADDING CATEGORIES ********************/
  addCategoryHandler = () => {
    if (this.state.categories.length < 15) {
      this.setState({ addCategory: true })
    } else {
      this.setState({ showMaxCategory: true })
      setTimeout(() => this.setState({ showMaxCategory: false }), 2000)
    }
  }

  addCategoryConfirm = categoryName => {
    let categoriesCount = this.state.categories.length
    this.props.addCategory(
      categoryName,
      categoriesCount + 1,
      this.state.categories,
      this.props.user.activeScIds
    )
    this.setState({ addCategory: false })
  }

  addCategoryCancel = () => {
    this.setState({ addCategory: false })
  }
  /******************************************************/

  /********* EDITING AND DELETING CATEGORIES ***********/
  onCategoryClicked = categoryName => {
    this.setState({
      selectedCategory: categoryName,
      editCategory: true
    })
  }

  editCategoryCancel = () => {
    this.setState({ editCategory: false })
  }

  editCategoryConfirm = newName => {
    this.props.editCategory(
      this.state.selectedCategory,
      newName,
      this.state.categories,
      this.props.user.activeScIds
    )
    this.setState({ editCategory: false })
  }

  deleteCategoryHandler = categoryName => {
    if (this.state.categories.length > 10) {
      this.setState({ showDeleteConfirmation: true })
    } else {
      this.setState({ showMinCategory: true })
      setTimeout(() => {
        this.setState({ showMinCategory: false })
      }, 2000)
    }
  }

  onDeleteCancelClicked = () => {
    this.setState({ showDeleteConfirmation: false })
  }

  onDeleteConfirmClicked = () => {
    this.props.deleteCategory(
      this.state.selectedCategory,
      this.state.categories,
      this.props.user.activeScIds
    )
    this.setState({ editCategory: false, showDeleteConfirmation: false })
  }

  onEditEntryClicked = entry => {
    let selectedScenario = entry.scenario

    for (let i = 0; i < this.state.subForScenario.length; i++) {
      if (selectedScenario === this.state.subForScenario[i].name) {
        this.setState({
          newMode: true,
          addEntry: true,
          editSelected: true,
          entryDetails: entry
        })
        break
      }
    }
  }

  /**************** REORDERING CATEGORIES **********************/

  reorderCategoryHandler = () => {
    this.setState({ reorderCategory: true })
  }

  reorderCategoryConfirm = categoryReordered => {
    let categoriesIds = []
    let categoriesNames = []

    for (let i = 0; i < categoryReordered.length; i++) {
      categoriesIds.push(parseInt(categoryReordered[i].category_id, 10))
      categoriesNames.push(categoryReordered[i].category_name)
    }
    categoriesIds.sort((a, b) => a - b)

    this.props.reorderCategory(
      categoriesIds,
      categoriesNames,
      this.state.categories,
      this.props.user.activeScIds
    )
    this.setState({ addCategory: false, reorderCategory: false })
  }

  reorderCategoryCancel = () => {
    this.setState({ reorderCategory: false })
  }

  transactionRedirect = () => {
    this.props.history.push({
      pathname: '/transactions',
      state: { openForm: true }
    })
  }

  render() {
    const { scenarios } = this.props.user
    const { categorySelected, openingDate } = this.state

    let preClass = this.state.customPreClass
    let nextClass = this.state.customNextClass

    return (
      <Fragment>
        <Modal
          classNames={{ modal: 'modal-add-new-sub' }}
          center
          open={this.state.addSub}
          onClose={this.addSubCancel}
        >
          <AddNewSub
            activeScenarios={this.props.currentScenario}
            addSubCancel={this.addSubCancel}
            addSubConfirm={this.addSubConfirm}
            sendCategoryName={categorySelected}
          />
        </Modal>
        <Modal
          open={this.state.addCategory}
          center
          onClose={this.addCategoryCancel}
          classNames={{ modal: 'modal-add-category' }}
        >
          <AddCategory
            addCategoryConfirm={this.addCategoryConfirm}
            addCategoryCancel={this.addCategoryCancel}
            reorderCategoryHandler={this.reorderCategoryHandler}
          />
        </Modal>

        <Modal
          open={this.state.reorderCategory}
          center
          onClose={this.reorderCategoryCancel}
          classNames={{ modal: 'modal-reorder-category' }}
        >
          <ReorderCategory
            categories={this.state.categoryArray}
            reorderCategoryConfirm={this.reorderCategoryConfirm}
            reorderCategoryCancel={this.reorderCategoryCancel}
          />
        </Modal>

        <Modal
          open={this.state.editCategory}
          center
          onClose={this.editCategoryCancel}
          classNames={{ modal: 'modal-modify-category' }}
        >
          <ModifyCategory
            totalCategories={this.state.categories.length}
            categoryName={this.state.selectedCategory}
            editCategoryCancel={this.editCategoryCancel}
            editCategoryConfirm={this.editCategoryConfirm}
            deleteCategoryHandler={this.deleteCategoryHandler}
          />
        </Modal>

        <Modal
          open={this.state.showDeleteConfirmation}
          onClose={this.onDeleteCancelClicked}
          center
          classNames={{ modal: 'modal-confirm-dialog' }}
        >
          <ConfirmationDialog
            dialogMessage={`Are you sure you want to delete category '${this.state.selectedCategory}?'`}
            dialogPositive={'Yes'}
            dialogNegative={'No'}
            onDeleteConfirmClicked={this.onDeleteConfirmClicked}
            onDeleteCancelClicked={this.onDeleteCancelClicked}
          />
        </Modal>

        <div id="category-data" className="panel">
          <div
            className="row"
            style={{
              borderBottom: 'none',
              marginBottom: '0rem',
              paddingLeft: '1.5rem'
            }}
          >
            <div className="col-12 col-xl-8 change-display">
              <div className="row w-100">
                <div className="cd-title col-12 col-md-4">
                  {this.state.tableTitle}
                </div>
                <div
                  className="add-category col-6 col-md-4"
                  onClick={() => this.addCategoryHandler()}
                >
                  <i className="fas fa-plus" />
                  <span style={{ fontSize: '1em' }}> Add Category</span>
                </div>
                <button
                  className="col-6 col-md-4 add-category"
                  onClick={() => this.transactionRedirect()}
                  style={{ background: 'white' }}
                >
                  <i className="fas fa-plus" />
                  <span style={{ fontSize: '1em' }}> Add Transaction</span>
                </button>
              </div>
            </div>
            <div className="col-12 col-xl-4">
              {this.state.selectedOption !== 'Y' ? (
                <div className="panel-year-wrap">
                  <span>Select year: </span>
                  <span className={preClass} onClick={this.leftClicked}>
                    <i className="fas fa-caret-left" />
                  </span>
                  <span>{this.props.selectedYear}</span>
                  <span className={nextClass} onClick={this.rightClicked}>
                    <i className="fas fa-caret-right" />
                  </span>
                </div>
              ) : null}
              <ul className="panel-toolbox">
                <li
                  className="panel-list"
                  onClick={this.showMenu}
                  style={{ fontSize: '1.1em' }}
                >
                  Select By Period
                  <img src="./img/menu-dropdown.png" alt="drop_down" />
                  {this.state.showMenu ? (
                    <div className="cdata-menu">
                      <div
                        onClick={() =>
                          this.categoryDistributionTypeHandler('W')
                        }
                      >
                        By Week
                      </div>
                      <div
                        onClick={() =>
                          this.categoryDistributionTypeHandler('M')
                        }
                      >
                        By Month
                      </div>
                      <div
                        onClick={() =>
                          this.categoryDistributionTypeHandler('Y')
                        }
                      >
                        By Year
                      </div>
                    </div>
                  ) : null}
                </li>
              </ul>
              <div className="clearfix" />
            </div>
          </div>
          {/* {showBy === 1
                        ? */}
          <CategoryTable
            selectedYear={this.props.selectedYear}
            scenarios={scenarios}
            transactionData={this.state.queriedData}
            selectedOption={this.state.selectedOption}
            categoriesList={this.state.categories}
            currentScenario={this.props.currentScenario}
            addSubHandler={this.addSubHandler}
            subCategoryData={this.state.subCategoryData}
            listSubCategories={this.state.listSubCategories}
            categoryArray={this.state.categoryArray}
            columnDataClicked={this.columnDataClicked}
            onCategoryClicked={this.onCategoryClicked}
            activeScIds={this.props.user.activeScIds}
            openingDate={openingDate}
          />
        </div>

        {this.state.showMaxCategory ? (
          <Snackbar
            message={'Maximum of 15 categories allowed'}
            show={this.state.showMaxCategory}
          />
        ) : null}
        {this.state.showMinCategory ? (
          <Snackbar
            message={'A minimum of 5 categories is required'}
            show={this.state.showMinCategory}
          />
        ) : null}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
})

export default connect(
  mapStateToProps,
  { addCategory, editCategory, deleteCategory, reorderCategory, addSubCategory }
)(withRouter(CategoryData))
