import React, { Component } from "react";
import classnames from "classnames";

class ConfirmationDialog extends Component {
	state = {
		disableButton: false
	};

	onDeleteConfirmClicked = () => {
		this.setState({ disableButton: true });
		this.props.onDeleteConfirmClicked();
	};

	render() {
		return (
			<div id="confirmationDialog">
				<div className="cd-text">{this.props.dialogMessage}</div>
				<div className="cd-button">
					<button
						className={classnames("cd-confirm", {
							"cd-disable": this.state.disableButton
						})}
						onClick={() => this.onDeleteConfirmClicked()}
					>
						{this.props.dialogPositive}
					</button>
					<button className="cd-cancel" onClick={() => this.props.onDeleteCancelClicked()}>
						{this.props.dialogNegative}
					</button>
				</div>
			</div>
		);
	}
}

export default ConfirmationDialog;
