import isEmpty from "../../validation/is-empty";

import { SET_CURRENT_USER, STOP_INITIAL_LOADER } from "../actions/types";

const initialState = {
	isAuthenticated: false,
	user: {},
	initialLoader: true
};

export default function(state = initialState, action) {
	switch (action.type) {
		case SET_CURRENT_USER:
			return {
				...state,
				isAuthenticated: !isEmpty(action.payload),
				user: action.payload
			};
		case STOP_INITIAL_LOADER:
			return {
				...state,
				initialLoader: false
			};
		default:
			return state;
	}
}
