import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import Modal from 'react-responsive-modal'

import {
  setWindowDimensions,
  justLoggedIn,
  updateScenarios,
  deleteTransaction,
  continueGuidedTour,
  openTransactionForm,
  clearTransactionForm
} from '../../store/actions'

// import Scenarios from "../scenarios/Scenarios";
import CurveGraph from './CurveGraph'
import BarGraph from './BarGraph'
import DescriptionDetails from './DescriptionDetails'
import CategoryData from '../matrix/CategoryData'

import ConfirmationDialog from '../dialogs/ConfirmationDialog'
import ThreeWayDialog from '../dialogs/ThreeWayDialog'

import Tour from 'reactour'
import Layout from '../layout/Layout'
import AddForm from '../layout/AddForm'
import isEmpty from '../../validation/is-empty'
import OpeningPopup from '../scenarios/OpeningPopup'

let sameIndexCalled = true
let selectedData = []
let legendBelow = false

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openingDate: '',
      showDescDetails: false,
      amountsForBar: [],
      descriptionsForBar: [],
      allTransactions: [],
      transactionsForDescriptions: [],
      selectedIndex: -1,
      clickedDescription: '',
      barMax: 0,
      selectedYear: moment(this.props.user.openingDate, 'YYYY-MM-DD').year(),
      showDeleteConfirmation: false,
      showRepeatingDelete: false,
      newTransaction: false,
      run: false,
      displayPopup: false,
      connectToXero: false,
      steps: [
        {
          selector: '#curve_handler',
          content:
            'Check your future daily cash balances by bank account or all together. (click scenario name to show / hide)',
          position: 'left'
        },
        {
          selector: '#category_handler',
          content:
            'Click on any amount in your cash flow forecast report to see a detailed breakdown of the transactions in the description field for edit.',
          position: 'top'
        },
        {
          selector: '#bar_handler',
          content:
            'Select any description to view and edit detailed transactions.',
          position: 'left'
        },
        {
          selector: '#bar_handler',
          content:
            'Select any description to view and edit detailed transactions.',
          position: 'left'
        }
      ]
    }
    this.curveRef = React.createRef()
    this.barRef = React.createRef()
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    setTimeout(() => {
      this.props.setWindowDimensions(
        this.curveRef.current.clientHeight,
        this.barRef.current.clientHeight
      )
    }, 500)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user.justLoggedIn) {
      setTimeout(() => {
        this.setState({ displayPopup: true })
      }, 1000)
    }
    if (
      nextProps.ui.guidedTour.status &&
      nextProps.ui.guidedTour.element === 3
    ) {
      this.setState({
        openingDate: nextProps.user.openingDate,
        selectedYear: moment(nextProps.user.openingDate, 'YYYY-MM-DD').year(),
        run: true,
        amountsForBar: [],
        descriptionsForBar: [],
        showDescDetails: false
      })
    } else {
      this.setState({
        openingDate: nextProps.user.openingDate,
        selectedYear: moment(nextProps.user.openingDate, 'YYYY-MM-DD').year(),
        amountsForBar: [],
        descriptionsForBar: [],
        showDescDetails: false
      })
    }
    sameIndexCalled = false
  }

  onBarClicked = element => {
    if (element.length > 0) {
      let atIndex = element[0]._index
      let selectedDescription = element[0]._view.label
      let newDescShow = true
      if (this.state.selectedIndex === atIndex) {
        if (sameIndexCalled) {
          newDescShow = false
          sameIndexCalled = false
        } else {
          newDescShow = true
          sameIndexCalled = true
        }
      } else {
        sameIndexCalled = true
      }
      let tempTransactions = this.state.allTransactions.slice()

      tempTransactions = tempTransactions.filter(
        item => item.description === selectedDescription
      )
      for (let i = 0, len = tempTransactions.length; i < len; i++) {
        tempTransactions[i].sNumber = i + 1
      }

      this.setState({
        showDescDetails: newDescShow,
        transactionsForDescriptions: tempTransactions,
        selectedIndex: atIndex,
        clickedDescription: selectedDescription
      })
    }
  }

  onCategoryDataClicked = (
    amountDescription,
    transactions,
    minAmount,
    maxAmount
  ) => {
    let max = Math.abs(maxAmount)
    let min = Math.abs(minAmount)
    let barMax = max > min ? max : min

    amountDescription.sort((a, b) =>
      a.amount > b.amount ? 1 : b.amount > a.amount ? -1 : 0
    )

    let labelData = []
    let barValues = []
    for (let i = 0, len = amountDescription.length; i < len; i++) {
      labelData.push(amountDescription[i].description)
      barValues.push(amountDescription[i].amount)
    }

    this.setState({
      descriptionsForBar: labelData,
      amountsForBar: barValues,
      allTransactions: transactions,
      barMax: barMax
    })
  }

  onYearChanged = year => {
    this.setState({ selectedYear: year })
  }

  /******************** EDIT AND DELETE FROM DESCRIPTION DETAILS ***************/
  addNewTransaction = () => {
    this.props.openTransactionForm('add', '')
    this.setState({ newTransaction: true })
  }

  newTransactionCancelled = () => {
    this.props.clearTransactionForm()
    this.setState({
      newTransaction: false
    })
  }

  onDeleteConfirmClicked = () => {
    this.setState({
      showDeleteConfirmation: false,
      showRepeatingDelete: false
    })
    this.onDeleteRepeatClicked(1)
  }

  onDeleteCancelClicked = () => {
    this.setState({
      showDeleteConfirmation: false,
      showRepeatingDelete: false
    })
  }

  onDeleteRepeatClicked = option => {
    const { activeScIds } = this.props.user
    this.props.deleteTransaction(
      activeScIds,
      selectedData.start_date,
      selectedData.millis,
      selectedData.id,
      selectedData.scenario,
      option
    )
  }

  rowInConcern = (mode, data) => {
    selectedData = data
    if (!isEmpty(data)) {
      if (mode === 'del') {
        if (data.frequency_id !== 'f0') {
          this.setState({ showRepeatingDelete: true })
        } else {
          this.setState({ showDeleteConfirmation: true })
        }
      } else {
        this.props.openTransactionForm(mode, data)
        this.setState({ newTransaction: true })
      }
    }
  }

  //************** React Tour *************/
  getCurrentStep = data => {
    if (data === 3) {
      this.setState({ run: false })
      this.props.continueGuidedTour(4)
    }
  }

  closeTour = () => {
    this.setState({ run: false })
    this.props.continueGuidedTour(6)
  }

  /**
   * This is on close listener for closing the opening popup when logging in
   */
  onClosePopup = () => {
    this.props.justLoggedIn(false)
    this.setState({ displayPopup: false })
  }

  onSavePopup = data => {
    this.props.updateScenarios(data)
    this.props.justLoggedIn(false)
  }

  connectToXero = () => {
    this.setState({
      displayPopup: false,
      connectToXero: true
    })
  }

  render() {
    const { width, curveHeight } = this.props.ui
    const { scenarios, activeScNames, activeScIds } = this.props.user

    const {
      amountsForBar,
      descriptionsForBar,
      barMax,
      transactionsForDescriptions,
      clickedDescription,
      run,
      steps,
      openingDate,
      displayPopup
    } = this.state

    let graphFontSize = 12
    if (width <= 600 && width > 480) {
      graphFontSize = 10
    } else if (width <= 480) {
      graphFontSize = 8
    }

    legendBelow = false

    // let barForScenario = curveHeight;
    // let curveForBar = curveHeight;
    // if (width < 1200) {
    //   barForScenario = barHeight;
    //   curveForBar = 0;
    // }

    let curveForBar = curveHeight

    if (width < 576) {
      curveForBar = 0
    }

    return (
      <Layout connectToXero={this.state.connectToXero}>
        <div className="dashboard">
          <Modal
            open={this.state.newTransaction}
            onClose={this.newTransactionCancelled}
            center
            classNames={{ modal: 'modal-dashboard-add-form' }}
          >
            <AddForm
              scenarioIds={activeScIds}
              scenarios={scenarios}
              currentScenario={activeScNames}
              newTransactionCancelled={this.newTransactionCancelled}
              newTransactionEdited={this.newTransactionCancelled}
              openingDate={openingDate}
            />
          </Modal>

          <Modal
            open={this.state.showDeleteConfirmation}
            onClose={this.onDeleteCancelClicked}
            center
            classNames={{ modal: 'modal-dashboard-confirmation' }}
          >
            <ConfirmationDialog
              dialogMessage={'Are you sure you want to delete this row?'}
              dialogPositive={'Confirm'}
              dialogNegative={'Cancel'}
              onDeleteConfirmClicked={this.onDeleteConfirmClicked}
              onDeleteCancelClicked={this.onDeleteCancelClicked}
            />
          </Modal>

          <Modal
            open={this.state.showRepeatingDelete}
            onClose={this.onDeleteCancelClicked}
            center
            classNames={{ modal: 'modal-dashboard-three-way' }}
          >
            <ThreeWayDialog
              dialogMessage={
                'This is a repeating type transaction. Select your option.'
              }
              dialogPositive={'Delete All Transactions'}
              dialogNegative={'Cancel'}
              onDeleteRepeatClicked={this.onDeleteRepeatClicked}
              onDeleteCancelClicked={this.onDeleteCancelClicked}
            />
          </Modal>

          <Modal
            open={displayPopup}
            onClose={this.onClosePopup}
            center
            classNames={{ modal: 'modal-dashboard-popup' }}
          >
            <OpeningPopup
              scenarios={scenarios}
              connectToXero={this.connectToXero}
              onCancelPopup={this.onClosePopup}
              onSavePopup={this.onSavePopup}
            />
          </Modal>

          <Tour
            steps={steps}
            isOpen={run}
            showNavigationNumber={false}
            showNumber={false}
            showNavigation={false}
            prevButton={<div style={{ display: 'none' }} />}
            nextButton={<div className={'tour-next-button'}>Next</div>}
            onRequestClose={this.closeTour}
            accentColor="#365478"
            getCurrentStep={this.getCurrentStep}
            startAt={0}
          />

          <div className="my-row graph-container">
            <div
              className="col35 curve-container"
              ref={this.curveRef}
              id="curve_handler"
            >
              <CurveGraph
                activeScIds={activeScIds}
                legendBelow={legendBelow}
                currentScenario={activeScNames}
                scenarioList={scenarios}
                selectedYear={this.state.selectedYear}
                graphFontSize={graphFontSize}
                openingDate={openingDate}
              />
            </div>
            <div className="col25" ref={this.barRef} id="bar_handler">
              <BarGraph
                curveHeight={curveForBar}
                onBarClicked={this.onBarClicked}
                descriptions={descriptionsForBar}
                amounts={amountsForBar}
                barMax={barMax}
                graphFontSize={graphFontSize}
              />
            </div>
          </div>

          {/* {width >= 1200 ? (
            <div className="my-row">
              <div className="col15">
                <Scenarios barHeight={barForScenario} narrowScreen={false} currentScenario={activeScNames} scenarios={scenarios} />
              </div>
              <div className="col25" ref={this.curveRef} id="curve_handler">
                <CurveGraph
                  activeScIds={activeScIds}
                  legendBelow={legendBelow}
                  currentScenario={activeScNames}
                  scenarioList={scenarios}
                  selectedYear={this.state.selectedYear}
                  graphFontSize={graphFontSize}
                  openingDate={openingDate}
                />
              </div>
              <div className="col25" ref={this.barRef} id="bar_handler">
                <BarGraph
                  curveHeight={curveForBar}
                  onBarClicked={this.onBarClicked}
                  descriptions={descriptionsForBar}
                  amounts={amountsForBar}
                  barMax={barMax}
                  graphFontSize={graphFontSize}
                />
              </div>
            </div>
          ) : (
            <div>
              <div style={{ marginBottom: "3rem" }} ref={this.curveRef} id="curve_handler">
                <CurveGraph
                  activeScIds={activeScIds}
                  legendBelow={legendBelow}
                  currentScenario={activeScNames}
                  scenarioList={scenarios}
                  selectedYear={this.state.selectedYear}
                  graphFontSize={graphFontSize}
                  openingDate={openingDate}
                />
              </div>

              <div className="my-row">
                <div className="col13" style={{ marginBottom: "2rem" }}>
                  <Scenarios narrowScreen={true} barHeight={barForScenario} currentScenario={activeScNames} scenarios={scenarios} />
                </div>
                <div className="col23" ref={this.barRef} id="bar_handler">
                  <BarGraph
                    curveHeight={curveForBar}
                    onBarClicked={this.onBarClicked}
                    descriptions={descriptionsForBar}
                    amounts={amountsForBar}
                    barMax={barMax}
                    graphFontSize={graphFontSize}
                  />
                </div>
              </div>
            </div>
          )} */}
          {this.state.showDescDetails ? (
            <div className="desc-details-wrapper">
              <DescriptionDetails
                transactions={transactionsForDescriptions}
                description={clickedDescription}
                rowInConcern={this.rowInConcern}
              />
            </div>
          ) : null}
          <div id="category_handler">
            <CategoryData
              scenarioIds={activeScIds}
              currentScenario={activeScNames}
              onCategoryDataClicked={this.onCategoryDataClicked}
              onYearChanged={this.onYearChanged}
              selectedYear={this.state.selectedYear}
            />
          </div>
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  ui: state.ui,
  user: state.user
})

export default connect(
  mapStateToProps,
  {
    setWindowDimensions,
    updateScenarios,
    justLoggedIn,
    deleteTransaction,
    continueGuidedTour,
    openTransactionForm,
    clearTransactionForm
  }
)(Dashboard)
