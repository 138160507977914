import React, { Component } from "react";
import { Player } from "video-react";
import "video-react/dist/video-react.css";

import { connectingBaseLink } from "../../helpers/constants";

class IntroVideo extends Component {
	state = {
		videoLink: {
			id: 1,
			source: `${connectingBaseLink}assets/videos/1AUZ0.mp4`
		}
	};

	setVideoLink = videoId => () => {
		let videoLink = { id: videoId, source: "" };
		if (videoId === 1) {
			videoLink.source = `${connectingBaseLink}assets/videos/1AUZ0.mp4`;
		} else if (videoId === 2) {
			videoLink.source = `${connectingBaseLink}assets/videos/quick_walkthrough.mp4`;
		}
		this.setState({ videoLink });
	};

	render() {
		const { videoLink } = this.state;
		return (
			<div id="introVideo">
				<div className="iv-title">Select a video to play</div>
				<div className="iv-options">
					<div onClick={this.setVideoLink(1)} className={videoLink.id === 1 ? "iv-active" : null}>
						About Zentaflow
					</div>
					<div onClick={this.setVideoLink(2)} className={videoLink.id === 2 ? "iv-active" : null}>
						See a quick app Walkthrough
					</div>
				</div>
				<div className="iv-container">
					<Player
						playsInline
						// poster="/assets/poster.png"
						src={videoLink.source}
					/>
				</div>
			</div>
		);
	}
}

export default IntroVideo;
