import React from "react";
import PropTypes from "prop-types";

const FormSelector = ({ options, onMirrorTransactionSelected }) => {
	return (
		<div className="form-selector">
			<select
				onChange={event => onMirrorTransactionSelected(event)}
				onClick={event => onMirrorTransactionSelected(event)}
			>
				{options.map(item => (
					<option key={item.id} value={item.id}>
						{item.name}
					</option>
				))}
			</select>
		</div>
	);
};

FormSelector.propTypes = {
	options: PropTypes.array.isRequired
};

export default FormSelector;
