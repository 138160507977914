import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";

import { validateUser, stopInitialLoader } from "../../store/actions";
import Loading from "../common/Loading";
import { getActiveUser } from "../../helpers/constants";

class AuthHeader extends Component {
	componentDidMount() {
		let placeholder = this.props.location.pathname;
		if (placeholder === "/login") {
			this.props.validateUser(getActiveUser(), this.props.history);
		} else {
			this.props.stopInitialLoader();
		}
	}
	render() {
		const { initialLoader } = this.props.auth;
		return (
			<Fragment>
				{initialLoader ? (
					<Loading
						loaderId="loadingElement"
						loaderType="spinningBubbles"
						loaderColor="#fafafa"
						loaderText="Redirecting..."
					/>
				) : (
					<div id="auth-header" style={{ backgroundImage: "url(./img/background-login.jpg)" }}>
						<div className="logo-container">
							<img src="./img/zentapon_binoculars.png" alt="zentapon_logo" />
							<h3>
								Cash Flow <span style={{ fontSize: ".8em", fontWeight: "400" }}>by Zentapon</span>
							</h3>
						</div>
						{this.props.children}
						<footer className="footer">
							<ul className="elements">
								<li>Terms of Service</li>
								<li>Privacy Policy</li>
								<li>About Us</li>
							</ul>
							<div className="copyright">
								© {moment().format("YYYY")} Zentapon Pty Ltd | All Rights Reserved
							</div>
						</footer>
					</div>
				)}
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth
});

export default connect(
	mapStateToProps,
	{ validateUser, stopInitialLoader }
)(withRouter(AuthHeader));
