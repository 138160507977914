import React from "react";
import { connect } from "react-redux";
import MenuDivider from "../common/MenuDivider";

const SettingsChanger = props => {
  const { openingDate, headerTitle } = props.user;
  return (
    <div id="settingsChanger">
      <div className="settings-title">Settings</div>
      <div className={"settings-options"}>
        <div className={"settings-date"}>Change Opening date:</div>
        <input
          className={"settings-date-input"}
          type="date"
          name="opening_date"
          min="2015-01-01"
          id="opening_date"
          defaultValue={openingDate}
          required
        />
      </div>
      <MenuDivider />
      <div className={"settings-options"}>
        <div className={"settings-date"}>Edit Header Title:</div>
        <input
          className={"settings-date-input"}
          type="text"
          name="header_title"
          id="header_title"
          maxLength="17"
          defaultValue={headerTitle}
          required
        />
      </div>
      <MenuDivider />
      <div className="settings-options">
        <button className="settings-confirm" style={{ width: "100%" }} onClick={() => props.openScenario()}>
          Edit Scenario data
        </button>
      </div>
      <div className="settings-button-wrap">
        <button className="settings-cancel" onClick={() => props.settingsCancel()}>
          Cancel
        </button>
        <button
          className={"settings-confirm"}
          onClick={() => props.settingsDone(document.getElementById("opening_date").value, document.getElementById("header_title").value)}
          // onClick={() => props.settingsDone(document.getElementById("opening_date").value, document.getElementById("category_types").value)}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(SettingsChanger);
