import React, { Component } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { _getCurrency } from '../../helpers/constants'
import { addScenario } from '../../store/actions'

import Modal from 'react-responsive-modal'
import PerfectScrollbar from 'react-perfect-scrollbar'
import AddScenario from './AddScenario'
import Snackbar from '../common/Snackbar'

class OpeningPopup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      scenarioData: this.props.scenarios,
      newScenario: false,
      showMaxLimit: false
    }
  }

  onInputChange = e => {
    let scenarioData = this.state.scenarioData
    let changedName = e.target.name
    let changedValue = e.target.value
    let changedId = changedName.substring(0, changedName.indexOf('_'))

    let newScenarioData = scenarioData.map(item => {
      if (changedId === item.id) {
        item.opening = changedValue
      }
      return item
    })
    this.setState({ scenarioData: newScenarioData })
  }

  /*************** ADDING NEW SCENARIO RELATED *************/
  addScenarioHandler = () => {
    if (this.props.scenarios.length < 20) {
      this.setState({
        newScenario: true
      })
    } else {
      this.setState({ showMaxLimit: true })
      setTimeout(() => this.setState({ showMaxLimit: false }), 2000)
    }
  }

  addScenarioCancel = () => {
    this.setState({
      newScenario: false
    })
  }

  addScenarioDone = (scenarioName, scenarioValue) => {
    this.props.addScenario(scenarioName, scenarioValue)
    this.setState({ newScenario: false })
  }
  /*******************************************************/

  render() {
    const { scenarios, onCancelPopup, onSavePopup } = this.props
    const { scenarioData } = this.state
    let date = moment().format('DD MMM, YYYY')
    return (
      <div id="opening-popup">
        <Modal
          open={this.state.newScenario}
          onClose={this.addScenarioCancel}
          center
          classNames={{ modal: 'modal-add-scenario' }}
        >
          <AddScenario
            addScenarioDone={this.addScenarioDone}
            addScenarioCancel={this.addScenarioCancel}
          />
        </Modal>
        <div className="popup-title">Opening Balances</div>
        <div className="popup-date">{date}</div>
        <div className="popup-message">Edit opening balances if you want</div>
        <hr />
        <PerfectScrollbar>
          <div className="popup-body">
            {scenarios.map(item => (
              <div className="row" key={item.id}>
                <div className="col-sm-8 names">{item.name}</div>
                <div className="col-sm-4 openings">
                  {_getCurrency}
                  <input
                    type="number"
                    name={`${item.id}_opening`}
                    id={item.id}
                    defaultValue={item.opening}
                    step="0.01"
                    max="1000000"
                    onChange={this.onInputChange}
                  />
                </div>
              </div>
            ))}
          </div>
        </PerfectScrollbar>
        <hr />
        <div className="popup-buttons">
          <button
            className="scenario-btn"
            onClick={() => this.addScenarioHandler()}
          >
            Add New Scenario
          </button>
          <button className="cancel-btn" onClick={() => onCancelPopup()}>
            Close Window
          </button>
          <button className="ok-btn" onClick={() => onSavePopup(scenarioData)}>
            Save Changes
          </button>
        </div>
        <hr />
        <div className="text-center">
          <p className="mt-5">
            Our application provides integration with Xero Account. This way you
            can benefit from both of the apps.
          </p>
          <span onClick={() => this.props.connectToXero()}>
            <img
              src="./img/xeroButtons/connect_xero_button_white.png"
              alt="connect to xero"
              style={{ cursor: 'pointer' }}
            />
          </span>
        </div>
        {this.state.showMaxLimit && (
          <Snackbar
            message={'A maximum of 20 scenarios are allowed'}
            show={this.state.showMaxLimit}
          />
        )}
      </div>
    )
  }
}

export default connect(null, {
  addScenario
})(OpeningPopup)
