import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import Joyride from 'react-joyride'
import Tour from 'reactour'
import { withRouter, Link } from 'react-router-dom'
import classnames from 'classnames'

import { continueGuidedTour, startGuidedTour } from '../../store/actions'
import { getActiveUser, connectingBaseLink } from '../../helpers/constants'
import Modal from 'react-responsive-modal'
import NavigationLink from './NavigationLink'
import MenuDivider from '../common/MenuDivider'
import DonationWindow from '../modals/DonationWindow'

let lastStep = false
let alreadyCalled = false

class SideDrawer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: props.currentPage,
      mountCalled: false,
      addNew: false,
      openDonate: false,
      runTour: false,
      stepsTour: [
        {
          selector: '#transaction_link',
          content: 'To see all future transactions for your bank / account.',
          position: 'top'
        },
        {
          content: <h4>Thanks for your time</h4>,
          position: 'top'
        }
      ],
      runDonate: false,
      stepsDonate: [
        {
          target: '#donate-button',
          content: "We'll appreciate your help",
          placement: 'top',
          disableBeacon: true
        }
      ],
      showDonate: false
    }
  }

  componentDidMount() {
    if (!this.state.mountCalled) {
      fetch(`${connectingBaseLink}home/getLoginCount.php`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          user: getActiveUser()
        })
      })
        .then(response => response.json())
        .then(result => {
          if (result.message === '0') {
            this.guidedTourTimeout = setTimeout(() => {
              this.props.startGuidedTour()
            }, 2000)
          } else if (result.message === '3') {
            this.setState({ showDonate: true })
          }
        })
      this.setState({ mountCalled: true })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.ui.guidedTour.status &&
      nextProps.ui.guidedTour.element === 5
    ) {
      this.setState({
        runTour: true
      })
    }
    if (!alreadyCalled) {
      this.displayDonate = setTimeout(() => {
        if (this.state.showDonate) this.setState({ runDonate: true })
      }, 8000)
      alreadyCalled = true
    }
  }

  componentWillUnmount() {
    if (this.mountTimeout) clearTimeout(this.mountTimeout)
    if (this.guidedTourTimeout) clearTimeout(this.guidedTourTimeout)
    if (this.displayDonate) clearTimeout(this.displayDonate)
  }

  checkPage = name => {
    return this.state.page === name
  }

  onDonateClicked = () => {
    this.setState({ openDonate: true })
  }

  onDonateCancel = () => {
    this.setState({ openDonate: false })
  }

  /************ React Tour ************/
  closeTour = () => {
    this.setState({ runTour: false })
    this.props.continueGuidedTour(6)
  }

  getCurrentStep = data => {
    if (data === 1 && !lastStep) {
      this.setState({ finishTour: true })
      lastStep = true
    }
  }

  finishTour = () => {
    this.setState({ runTour: false })
    this.props.continueGuidedTour(6)
  }

  render() {
    const {
      runDonate,
      stepsDonate,
      runTour,
      stepsTour,
      finishTour
    } = this.state
    const { navigationOn } = this.props

    const currentLink = this.props.location.pathname.substr(1)

    return (
      <div
        className={classnames('side-drawer', {
          'standard-size': navigationOn,
          'narrow-size': !navigationOn
        })}
      >
        <Modal
          open={this.state.openDonate}
          center
          onClose={this.onDonateCancel}
          classNames={{ modal: 'modal-donate' }}
        >
          <DonationWindow />
        </Modal>

        {finishTour ? (
          <Tour
            steps={stepsTour}
            isOpen={runTour}
            showNavigationNumber={false}
            showNumber={false}
            showNavigation={false}
            nextStep={this.finishTour}
            prevButton={<div style={{ display: 'none' }} />}
            nextButton={
              <div
                className={'tour-next-button'}
                onClick={() => this.finishTour()}
              >
                Finish
              </div>
            }
            onRequestClose={this.closeTour}
            accentColor="#365478"
            getCurrentStep={this.getCurrentStep}
          />
        ) : (
          <Tour
            steps={stepsTour}
            isOpen={runTour}
            showNavigationNumber={false}
            showNumber={false}
            showNavigation={false}
            prevButton={<div style={{ display: 'none' }} />}
            nextButton={<div className={'tour-next-button'}>Next</div>}
            onRequestClose={this.closeTour}
            accentColor="#365478"
            getCurrentStep={this.getCurrentStep}
          />
        )}

        <Joyride
          steps={stepsDonate}
          run={runDonate}
          styles={{
            options: {
              arrowColor: '#fafafa',
              backgroundColor: '#fafafa',
              width: 300,
              zIndex: 500
            },
            buttonNext: {
              borderRadius: 0,
              backgroundColor: '#365478',
              width: '30%',
              marginLeft: '35%'
            },
            tooltipContent: {
              padding: '1rem',
              paddingTop: '2rem'
            }
          }}
        />

        <div>
          <Link to="/dashboard" className="side-drawer-header">
            {navigationOn ? (
              <h1>Cash Flow Management</h1>
            ) : (
              <img src="./img/drawer-logo.png" alt="logo" />
            )}
            {navigationOn ? <h2>Simply, made easy</h2> : null}
            {navigationOn ? (
              <div className="sdate">
                Date: {moment().format('DD MMM, YYYY')}
              </div>
            ) : null}
          </Link>

          <MenuDivider />

          <div className="navigation-items">
            <NavigationLink
              link="dashboard"
              title={navigationOn ? null : 'Dashboard'}
              checkActive={currentLink === 'dashboard' ? 'active' : null}
            >
              {navigationOn ? (
                <div className="delayed">
                  <i className="fas fa-home" style={{ marginRight: '1rem' }} />
                  Dashboard
                </div>
              ) : (
                <i className="fas fa-home" />
              )}
            </NavigationLink>
            <div id="transaction_link">
              <NavigationLink
                link="transactions"
                title={navigationOn ? null : 'Transactions'}
                checkActive={currentLink === 'transactions' ? 'active' : null}
              >
                {navigationOn ? (
                  <div className="delayed">
                    <i
                      className="fas fa-table"
                      style={{ marginRight: '1rem' }}
                    />
                    Transactions
                  </div>
                ) : (
                  <i className="fas fa-table" />
                )}
              </NavigationLink>
            </div>
          </div>
        </div>
        <MenuDivider />
        {navigationOn ? (
          <div className="donation-element">
            <MenuDivider />
            <h4>Tell us what you want us to improve on</h4>
            <button id="donate-button" onClick={this.onDonateClicked}>
              Donate
            </button>
            <MenuDivider />
          </div>
        ) : (
          <div className="icon-container">
            <MenuDivider />
            <div
              id="donate-button"
              onClick={this.onDonateClicked}
              className="donation-icon"
              title="Donations"
            >
              <i className="fas fa-donate" />
            </div>
            <MenuDivider />
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ui: state.ui
})

export default connect(
  mapStateToProps,
  { continueGuidedTour, startGuidedTour }
)(withRouter(SideDrawer))
