import moment from "moment";
import {
  GET_SCENARIOS,
  SET_ACTIVE_SCENARIOS,
  ADD_SCENARIO,
  EDIT_SCENARIO,
  EDIT_SETTINGS,
  GET_TRANSACTIONS,
  ADD_TRANSACTION,
  EDIT_TRANSACTION,
  GET_CATEGORY_DATA,
  ADD_CATEGORY,
  SET_DATA_LOADING,
  STOP_LOADING,
  EDIT_OPENING,
  RESET_SCENARIOS,
  JUST_LOGGED_IN
} from "../actions/types";

const initialState = {
  userLoading: false,
  scenarios: [],
  activeScNames: [],
  activeScIds: [],
  transactions: [],
  categoryNames: [],
  headerTitle: "ZentaFlow",
  categoryData: [],
  openingDate: moment(new Date()).format("YYYY-MM-DD"),
  justLoggedIn: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_LOADING:
      return {
        ...state,
        userLoading: true
      };
    case STOP_LOADING:
      return {
        ...state,
        userLoading: false
      };
    case GET_SCENARIOS:
      let scenarios = action.payload.scenarios;
      return {
        ...state,
        scenarios,
        activeScNames: scenarioNames(scenarios),
        activeScIds: scenarioIds(scenarios),
        headerTitle: action.payload.headerTitle
      };
    case RESET_SCENARIOS:
      return {
        ...state,
        scenarios: action.payload
      };
    case GET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload
      };
    case EDIT_SETTINGS:
      return {
        ...state,
        headerTitle: action.payload.headerTitle,
        openingDate: action.payload.openingDate
      };
    case EDIT_OPENING:
      return {
        ...state,
        openingDate: action.payload
      };
    case SET_ACTIVE_SCENARIOS:
    case ADD_SCENARIO:
    case EDIT_SCENARIO:
      return {
        ...state,
        scenarios: action.payload,
        activeScNames: scenarioNames(action.payload),
        activeScIds: scenarioIds(action.payload)
      };
    case ADD_TRANSACTION:
      return {
        ...state,
        transactions: action.payload
      };
    case EDIT_TRANSACTION:
      return {
        ...state,
        transactions: action.payload
      };
    case GET_CATEGORY_DATA:
      return {
        ...state,
        categoryData: action.payload.categoryData,
        categoryNames: action.payload.categoryNames
      };
    case ADD_CATEGORY:
      return {
        ...state,
        categoryNames: action.payload
      };
    case JUST_LOGGED_IN:
      return {
        ...state,
        justLoggedIn: action.payload
      };
    default:
      return state;
  }
}

const scenarioNames = scenarios => {
  return scenarios.filter(item => item.status === "1").map(item => item.name);
};

const scenarioIds = scenarios => {
  return scenarios.filter(item => item.status === "1").map(item => item.id);
};
