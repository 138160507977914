import { OPEN_FORM, CLEAR_FORM, GET_REMINDERS } from "../actions/types";

const initialState = {
	formValue: { mode: "add", data: "" },
	reminders: [],
	descTransactions: []
};

export default function(state = initialState, action) {
	switch (action.type) {
		case OPEN_FORM:
			return {
				...state,
				formValue: { mode: action.payload.mode, data: action.payload.data }
			};
		case CLEAR_FORM:
			return {
				...state,
				formValue: { mode: "add", data: "" }
			};
		case GET_REMINDERS:
			return {
				...state,
				reminders: action.payload
			};
		default:
			return state;
	}
}
