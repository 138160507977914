import React from 'react'

class AddScenario extends React.Component {
  constructor() {
    super()
    this.state = {
      scenarioName: ''
    }
    this.inputRef = React.createRef()
  }

  onDoneClicked = event => {
    event.preventDefault()
    let newValue = this.state.scenarioName.trim()
    let balance = document.getElementById('scenario_balance').value
    if (
      newValue.length < 3 ||
      balance === '' ||
      !/^(?=.*[\w\d]).+/.test(newValue)
    ) {
      this.props.warningToaster('Name should have at least one letter')
    } else {
      this.props.addScenarioDone(newValue, balance)
    }
  }

  onNameChange = event => {
    let checkedValue = event.target.value
    this.setState({ scenarioName: checkedValue })
  }

  render() {
    return (
      <div id="modalAddScenario">
        <form onSubmit={event => this.onDoneClicked(event)} method="POST">
          <div> Enter a name for the new scenario:</div>
          <input
            className="mas-input-scenario"
            ref={this.inputRef}
            type="text"
            name="scenarioName"
            minLength="3"
            maxLength="30"
            autoFocus
            required
            autoComplete="off"
            onChange={this.onNameChange}
          />
          <div style={{ marginTop: '1rem' }}>
            {' '}
            Enter the opening balance (Amount):
          </div>
          <input
            className="mas-input-balance"
            id="scenario_balance"
            type="number"
            name="scenarioBalance"
            required
          />
          <div className="mas-button-wrap">
            <input type="submit" className="mas-confirm" value="Confirm" />
          </div>
        </form>
        <button
          className="mas-cancel"
          onClick={() => this.props.addScenarioCancel()}
        >
          Cancel
        </button>
      </div>
    )
  }
}

export default AddScenario
