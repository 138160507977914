import React, { Component } from "react";
import XLSX from "xlsx";
import moment from "moment";
import saveAs from "file-saver";

import Modal from "react-responsive-modal";
import {
	connectingBaseLink,
	getCategories,
	getActiveUser,
	_getDecimal,
	_getCurrency
} from "../../../helpers/constants";
import EditingListRow from "./EditingListRow";

const sheetHeaders = [
	["Invoice Date", "Business Partner Name", "Subcategory", "Amount", "Due Date", "Cash Flow Pay Date"]
];
const sheetName = "Transactions data";
const fileName = "zentaflow.xlsx";

const headerWidths = ["5%", "12%", "15%", "20%", "15%", "12%", "12%"];

function isNumeric(value) {
	return !isNaN(parseFloat(value)) && isFinite(value);
}

function priceFormatter(cell) {
	let tempValue = cell;
	if (tempValue < 0) cell = -1 * cell;
	let returnedValue = parseFloat(Math.round(cell + "e" + _getDecimal()) + "e-" + _getDecimal()).toFixed(_getDecimal());
	let formattedValue = returnedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	if (isNaN(returnedValue)) return <span />;
	else if (tempValue < 0)
		return (
			<span>
				{_getCurrency} ({formattedValue})
			</span>
		);
	else
		return (
			<span>
				{_getCurrency} {formattedValue}
			</span>
		);
}

class EditSubList extends Component {
	state = {
		// dataArray: [],
		excelFileName: "No File Selected",
		customSaveClass: "esl-save esl-disable",
		invalidData: "",
		listData: this.props.listData,
		editRow: false,
		selectedForEditing: []
	};

	onFileReset = e => {
		e.target.value = null;
	};

	onFileUpload = e => {
		let files = e.target.files;

		if (typeof files[0] !== "undefined") {
			let excelFileName = files[0].name;

			let reader = new FileReader();
			reader.readAsArrayBuffer(files[0]);

			reader.onload = e => {
				let arrayBuffer = e.target.result;

				let data = new Uint8Array(arrayBuffer);
				let arr = [];
				for (let i = 0; i !== data.length; ++i) arr[i] = String.fromCharCode(data[i]);
				let bstr = arr.join("");

				let workbook = XLSX.read(bstr, { type: "binary" });
				let first_worksheet = workbook.Sheets[workbook.SheetNames[0]];

				let range = XLSX.utils.decode_range(first_worksheet["!ref"]);

				let result = [];
				// let maxColumn = range.e.c > 5 ? 5 : range.e.c;
				let maxColumn = range.e.c;
				// let maxRow = range.e.r > 9 ? 9 : range.e.r;
				let maxRow = range.e.r;

				let headers = [];
				for (let i = range.s.c; i <= maxColumn; i++) {
					// if (i === 0 || i === 2 || i === 4) continue;
					let cell = first_worksheet[XLSX.utils.encode_cell({ r: 0, c: i })];
					if (typeof cell !== "undefined") {
						headers.push(cell.w);
					}
				}

				for (let rowNum = range.s.r + 1; rowNum <= maxRow; rowNum++) {
					let row = [];
					// result.push(first_worksheet[XLSX.utils.encode_row(rowNum)]);
					for (let colNum = range.s.c; colNum <= maxColumn; colNum++) {
						// if (colNum === 0 || colNum === 2 || colNum === 4) continue;
						let cell = first_worksheet[XLSX.utils.encode_cell({ r: rowNum, c: colNum })];
						if (typeof cell === "undefined") {
							row.push("N/A");
						} else {
							row.push(cell.w);
						}
					}
					result.push(row);
				}

				// Check validity of data
				let subListData = this.props.subListData;
				let subId = subListData.subId;

				let listDataArray = this.state.listData;

				if (result.length > 0) {
					let indexDate = -1,
						indexName = -1,
						indexAmount = -1,
						indexSub = -1,
						indexInvoice = -1,
						indexDue = -1;

					indexInvoice = headers.indexOf(sheetHeaders[0][0]);
					indexName = headers.indexOf(sheetHeaders[0][1]);
					indexSub = headers.indexOf(sheetHeaders[0][2]);
					indexAmount = headers.indexOf(sheetHeaders[0][3]);
					indexDue = headers.indexOf(sheetHeaders[0][4]);
					indexDate = headers.indexOf(sheetHeaders[0][5]);

					if (
						indexInvoice >= 0 &&
						indexSub >= 0 &&
						indexAmount >= 0 &&
						indexDue >= 0 &&
						indexDate >= 0 &&
						indexName >= 0
					) {
						let invalidData = false;

						for (let i = 0; i < result.length; i++) {
							// Checking and setting invoice date
							if (
								// !moment(result[i][indexInvoice], "M/D/YY", true).isValid() &&
								!moment(result[i][indexInvoice], "M/D/YY", true).isValid()
							) {
								invalidData = true;
								break;
							}
							let momentInvoice = moment(result[i][indexInvoice], "M/D/YY").toDate();
							let invoice_date = moment(momentInvoice).format("YYYY-MM-DD");

							// Checking and setting due date
							if (
								// !moment(result[i][indexDue], "M/D/YY", true).isValid() &&
								!moment(result[i][indexDue], "M/D/YY", true).isValid()
							) {
								invalidData = true;
								break;
							}
							let momentDue = moment(result[i][indexDue], "M/D/YY").toDate();
							let due_date = moment(momentDue).format("YYYY-MM-DD");

							// Checking and setting cash flow date
							if (
								// !moment(result[i][indexDate], "M/D/YY", true).isValid() &&
								!moment(result[i][indexDate], "M/D/YY", true).isValid()
							) {
								invalidData = true;
								break;
							}
							let momentDate = moment(result[i][indexDate], "M/D/YY").toDate();
							let start_date = moment(momentDate).format("YYYY-MM-DD");

							if (!isNumeric(result[i][indexAmount])) {
								invalidData = true;
								break;
							}

							let listData = {
								subId: subId,
								invoiceDate: invoice_date,
								listName: result[i][indexName],
								listAmount: result[i][indexAmount],
								dueDate: due_date,
								cashDate: start_date
							};
							listDataArray.push(listData);
						}
						if (invalidData) {
							this.setState({
								excelFileName: excelFileName,
								invalidData: "Make sure the date and amount entered in file are of correct types."
							});
						} else {
							this.setState({
								excelFileName: excelFileName,
								listData: listDataArray,
								customSaveClass: "esl-save",
								invalidData: ""
							});
						}
					} else {
						this.setState({
							excelFileName: excelFileName,
							invalidData: "File doesn't have required columns (See below table headers)"
						});
					}
				}
			};
		}
	};

	onSaveClicked = () => {
		let subListData = this.props.subListData;

		let scenarioIds = this.props.scenarios.map(item => item.id);
		let sId = this.props.scenarios.filter(item => item.name === "Main Account").map(item => item.id);
		let scenarioId = sId[0];

		let categoryArray = getCategories();
		let categoryId = categoryArray.filter(item => item.category_name === subListData.category);
		categoryId = categoryId[0].category_id;

		let subId = subListData.subId;

		let frequency_id = "f0";
		let frequency_type = "One Time";
		let end_type_id = "1";
		let d = new Date();
		let millis = d.getTime();
		let reminder = "No";
		let reminderNote = null;
		let xTrans = null;

		let listDataArray = this.state.listData;
		let newTransaction = [];

		for (let i = 0; i < listDataArray.length; i++) {
			let transaction = {
				scenario_id: scenarioId,
				category_id: categoryId,
				transaction_type: "X",
				type_id: listDataArray[i].subId,
				description: `${subListData.name} (${subListData.category})`,
				start_date: listDataArray[i].cashDate,
				amount: listDataArray[i].listAmount,
				balance: listDataArray[i].listAmount,
				frequency_id: frequency_id,
				frequency_type: frequency_type,
				end_type_id: end_type_id,
				end_type_value: listDataArray[i].cashDate,
				millis: millis,
				reminder: reminder,
				reminder_note: reminderNote,
				xtrans: xTrans
			};
			newTransaction.push(transaction);
		}

		this.saveChangedToDb(newTransaction, scenarioIds, listDataArray, subId);
	};

	saveChangedToDb = (newTransaction, scenarioIds, listDataArray, subId) => {
		fetch(`${connectingBaseLink}lists/addList.php`, {
			method: "post",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				user: getActiveUser(),
				opening: this.props.openingDate,
				transList: newTransaction,
				scenarioIds: scenarioIds,
				listData: listDataArray,
				subId: subId
			})
		})
			.then(response => response.json())
			.then(result => {
				this.setState({
					invalidData: ""
				});
				this.props.editListCancel();
			});
	};

	onTemplateExport = () => {
		let newBook = XLSX.utils.book_new();
		newBook.props = {
			Title: "Load Transactions",
			Subject: "Load Transactions",
			Author: "Zentapon Pty. Ltd",
			CreatedDate: new Date()
		};
		newBook.SheetNames.push(sheetName);

		let newSheet = XLSX.utils.aoa_to_sheet(sheetHeaders);
		newBook.Sheets[sheetName] = newSheet;

		let exportedBook = XLSX.write(newBook, { bookType: "xlsx", type: "binary" });
		saveAs(new Blob([this.sheetToBinary(exportedBook)], { type: "application/octet-stream" }), fileName);
	};

	sheetToBinary = sheet => {
		let buf = new ArrayBuffer(sheet.length);
		let view = new Uint8Array(buf);
		for (let i = 0; i < sheet.length; i++) {
			view[i] = sheet.charCodeAt(i) & 0xff;
		}
		return buf;
	};

	// Editing and Deleting row data
	onEditRowClicked = selectedItem => {
		this.setState({
			editRow: true,
			selectedForEditing: selectedItem
		});
	};

	editingRowConfirm = editedData => {
		let newListData = this.state.listData;
		for (let i = 0; i < newListData.length; i++) {
			if (newListData[i].index === editedData.index) {
				newListData[i] = editedData;
				break;
			}
		}
		this.setState({
			editRow: false,
			listData: newListData,
			customSaveClass: "esl-save"
		});
	};

	editingRowCancel = () => {
		this.setState({
			editRow: false
		});
	};

	onDeleteRowClicked = selectedItem => {
		let listData = this.state.listData;
		let newListData = listData.filter(item => item.index !== selectedItem.index);

		this.setState({
			invalidData: "",
			listData: newListData,
			customSaveClass: "esl-save"
		});
	};

	render() {
		let { subListData } = this.props;
		const { listData, excelFileName, customSaveClass, invalidData, selectedForEditing } = this.state;

		let noData = <div className="esl-nodata">No entries for this sub-category</div>;
		let tableData = null;

		let preListLength = listData.length;

		if (preListLength > 0) {
			for (let j = 0; j < preListLength; j++) {
				listData[j].index = `list_${j + 1}`;
			}

			tableData = (
				<tbody className="esl-table-body">
					{listData.map((item, index) => (
						<tr key={`list_${index + 1}`}>
							<td width="5%">{index + 1}</td>
							<td width="12%">{moment(item.invoiceDate, "YYYY-MM-DD").format("MM/DD/YY")}</td>
							<td width="15%">{item.listName}</td>
							<td width="20%">{subListData.name}</td>
							<td width="15%">{priceFormatter(item.listAmount)}</td>
							<td width="12%">{moment(item.dueDate, "YYYY-MM-DD").format("MM/DD/YY")}</td>
							{/* <td width="12%">{moment(item.cashDate, "YYYY-MM-DD").format("MM/DD/YY")}</td> */}
							<td width="12%">Unpaid</td>
							<td width="9%">
								<div className={"esl-icons"}>
									<i className="fas fa-edit" onClick={() => this.onEditRowClicked(item)} />
									<i className="fas fa-trash-alt" onClick={() => this.onDeleteRowClicked(item)} />
								</div>
							</td>
						</tr>
					))}
				</tbody>
			);
			noData = null;
		}

		let dataError = null;
		if (invalidData !== "") {
			dataError = <div className={"esl-data-error"}>{invalidData}</div>;
		}
		return (
			<div id="editSubList">
				<Modal
					classNames={{ modal: "modal-editing-row" }}
					center
					open={this.state.editRow}
					onClose={this.editingRowCancel}
				>
					<EditingListRow
						selectedForEditing={selectedForEditing}
						editingRowConfirm={this.editingRowConfirm}
						editingRowCancel={this.editingRowCancel}
						subName={subListData.name}
						openingDate={this.props.openingDate}
					/>
				</Modal>

				<div className="esl-export-button" onClick={this.onTemplateExport}>
					Export empty template file to enter data
				</div>
				<div className="Clearfix" />
				<div className="esl-import-container">
					<div className="esl-import-button">
						<label className="esl-import-label" htmlFor="import-file">
							Import Excel File
						</label>
						<input
							type="file"
							id="import-file"
							className="esl-import-input"
							name="file"
							onChange={event => this.onFileUpload(event)}
							onClick={event => this.onFileReset(event)}
							accept=".xls,.xlsx"
						/>
					</div>
					<div className="esl-filename">{excelFileName}</div>
				</div>

				{dataError}
				<div className="esl-table-title">Imported Data</div>
				<table className="esl-list-table">
					<thead className="esl-table-head">
						<tr>
							<th width="5%">S No.</th>
							{sheetHeaders[0].map((item, index) => (
								<th key={`header_${index + 1}`} width={headerWidths[index + 1]}>
									{item}
								</th>
							))}
							{/* <th>Invoice Date</th>
							<th>Business Partner Name</th>
							<th>SubCategory</th>
							<th>Amount</th>
							<th>Due Date</th>
							<th>Cash Flow pay date</th> */}
							<th width="9%">&nbsp;</th>
						</tr>
					</thead>
					{tableData}
				</table>
				{noData}

				<button className={customSaveClass} onClick={() => this.onSaveClicked()}>
					Save Changes
				</button>
				<button className="esl-ok" onClick={() => this.props.editListCancel()}>
					Cancel
				</button>
			</div>
		);
	}
}

export default EditSubList;
