import React from "react";
import moment from "moment";

const Footer = () => {
	return (
		<footer id="main-footer">
			<div className="footer-container">
				<div className="footer-bottom">
					<h4 style={{ paddingRight: "4rem", paddingTop: "1rem" }}>Connect us with Social Media</h4>
					<div className="footer-icons">
						<a
							href="https://www.facebook.com/theCFO4U/"
							align="center"
							target="_blank"
							rel="noopener noreferrer"
						>
							<i className="fab fa-facebook-f" />
						</a>
						<a href="https://twitter.com/zentapon" align="center" target="_blank" rel="noopener noreferrer">
							<i className="fab fa-twitter" />
						</a>
						<a
							href="https://www.linkedin.com/in/kennethjandersen"
							align="center"
							target="_blank"
							rel="noopener noreferrer"
						>
							<i className="fab fa-linkedin-in" />
						</a>
						<a
							href="https://www.instagram.com/zentaflow/"
							align="center"
							target="_blank"
							rel="noopener noreferrer"
						>
							<i className="fab fa-instagram" />
						</a>
						{/* <a align="center">
							<i className="fab fa-youtube" />
						</a> */}
					</div>
				</div>
				<div className="footer-bottom">
					<h4 style={{ paddingRight: "4rem", paddingTop: "1rem" }}>Want to discuss about ZentaFlow?</h4>
					<a className="link-to-zf" href="https://zentaflow.com.au">
						Use our Chat option
					</a>
				</div>
				<p className="footer-copyright">© {moment().format("YYYY")} Zentapon Pty Ltd | All Rights Reserved</p>
			</div>
		</footer>
	);
};

export default Footer;
