import React, { Component } from 'react'
import moment from 'moment'

import { _getCurrency } from '../../helpers/constants'

let tableData = []
let setTheData = false

function formatDecimal(value) {
  let tempValue = value
  if (tempValue < 0) value = -1 * value
  let returnedValue = Math.round(value)
  let formattedValue = returnedValue
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  if (isNaN(returnedValue)) return ''
  else if (tempValue < 0) return _getCurrency + ' (' + formattedValue + ')'
  else return _getCurrency + ' ' + formattedValue
}

class SubDataTable extends Component {
  componentDidMount() {
    this.setTheData()
  }
  componentWillReceiveProps() {
    setTheData = false
  }

  componentDidUpdate() {
    if (!setTheData) {
      this.setTheData()
      setTheData = true
    }
  }

  setTheData = () => {
    let transactions = this.props.subTrans
    let type = this.props.selectedOption
    let subs = this.props.entriesData

    tableData = []
    if (subs.length > 0) {
      if (type === 'W') {
        this.distributeBalancesByWeek(transactions, subs)
      } else if (type === 'Y') {
        this.distributeBalancesByYear(transactions, subs)
      } else if (type === 'M') {
        this.distributeBalancesByMonth(transactions, subs)
      }
    }
    setTheData = true
  }

  distributeBalancesByWeek = (transactions, subs) => {
    let minimumWeekNumber = moment(
      this.props.openingDate,
      'YYYY-MM-DD'
    ).isoWeek()
    let currentYear = this.props.selectedYear
    let startingIndex = 1
    let openingYear = moment(this.props.openingDate, 'YYYY-MM-DD').year()

    if (openingYear === currentYear) startingIndex = minimumWeekNumber
    for (let subIndex = 0; subIndex < subs.length; subIndex++) {
      let tempData = []
      for (let i = startingIndex; i <= 53; i++) {
        let amount = 0

        for (let j = transactions.length - 1; j >= 0; j--) {
          if (transactions[j].typeId === subs[subIndex].subId) {
            let tDate = transactions[j].start_date
            let tWeekNum = moment(tDate, 'YYYY-MM-DD').isoWeek()
            let tYear = +tDate.substring(0, 4)

            if (tYear === currentYear) {
              if (tWeekNum === i) {
                amount += parseFloat(transactions[j].amount)
                transactions.splice(j, 1)
              }
            }
          }
        }

        tempData.push({
          id: i,
          amount: amount
        })
      }
      tableData.push({
        subId: subs[subIndex].subId,
        subData: tempData
      })
    }
  }

  distributeBalancesByMonth = (transactions, subs) => {
    let minMonthNum = moment(this.props.openingDate, 'YYYY-MM-DD').month()
    let currentYear = this.props.selectedYear
    let startingIndex = 1

    if (moment(this.props.openingDate, 'YYYY-MM-DD').year() === currentYear)
      startingIndex = minMonthNum + 1
    for (let subIndex = 0; subIndex < subs.length; subIndex++) {
      let tempData = []
      for (let i = startingIndex; i <= 12; i++) {
        let amount = 0

        for (let j = transactions.length - 1; j >= 0; j--) {
          if (transactions[j].typeId === subs[subIndex].subId) {
            let tDate = transactions[j].start_date
            let tMonthNum = Number(tDate.substring(5, 7))
            let tYear = +tDate.substring(0, 4)

            if (tYear === currentYear) {
              if (tMonthNum === i) {
                amount += parseFloat(transactions[j].amount)
                transactions.splice(j, 1)
              }
            }
          }
        }

        tempData.push({
          id: i,
          amount: amount
        })
      }
      tableData.push({
        subId: subs[subIndex].subId,
        subData: tempData
      })
    }
  }

  distributeBalancesByYear = (transactions, subs) => {
    let minYear = moment(this.props.openingDate, 'YYYY-MM-DD').year()

    for (let subIndex = 0; subIndex < subs.length; subIndex++) {
      let tempData = []
      for (let i = minYear; i < 2030; i++) {
        let amount = 0

        for (let j = transactions.length - 1; j >= 0; j--) {
          if (transactions[j].typeId === subs[subIndex].subId) {
            let tDate = transactions[j].start_date
            let tYear = +tDate.substring(0, 4)

            if (tYear === i) {
              amount += parseFloat(transactions[j].amount)
              transactions.splice(j, 1)
            }
          }
        }

        tempData.push({
          id: i,
          amount: amount
        })
      }
      tableData.push({
        subId: subs[subIndex].subId,
        subData: tempData
      })
    }
  }

  render() {
    let displayedTable = null

    if (tableData.length > 0) {
      displayedTable = (
        <div id="sub-data-table">
          <table className="sub-table">
            <tbody className="row-container">
              {tableData.map((item, index) => (
                <tr key={`id_${item.subId}`} className="sub-row">
                  {item.subData.map((sub, index) => (
                    <td key={`id_${sub.id}`}>{formatDecimal(sub.amount)}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )
    }
    return <div>{displayedTable}</div>
  }
}

export default SubDataTable
