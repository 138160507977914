import {
  getActiveUser,
  _getOpeningDate,
  connectingBaseLink,
  _getTodayDate
} from "../../helpers/constants";
import axios from "axios";
import {
  ADD_TRANSACTION,
  EDIT_TRANSACTION,
  OPEN_FORM,
  CLEAR_FORM,
  GET_TRANSACTIONS,
  GET_REMINDERS
} from "./types";
import { showSuccessToaster, showErrorToaster } from "./uiActions";
import { getCategoryData } from "./userActions";

export const addTransaction = (transList, scenarioIds) => dispatch => {
  const data = {
    user: getActiveUser(),
    opening: _getOpeningDate(),
    transList,
    scenarioIds
  };

  axios
    .post(`${connectingBaseLink}transactions/addTrans.php`, data)
    .then(res => {
      if (res.data.success) {
        let response = res.data.success;
        dispatch({
          type: ADD_TRANSACTION,
          payload: response
        });
        dispatch(getCategoryData(scenarioIds));
        dispatch(getReminders(_getTodayDate(), scenarioIds, false));
        dispatch(showSuccessToaster("Transaction successfully added."));
      } else {
        dispatch(
          showErrorToaster("Adding transaction error. Please try again.")
        );
      }
    })
    .catch(err => dispatch(showErrorToaster(err.response.data)));
};

export const addSubTransaction = (
  transList,
  scenarioIds,
  status
) => dispatch => {
  const data = {
    user: getActiveUser(),
    opening: _getOpeningDate(),
    transList,
    scenarioIds,
    status
  };

  axios
    .post(`${connectingBaseLink}transactions/addTransSS.php`, data)
    .then(res => {
      if (res.data.success) {
        let response = res.data.success;
        dispatch({
          type: ADD_TRANSACTION,
          payload: response
        });
        dispatch(getCategoryData(scenarioIds));
        dispatch(getReminders(_getTodayDate(), scenarioIds, false));
        dispatch(showSuccessToaster("Transactions successfully saved."));
      } else {
        dispatch(showErrorToaster("Transactions not saved."));
      }
    })
    .catch(err => dispatch(showErrorToaster("Error connecting to database.")));
};

export const editTransaction = (
  transList,
  oldData,
  scenarioIds,
  sId
) => dispatch => {
  const data = {
    user: getActiveUser(),
    opening: _getOpeningDate(),
    oldData,
    transList,
    scenarioIds,
    sId
  };

  axios
    .post(`${connectingBaseLink}transactions/editTrans.php`, data)
    .then(res => {
      if (res.data.success) {
        let response = res.data.success;
        dispatch({
          type: EDIT_TRANSACTION,
          payload: response
        });
        dispatch(getCategoryData(scenarioIds));
        dispatch(getReminders(_getTodayDate(), scenarioIds, false));
        dispatch(showSuccessToaster("Transaction successfully edited."));
      } else {
        dispatch(showErrorToaster("Editing error. Please try again."));
      }
    })
    .catch(err => dispatch(showErrorToaster(err.response.data)));
};

export const deleteTransaction = (
  scenarioIds,
  date,
  millis,
  transId,
  transScenario,
  option
) => dispatch => {
  const data = {
    user: getActiveUser(),
    opening: _getOpeningDate(),
    scenarioIds,
    date,
    millis,
    transId,
    transScenario,
    option
  };

  axios
    .post(`${connectingBaseLink}transactions/delTrans.php`, data)
    .then(res => {
      if (res.data.success) {
        let response = res.data.success;
        dispatch({
          type: EDIT_TRANSACTION,
          payload: response
        });
        dispatch(getCategoryData(scenarioIds));
        dispatch(showSuccessToaster("Transaction deleted successfully."));
      } else {
        dispatch(showErrorToaster("Error deleting transaction."));
      }
    })
    .catch(err => dispatch(showErrorToaster(err.response.data)));
};

export const deleteTransactionById = (ids, scenarioIds) => dispatch => {
  ids = ids.join(", ");
  const data = {
    user: getActiveUser(),
    ids,
    scenarioIds,
    opening: _getOpeningDate()
  };

  axios
    .post(`${connectingBaseLink}transactions/delTransById.php`, data)
    .then(res => {
      if (res.data.success) {
        let response = res.data.success;
        dispatch({
          type: EDIT_TRANSACTION,
          payload: response
        });
        dispatch(getCategoryData(scenarioIds));
        dispatch(getReminders(_getTodayDate(), scenarioIds, false));
        dispatch(showSuccessToaster("Paid Transaction removed successfully."));
      } else {
        dispatch(showErrorToaster("Error removing transaction."));
      }
    })
    .catch(err => dispatch(showErrorToaster(err.response.data)));
};

export const openTransactionForm = (mode, data) => {
  return {
    type: OPEN_FORM,
    payload: { mode, data }
  };
};

export const clearTransactionForm = () => {
  return {
    type: CLEAR_FORM
  };
};

export const searchTransaction = (
  allScIds,
  searchTag,
  scIds,
  cIds
) => dispatch => {
  const data = { user: getActiveUser(), allScIds, searchTag, scIds, cIds };

  axios
    .post(`${connectingBaseLink}transactions/searchTrans.php`, data)
    .then(res => {
      if (res.data.success) {
        dispatch({
          type: GET_TRANSACTIONS,
          payload: res.data.success
        });
      } else {
        dispatch(showErrorToaster(res.data.message));
      }
    })
    .catch(() => {});
};

export const getReminders = (opening, scenarioIds, isPast) => dispatch => {
  const data = { user: getActiveUser(), opening, scenarioIds, isPast };

  axios
    .post(`${connectingBaseLink}transactions/readReminders.php`, data)
    .then(res => {
      if (res.data.message !== "no") {
        dispatch({
          type: GET_REMINDERS,
          payload: res.data.message
        });
      }
    })
    .catch(err => {
      dispatch({
        type: GET_REMINDERS,
        payload: []
      });
    });
};
