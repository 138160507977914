import React from "react";

const ThreeWayDialog = props => {
	return (
		<div id="threeWayDialog">
			<div className="twd-text">{props.dialogMessage}</div>
			<div className="twd-content">
				<button className="twd-this" onClick={() => props.onDeleteRepeatClicked(1)}>
					Delete this Transaction
				</button>
				<button className="twd-future" onClick={() => props.onDeleteRepeatClicked(2)}>
					Delete Future Transactions
				</button>
				<button className="twd-all" onClick={() => props.onDeleteRepeatClicked(3)}>
					{props.dialogPositive}
				</button>
				<button className="twd-cancel" onClick={() => props.onDeleteCancelClicked()}>
					{props.dialogNegative}
				</button>
			</div>
		</div>
	);
};

export default ThreeWayDialog;
