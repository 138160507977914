import axios from "axios";
import setAuthToken from "../../helpers/setAuthToken";

import {
  GET_ERRORS,
  SET_CURRENT_USER,
  STOP_INITIAL_LOADER,
  CLEAR_ERRORS
} from "./types";
import {
  connectingBaseLink,
  _storageActiveUser
} from "../../helpers/constants";
import isEmpty from "../../validation/is-empty";
import { justLoggedIn } from "./userActions";

export const registerUser = (
  email,
  password,
  categories,
  homeCategories,
  history
) => dispatch => {
  dispatch(clearErrors());
  const data = { email, password, categories, homeCategories };
  axios
    .post(`${connectingBaseLink}home/create.php`, data)
    .then(res => {
      if (res.data.success) {
        history.push("/login");
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: res.data
        });
      }
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: { message: "Couldn't connect to the server" }
      });
    });
};

export const loginUser = (email, password) => dispatch => {
  dispatch(clearErrors());
  const data = { email, password };
  axios
    .post(`${connectingBaseLink}home/read.php`, data)
    .then(res => {
      if (res.data.success) {
        const { token } = res.data.success;
        localStorage.setItem(_storageActiveUser, JSON.stringify(token));
        setAuthToken(token);

        dispatch(setCurrentUser(token));
        dispatch(setLoginCount(token));
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: res.data
        });
      }
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: { message: "Couldn't connect to the server" }
      });
    });
};

export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

export const validateUser = (user, history) => dispatch => {
  const data = { user };
  if (!isEmpty(user)) {
    axios
      .post(`${connectingBaseLink}home/validate.php`, data)
      .then(res => {
        if (res.data.authorized) {
          dispatch(setCurrentUser(user));
          dispatch(stopInitialLoader());
          history.push("/dashboard");
        } else {
          dispatch(stopInitialLoader());
          history.push("/login");
        }
      })
      .catch(err => {
        dispatch(stopInitialLoader());
        history.push("/login");
      });
  } else {
    dispatch(stopInitialLoader());
    history.push("/login");
  }
};

export const setLoginCount = user => dispatch => {
  const data = { user };
  dispatch(justLoggedIn(true));

  axios.post(`${connectingBaseLink}home/setLoginCount.php`, data);
};

export const logoutUser = () => dispatch => {
  localStorage.removeItem(_storageActiveUser);
  setAuthToken(false);
  dispatch(setCurrentUser({}));
};

export const stopInitialLoader = () => {
  return {
    type: STOP_INITIAL_LOADER
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};
