import React from "react";
import MenuDivider from "../common/MenuDivider";

class ScenarioDataPopUp extends React.Component {
  constructor() {
    super();
    this.inputRef = React.createRef();
    this.nameRef = React.createRef();
  }

  confirmEdit = () => {
    this.props.editingConfirmed(this.inputRef.current.value, this.nameRef.current.value);
  };

  render() {
    let scenarioNames = this.props.scenarios;
    return (
      <div id="modalScenarioDataPopup" style={{ overflow: "hidden" }}>
        <div className="msdp-title">
          <div>Edit Scenario Details</div>
          {scenarioNames.name === "Main Account" || scenarioNames.name === "What If?" ? null : (
            <div className={"msdp-delete"} onClick={() => this.props.deleteScenarioHandler(scenarioNames.name)}>
              <i className="fas fa-trash" />
            </div>
          )}
        </div>
        <MenuDivider />

        <div className="msdp-body">
          {scenarioNames.name === "Main Account" || scenarioNames.name === "What If?" ? (
            <div id={scenarioNames.name} ref={this.nameRef} style={{ width: "50%", paddingTop: ".2rem" }}>
              {scenarioNames.name}
            </div>
          ) : (
            <input
              type="text"
              id={scenarioNames.name}
              defaultValue={scenarioNames.name}
              // onChange={() => this.onChange()}
              ref={this.nameRef}
            />
          )}
          <input type="number" step=".01" max="1000000000" ref={this.inputRef} className="msdp-amount" defaultValue={scenarioNames.opening} />
        </div>
        <MenuDivider />
        <div className="msdp-button">
          <button className="msdp-cancel" onClick={() => this.props.editingCancelled()}>
            Cancel
          </button>
          <button className="msdp-confirm" onClick={() => this.confirmEdit()}>
            Confirm
          </button>
        </div>
      </div>
    );
  }
}

export default ScenarioDataPopUp;
