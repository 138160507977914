import React from "react";

const XeroWindow = ({ link, onClose }) => {
	return (
		<div id="xeroWindow">
			<div style={{ padding: "2rem" }}>
				You should have a Xero Account to sync with app or you could create a new Xero Account
			</div>
			<div className="xw-button">
				<button
					className="xw-link"
					onClick={onClose}
					style={{ border: "none", outline: "none", background: "#5784b9" }}
				>
					Cancel
				</button>
				<a href={link} className="xw-link">
					Continue with Xero Sync
				</a>
			</div>
		</div>
	);
};

export default XeroWindow;
