import React, { Component } from "react";
import MenuDivider from "../common/MenuDivider";

const minCategories = 9;

class ModifyCategory extends Component {
	checkForInputName = newName => {
		let actualName = newName.trim();
		if (actualName.length < 3) {
			// write the error code here
		} else {
			this.props.editCategoryConfirm(actualName);
		}
	};

	render() {
		return (
			<div id="modalModifyCategory">
				<div className="mmc-title">
					<div>
						Edit Category - <strong>{this.props.categoryName}</strong>
					</div>
					{this.props.totalCategories > minCategories ? (
						<div
							className="mmc-delete"
							onClick={() => this.props.deleteCategoryHandler(this.props.categoryName)}
						>
							<i className="fas fa-trash-alt" title="Delete this category instead" />
						</div>
					) : null}
				</div>
				<MenuDivider />
				<div className="mmc-body">
					<div style={{ width: "40%", paddingTop: ".3rem", textAlign: "right" }}>Enter new name</div>
					<input type="text" id="new_name" />
				</div>
				<div className="mmc-sbutton">
					<button className="mmc-cancel" onClick={() => this.props.editCategoryCancel()}>
						Cancel
					</button>
					<button
						className="mmc-confirm"
						onClick={() => this.checkForInputName(document.getElementById("new_name").value)}
					>
						Confirm
					</button>
				</div>
			</div>
		);
	}
}

export default ModifyCategory;
