import {
	TOGGLE_NAVIGATION,
	SET_WINDOW_DIMENSIONS,
	SHOW_SUCCESS_TOASTER,
	SHOW_ERROR_TOASTER,
	SHOW_WARNING_TOASTER,
	CLEAR_TOASTERS,
	CONTINUE_GUIDED_TOUR,
	END_TOUR,
	START_GUIDED_TOUR
} from "../actions/types";

const initialState = {
	navigationOn: false,
	guidedTour: {
		status: false,
		element: 1
	},
	width: window.innerWidth,
	height: window.innerHeight,
	curveHeight: 0,
	barHeight: 0,
	toasters: {}
};

export default function(state = initialState, action) {
	switch (action.type) {
		case TOGGLE_NAVIGATION:
			return {
				...state,
				navigationOn: action.payload
			};
		case SET_WINDOW_DIMENSIONS:
			return {
				...state,
				width: window.innerWidth,
				height: window.innerHeight,
				curveHeight: action.payload.curveHeight,
				barHeight: action.payload.barHeight
			};
		case SHOW_SUCCESS_TOASTER:
			return {
				...state,
				toasters: { success: action.payload }
			};
		case SHOW_ERROR_TOASTER:
			return {
				...state,
				toasters: { errors: action.payload }
			};
		case SHOW_WARNING_TOASTER:
			return {
				...state,
				toasters: { warning: action.payload }
			};
		case CLEAR_TOASTERS:
			return {
				...state,
				toasters: {}
			};
		case START_GUIDED_TOUR:
			return {
				...state,
				guidedTour: { status: true, element: 1 }
			};
		case CONTINUE_GUIDED_TOUR:
			return {
				...state,
				guidedTour: { status: true, element: action.payload }
			};
		case END_TOUR:
			return {
				...state,
				guidedTour: { status: false, element: 1 }
			};
		default:
			return state;
	}
}
