import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-responsive-modal'
import moment from 'moment'
import XLSX from 'xlsx'
import Tour from 'reactour'
import axios from 'axios'

import DayPickerInput from 'react-day-picker/DayPickerInput'
import { formatDate, parseDate } from 'react-day-picker/moment'

import {
  deleteTransaction,
  endTour,
  continueGuidedTour,
  addSubTransaction,
  openTransactionForm,
  clearTransactionForm,
  searchTransaction,
  getTransactions,
  showWarningToaster
} from '../../store/actions'
import TransData from './TransData'
import ConfirmationDialog from '../dialogs/ConfirmationDialog'
import InformationalDialog from '../dialogs/InformationalDialog'
import {
  connectingBaseLink,
  getCategories,
  getActiveUser
} from '../../helpers/constants'
import ThreeWayDialog from '../dialogs/ThreeWayDialog'

import Layout from '../layout/Layout'
import AddForm from '../layout/AddForm'
import isEmpty from '../../validation/is-empty'

let newTransactions, serialNumber, selectedData
let clickTriggered = false
let clickCounter = 0

const sheetHeaders = [['Date', 'Scenario', 'Description', 'Category', 'Amount']]

function isNumeric(value) {
  return !isNaN(parseFloat(value)) && isFinite(value)
}

class Transactions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      newTransaction: false,
      showDeleteConfirmation: false,
      showRepeatingDelete: false,
      transactions: [],
      scenarios: [],
      beforeSearch: [],
      importedTransactions: [],
      showSaveConfirm: false,
      allScenarioIds: [],
      showInfoDialog: false,
      run: false,
      searchActive: false,
      loadingTransactions: false,
      from: undefined,
      to: undefined,
      redirectComplete: false,
      fromDate: null,
      toDate: null,
      steps: [
        {
          selector: '#transaction_handler',
          content: 'Check out Future Scenario (Bank) statements here.',
          position: 'top'
        },
        {
          selector: '#transaction_handler',
          content: 'Check out Future Scenario (Bank) statements here.',
          position: 'top'
        }
      ]
    }
    this.selectRef = React.createRef()
    this.handleFromChange = this.handleFromChange.bind(this)
    this.handleToChange = this.handleToChange.bind(this)
  }

  /*****************************************************/
  /************* SETTING OF TRANSACTIONS ***************/
  resetValues = () => {
    newTransactions = []
    serialNumber = 1
    selectedData = null
  }

  componentDidMount() {
    const { location } = this.props
    if (location && location.state && location.state.openForm) {
      this.addNewTransaction()
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.ui.guidedTour.status &&
      nextProps.ui.guidedTour.element === 6
    ) {
      this.tooltipTimeout = setTimeout(() => this.setState({ run: true }), 1000)
    }
    const { scenarios, transactions } = nextProps.user
    this.setState({ scenarios })
    this.getAllTransactions(transactions, true)
  }

  getAllTransactions = (filtered, allActive) => {
    this.resetValues()
    serialNumber = 1

    let balancedTransactions = this.setBalancesForTransaction(
      filtered,
      allActive
    )

    for (let i = 0, len = balancedTransactions.length; i < len; i++) {
      let transaction = balancedTransactions[i]
      this.addNewTransactionObject(transaction)
    }
    this.setState({
      transactions: newTransactions,
      beforeSearch: newTransactions,
      showDeleteConfirmation: false,
      showRepeatingDelete: false,
      showSaveConfirm: false,
      loadingTransactions: false
    })
  }

  setBalancesForTransaction = (transactions, allActive) => {
    let tempTrans = transactions.slice(0)
    tempTrans.sort((a, b) => {
      if (a.start_date === b.start_date) {
        return a.id > b.id ? 1 : -1
      } else {
        return a.start_date > b.start_date ? 1 : -1
      }
    })

    let currentBalance = 0
    if (allActive || transactions.length < 1) {
      for (let i = 0, len = this.state.scenarios.length; i < len; i++) {
        if (this.state.scenarios[i].status === '1') {
          currentBalance += +this.state.scenarios[i].opening
        }
      }
    } else {
      for (let i = 0, len = this.state.scenarios.length; i < len; i++) {
        if (this.state.scenarios[i].name === transactions[0].scenario) {
          currentBalance += +this.state.scenarios[i].opening
        }
      }
    }

    tempTrans = tempTrans.map(item => {
      currentBalance += parseFloat(item.amount)
      item.balance = currentBalance
      return item
    })

    return tempTrans
  }

  addNewTransactionObject = transaction => {
    const temporaryTransaction = transaction
    transaction.sNumber = serialNumber
    newTransactions.push(temporaryTransaction)
    serialNumber++
  }

  /*****************************************************/
  /*********** ADD NEW TRANSACTION RELATED *************/
  addNewTransaction = () => {
    this.props.openTransactionForm('add', '')
    this.setState({ newTransaction: true })
  }

  newTransactionCancelled = () => {
    this.props.clearTransactionForm()
    this.setState({
      newTransaction: false
    })
  }

  /*****************************************************/
  /*********** DELETE TRANSACTION RELATED *************/
  onDeleteConfirmClicked = () => {
    this.onDeleteRepeatClicked(1)
  }

  onDeleteCancelClicked = () => {
    this.setState({
      showDeleteConfirmation: false,
      showRepeatingDelete: false
    })
  }

  onDeleteRepeatClicked = option => {
    const { activeScIds } = this.props.user
    this.props.deleteTransaction(
      activeScIds,
      selectedData.start_date,
      selectedData.millis,
      selectedData.id,
      selectedData.scenario,
      option
    )
  }

  rowInConcern = (mode, data) => {
    selectedData = data
    if (!isEmpty(data)) {
      if (mode === 'del') {
        if (data.frequency_id !== 'f0') {
          this.setState({ showRepeatingDelete: true })
        } else {
          this.setState({ showDeleteConfirmation: true })
        }
      } else {
        this.props.openTransactionForm(mode, data)
        this.setState({ newTransaction: true })
      }
    }
  }

  /*****************************************************/
  /****************** SEARCH RELATED *******************/

  searchClicked = () => {
    let temp = document.getElementById('search_transaction').value
    temp = temp.trim()
    let searchValue = temp + ' '
    searchValue = searchValue.substr(0, searchValue.indexOf(' '))

    const { scenarios } = this.props.user
    let allScenarioIds = scenarios.map(item => item.id)

    let searchedScenarioIds = scenarios
      .filter(
        item =>
          item.name
            .toString()
            .toLowerCase()
            .search(searchValue.toString().toLowerCase()) !== -1
      )
      .map(item => item.id)

    let categories = getCategories()

    let searchedCategoryIds = categories
      .filter(
        item =>
          item.category_name
            .toString()
            .toLowerCase()
            .search(searchValue.toString().toLowerCase()) !== -1
      )
      .map(item => item.category_id)
    searchedScenarioIds = searchedScenarioIds.join(', ')
    searchedCategoryIds = searchedCategoryIds.join(', ')
    allScenarioIds = allScenarioIds.join(', ')

    this.props.searchTransaction(
      allScenarioIds,
      searchValue,
      searchedScenarioIds,
      searchedCategoryIds
    )
    this.setState({ searchActive: true, loadingTransactions: true })
    clickTriggered = false
    clickCounter = 0
  }

  onKeyPressed = event => {
    if (event.keyCode === 13) this.searchClicked()
  }

  refreshTransactions = () => {
    clickTriggered = false
    clickCounter = 0
    this.setState({
      searchActive: false,
      loadingTransactions: true,
      fromDate: null,
      toDate: null
    })
    document.getElementById('search_transaction').value = ''
    this.props.getTransactions(this.props.user.activeScIds)
  }

  /*****************************************************/
  /************** IMPORTING TRANSACTIONS ***************/
  resetImportFile = e => {
    e.target.value = null
  }
  importTransactionsHandler = e => {
    let files = e.target.files
    const { categoryNames } = this.props.user

    if (typeof files[0] !== 'undefined') {
      let reader = new FileReader()
      reader.readAsArrayBuffer(files[0])

      reader.onload = e => {
        let arrayBuffer = e.target.result

        let data = new Uint8Array(arrayBuffer)
        let arr = []
        for (let i = 0; i !== data.length; ++i)
          arr[i] = String.fromCharCode(data[i])
        let bstr = arr.join('')

        let workbook = XLSX.read(bstr, { type: 'binary' })
        let first_worksheet = workbook.Sheets[workbook.SheetNames[0]]

        let range = XLSX.utils.decode_range(first_worksheet['!ref'])

        let result = []
        // let maxColumn = range.e.c > 5 ? 5 : range.e.c;
        let maxColumn = range.e.c
        // let maxRow = range.e.r > 9 ? 9 : range.e.r;
        let maxRow = range.e.r

        let headers = []
        for (let i = range.s.c; i <= maxColumn; i++) {
          // if (i === 0 || i === 2 || i === 4) continue;
          let cell = first_worksheet[XLSX.utils.encode_cell({ r: 0, c: i })]
          if (typeof cell !== 'undefined') {
            headers.push(cell.w)
          }
        }

        for (let rowNum = range.s.r + 1; rowNum <= maxRow; rowNum++) {
          let row = []
          // result.push(first_worksheet[XLSX.utils.encode_row(rowNum)]);
          for (let colNum = range.s.c; colNum <= maxColumn; colNum++) {
            // if (colNum === 0 || colNum === 2 || colNum === 4) continue;
            let cell =
              first_worksheet[XLSX.utils.encode_cell({ r: rowNum, c: colNum })]
            if (typeof cell === 'undefined') {
              row.push('N/A')
            } else {
              row.push(cell.w)
            }
          }
          result.push(row)
        }

        let newTransaction = []

        // If result found
        if (result.length > 0) {
          let indexDate = -1,
            indexScenario = -1,
            indexDesc = -1,
            indexCategory = -1,
            indexAmount = -1

          indexDate = headers.indexOf(sheetHeaders[0][0])
          indexScenario = headers.indexOf(sheetHeaders[0][1])
          indexDesc = headers.indexOf(sheetHeaders[0][2])
          indexCategory = headers.indexOf(sheetHeaders[0][3])
          indexAmount = headers.indexOf(sheetHeaders[0][4])

          // Check if column exists
          if (
            indexDate >= 0 &&
            indexScenario >= 0 &&
            indexDesc >= 0 &&
            indexCategory >= 0 &&
            indexAmount >= 0
          ) {
            let millisCounter = 0
            for (let i = 0; i < result.length; i++) {
              let scenarioNames = this.state.scenarios
              for (let sIndex = 0; sIndex < scenarioNames.length; sIndex++) {
                // Importing only existing scenarios
                if (
                  scenarioNames[sIndex].name.trim().toLowerCase() ===
                  result[i][indexScenario].trim().toLowerCase()
                ) {
                  if (categoryNames.includes(result[i][indexCategory])) {
                    let invalidData = false
                    // Checking and setting cash flow date
                    if (
                      !moment(result[i][indexDate], 'M/D/YY', true).isValid()
                    ) {
                      invalidData = true
                      break
                    }
                    let momentDate = moment(
                      result[i][indexDate],
                      'M/D/YY'
                    ).toDate()
                    let start_date = moment(momentDate).format('YYYY-MM-DD')

                    // Checking the validity of amount
                    if (!isNumeric(result[i][indexAmount])) {
                      invalidData = true
                      break
                    }

                    let d = new Date()
                    let millis = d.getTime()

                    if (!invalidData) {
                      // Note that category id here is name... change it to id in back-end
                      let transaction = {
                        scenario_id: scenarioNames[sIndex].id,
                        category_id: result[i][indexCategory],
                        transaction_type: 'S',
                        type_id: 0,
                        description: result[i][indexDesc],
                        start_date: start_date,
                        amount: result[i][indexAmount],
                        balance: result[i][indexAmount],
                        frequency_id: 'f0',
                        frequency_type: 'One Time',
                        end_type_id: '1',
                        end_type_value: start_date,
                        millis: millis + millisCounter,
                        reminder: 'No',
                        reminder_note: null,
                        xtrans: null
                      }
                      newTransaction.push(transaction)
                      millisCounter++
                    }
                  }
                }
              }
            }
          }

          if (newTransaction.length > 0) {
            let allScenarioIds = this.state.scenarios.map(item => item.id)
            this.setState({
              importedTransactions: newTransaction,
              showSaveConfirm: true,
              allScenarioIds: allScenarioIds
            })
          } else {
            this.setState({
              showInfoDialog: true
            })
          }
        }
      }
    }
  }

  onSaveConfirm = () => {
    let allTransactions = this.state.importedTransactions
    this.props.addSubTransaction(
      allTransactions,
      this.state.allScenarioIds,
      true
    )
    this.setState({
      showSaveConfirm: false
    })
  }

  onSaveCancel = () => {
    let allTransactions = this.state.importedTransactions
    this.props.addSubTransaction(
      allTransactions,
      this.state.allScenarioIds,
      false
    )
    this.setState({
      showSaveConfirm: false
    })
  }

  onInfoClosed = () => {
    this.setState({
      showInfoDialog: false
    })
  }

  /*****************************************************/
  /********************* REACT TOUR ********************/
  closeTour = () => {
    this.setState({ run: false })
    this.props.continueGuidedTour(1)
    this.props.endTour()
  }

  getCurrentStep = data => {
    if (data === 1) {
      this.setState({ run: false })
      this.props.continueGuidedTour(7)
    }
  }

  onScenarioChange = () => {
    let selectRefId = this.selectRef.current.value
    const { searchActive } = this.state
    this.setState({ loadingTransactions: true })

    if (searchActive) {
      this.selectingFromSearched(selectRefId)
    } else {
      const { openingDate } = this.props.user
      const data = {
        user: getActiveUser(),
        opening: openingDate,
        scenarios: this.selectRef.current.value
      }

      axios
        .post(`${connectingBaseLink}transactions/readSpecificT.php`, data)
        .then(res => {
          let response = res.data.message
          this.getAllTransactions(response, false)
        })
    }
  }

  onScenarioClicked = () => {
    if (this.state.searchActive && !clickTriggered) {
      let selectRefId = this.selectRef.current.value
      if (clickCounter === 0) {
        clickCounter++
      } else if (clickCounter === 1) {
        this.selectingFromSearched(selectRefId)
        clickCounter++
        clickTriggered = true
      }
    }
  }

  selectingFromSearched = selectRefId => {
    const { scenarios } = this.state
    let askedTransactions = this.state.beforeSearch.slice(0)
    let selectRefScenario = 'Main Account'
    for (let i = 0; i < scenarios.length; i++) {
      if (scenarios[i].id === selectRefId) {
        selectRefScenario = scenarios[i].name
        break
      }
    }
    askedTransactions = askedTransactions.filter(
      item => item.scenario === selectRefScenario
    )
    this.setSearchedTransactions(askedTransactions)
  }

  setSearchedTransactions = transactions => {
    this.resetValues()
    serialNumber = 1

    for (let i = 0, len = transactions.length; i < len; i++) {
      this.addNewTransactionObject(transactions[i])
    }

    this.setState({
      transactions: newTransactions,
      loadingTransactions: false
    })
  }

  /*****************************************************/
  /******************* DAY PICKER **********************/
  handleFromChange(from) {
    this.setState({ from })
  }

  handleToChange(to) {
    this.setState({ to }, this.changeDates)
  }

  changeDates = () => {
    const { from, to } = this.state
    if (!from) {
      return
    }

    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from)
    }

    this.setState({
      fromDate: moment(from).format('YYYY-MM-DD'),
      toDate: moment(to).format('YYYY-MM-DD')
    })
  }

  render() {
    const { openingDate, activeScNames, activeScIds } = this.props.user
    const { guidedTour } = this.props.ui
    const { endTour } = this.props
    const {
      steps,
      run,
      scenarios,
      transactions,
      loadingTransactions,
      fromDate,
      toDate
    } = this.state
    const { from, to } = this.state
    const modifiers = { start: from, end: to }
    const dropdownScenarios = scenarios.filter(item =>
      activeScIds.includes(item.id)
    )

    let displayTransactions = [...transactions]
    if (fromDate && toDate) {
      displayTransactions = transactions.filter(
        item => item.start_date >= fromDate && item.start_date <= toDate
      )
    }

    return (
      <Layout>
        <div id="transactions">
          <Modal
            open={this.state.newTransaction}
            onClose={this.newTransactionCancelled}
            center
            classNames={{ modal: 'modal-transactions' }}
          >
            <AddForm
              scenarioIds={activeScIds}
              scenarios={scenarios}
              currentScenario={activeScNames}
              newTransactionCancelled={this.newTransactionCancelled}
              newTransactionEdited={this.newTransactionCancelled}
              openingDate={openingDate}
            />
          </Modal>

          <Modal
            open={this.state.showDeleteConfirmation}
            onClose={this.onDeleteCancelClicked}
            center
            classNames={{ modal: 'modal-custom' }}
          >
            <ConfirmationDialog
              dialogMessage={'Are you sure you want to delete this row?'}
              dialogPositive={'Confirm'}
              dialogNegative={'Cancel'}
              onDeleteConfirmClicked={this.onDeleteConfirmClicked}
              onDeleteCancelClicked={this.onDeleteCancelClicked}
            />
          </Modal>

          <Modal
            open={this.state.showSaveConfirm}
            onClose={this.onSaveCancel}
            center
            classNames={{ modal: 'modal-custom' }}
          >
            <ConfirmationDialog
              dialogMessage={
                'We have found some valid transactions. Choose your option.'
              }
              dialogPositive={'Save and keep older'}
              dialogNegative={'Save and remove older'}
              onDeleteConfirmClicked={this.onSaveConfirm}
              onDeleteCancelClicked={this.onSaveCancel}
            />
          </Modal>

          <Modal
            open={this.state.showInfoDialog}
            onClose={this.onInfoClosed}
            center
            classNames={{ modal: 'modal-custom' }}
          >
            <InformationalDialog
              dialogTitle={'No valid transactions found'}
              dialogText={
                "Make sure the file isn't empty and has valid data types. \n Also make sure that the file contains following columns: \n (Date, Scenario, Description, Category and Amount)"
              }
              dialogPositive={'OK'}
              dialogClicked={this.onInfoClosed}
            />
          </Modal>

          <Modal
            open={this.state.showRepeatingDelete}
            onClose={this.onDeleteCancelClicked}
            center
            classNames={{ modal: 'modal-three-way' }}
          >
            <ThreeWayDialog
              dialogMessage={
                'This is a repeating type transaction. Select your option.'
              }
              dialogPositive={'Delete All Transactions'}
              dialogNegative={'Cancel'}
              onDeleteRepeatClicked={this.onDeleteRepeatClicked}
              onDeleteCancelClicked={this.onDeleteCancelClicked}
            />
          </Modal>

          <Tour
            steps={steps}
            isOpen={run}
            showNavigationNumber={false}
            showNumber={false}
            showNavigation={false}
            prevButton={<div style={{ display: 'none' }} />}
            nextButton={<div className={'tour-next-button'}>Next</div>}
            onRequestClose={this.closeTour}
            accentColor="#365478"
            getCurrentStep={this.getCurrentStep}
          />

          <div className="search-wrapper">
            <input
              type="search"
              id="search_transaction"
              onKeyDown={event => this.onKeyPressed(event)}
              placeholder="Search for transactions..."
            />
            <div className={'icon'} onClick={() => this.searchClicked()}>
              <i className="fas fa-search" />
            </div>
            <button
              onClick={() => this.refreshTransactions()}
              className="search-refresh"
            >
              Refresh
              <i className="fas fa-redo-alt" />
            </button>
          </div>

          <div className="clearfix" />
          <div className="import-feature">
            <div className="button-import-new">
              <label className="import-label" htmlFor="import-file">
                Import from Spreadsheet
              </label>
              <input
                type="file"
                id="import-file"
                name="file"
                className="import-input"
                onChange={event => this.importTransactionsHandler(event)}
                onClick={event => this.resetImportFile(event)}
                accept=".xls,.xlsx"
              />
            </div>
            <a
              href={`${connectingBaseLink}assets/sample-docs/zentaflow-transactions.xlsx`}
              download="zentaflow-transactions.xlsx"
              className="get-template"
            >
              Get Template
            </a>
          </div>

          <div className="future-transactions">
            <div>Show me future bank statement for </div>
            <select
              name="futureTransactions"
              ref={this.selectRef}
              id="selectRef"
              onChange={() => this.onScenarioChange()}
              onClick={() => this.onScenarioClicked()}
            >
              {dropdownScenarios.map(item => (
                <option key={item.name} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>

          <div className="select-by-day">
            <div className="day-text">Show transactions within range: </div>
            <div className="input-day-picker">
              <DayPickerInput
                value={from}
                placeholder="From"
                format="LL"
                formatDate={formatDate}
                parseDate={parseDate}
                dayPickerProps={{
                  selectedDays: [from, { from, to }],
                  disabledDays: { after: to },
                  toMonth: to,
                  modifiers,
                  numberOfMonths: 2,
                  onDayClick: () => this.to.getInput().focus()
                }}
                onDayChange={this.handleFromChange}
              />{' '}
              -{' '}
              <span className="input-to">
                <DayPickerInput
                  ref={el => (this.to = el)}
                  value={to}
                  placeholder="To"
                  format="LL"
                  formatDate={formatDate}
                  parseDate={parseDate}
                  dayPickerProps={{
                    selectedDays: [from, { from, to }],
                    disabledDays: { before: from },
                    modifiers,
                    month: from,
                    fromMonth: from,
                    numberOfMonths: 2
                  }}
                  onDayChange={this.handleToChange}
                />
              </span>
            </div>
          </div>
          <div className="clearfix" />

          <div id="transaction_handler">
            <TransData
              addNewTransaction={this.addNewTransaction}
              searchActive={this.state.searchActive}
              guidedTour={guidedTour}
              endTour={endTour}
              transactions={displayTransactions}
              rowInConcern={this.rowInConcern}
              loadingTransactions={loadingTransactions}
            />
          </div>
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  ui: state.ui
})

export default connect(mapStateToProps, {
  deleteTransaction,
  endTour,
  continueGuidedTour,
  addSubTransaction,
  openTransactionForm,
  clearTransactionForm,
  searchTransaction,
  getTransactions,
  showWarningToaster
})(Transactions)
