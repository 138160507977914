import React from 'react'

const Snackbar = props => {
  return (
    <div
      id="snackBar"
      style={{
        visibility: props.show ? 'visible' : 'hidden'
      }}
    >
      {props.message}
    </div>
  )
}

export default Snackbar
