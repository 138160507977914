import React from "react";

const addCategory = props => {
	return (
		<div id="modalAddCategory">
			<div> Enter a name for the new category:</div>
			<input
				className="input-category"
				id="category_name"
				type="text"
				name="categoryName"
				maxLength="30"
				autoFocus
			/>
			<div className="mac-button">
				<button className="mac-cancel" onClick={() => props.addCategoryCancel()}>
					Cancel
				</button>
				<button
					className="mac-confirm"
					onClick={() => props.addCategoryConfirm(document.getElementById("category_name").value)}
				>
					Confirm
				</button>
			</div>
			<button className="mac-reorder" onClick={() => props.reorderCategoryHandler()}>
				Reorder Categories
			</button>
		</div>
	);
};

export default addCategory;
