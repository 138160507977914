import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PerfectScrollbar from 'react-perfect-scrollbar'
import Tour from 'reactour'
import Modal from 'react-responsive-modal'
import axios from 'axios'

import {
  addScenario,
  editScenario,
  deleteScenario,
  continueGuidedTour,
  showErrorToaster
} from '../../store/actions'
import MenuDivider from '../common/MenuDivider'
import {
  _getCurrency,
  connectingBaseLink,
  getActiveUser
} from '../../helpers/constants'

import 'react-perfect-scrollbar/dist/css/styles.css'
import AddScenario from './AddScenario'
import ScenarioDataPopUp from './ScenarioDataPopUp'
import EnterConfirmationDialog from '../dialogs/EnterConfirmationDialog'
import Snackbar from '../common/Snackbar'

let initialRadioSet = false

const closeBtnStyle = {
  width: '90%',
  marginLeft: '5%',
  marginTop: '2rem',
  padding: '1rem',
  backgroundColor: '#cbd1d8',
  color: '#000',
  border: 'none',
  outline: 'none',
  cursor: 'pointer'
}

class Scenarios extends Component {
  constructor() {
    super()
    this.state = {
      openingDate: new Date(),
      run: false,
      addScenario: false,
      editingScenario: false,
      showDeleteConfirmation: false,
      showMaxLimit: false,
      selectedRadioScenario: '',
      steps: [
        {
          selector: '#scenario_main',
          content:
            "Punch in today's opening bank balances for correct future balance.",
          position: 'bottom'
        },
        {
          selector: '#add_transaction',
          content:
            'Add transactions (for any scenarios selected in the menu bar)',
          position: 'bottom'
        },
        {
          selector: '#add_transaction',
          content:
            'Add transactions (for any scenarios selected in the menu bar)',
          position: 'bottom'
        }
      ]
    }
    this.handleDateChange = this.handleDateChange.bind(this)
  }

  handleDateChange = date => {
    this.setState({ openingDate: date })
  }

  componentDidMount() {
    const { scenarios } = this.props
    if (!initialRadioSet) {
      let initialRadioScenario = ''
      for (let i = 0; i < scenarios.length; i++) {
        if (scenarios[i].status === '1') {
          initialRadioScenario = scenarios[i]
          break
        }
      }
      initialRadioSet = true
      this.setState({
        selectedRadioScenario: initialRadioScenario
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.ui.guidedTour.status &&
      nextProps.ui.guidedTour.element === 2
    ) {
      this.setState({ run: true })
    }
  }

  onModifyEntryHandler = () => {
    let scenario = ''
    for (let i = 0, len = this.props.scenarios.length; i < len; i++) {
      let temp = document.getElementById(`${this.props.scenarios[i].name}` + 1)
      if (temp.checked) {
        scenario = temp.value
      }
    }
    if (scenario === '') {
      scenario = this.props.currentScenario[0]
      let newTemp = scenario + 1
      document.getElementById(newTemp).checked = true
    }
    this.editingScenarioHandler(scenario)
  }

  /*************************  React Tour */
  closeTour = () => {
    this.setState({ run: false })
    this.props.continueGuidedTour(6)
  }

  getCurrentStep = data => {
    if (data === 2) {
      this.setState({ run: false })
      this.props.continueGuidedTour(3)
    }
  }

  /*************** ADDING NEW SCENARIO RELATED *************/
  addScenarioHandler = () => {
    if (this.props.scenarios.length < 20) {
      this.setState({
        addScenario: true
      })
    } else {
      this.setState({ showMaxLimit: true })
      setTimeout(() => this.setState({ showMaxLimit: false }), 2000)
    }
  }

  addScenarioCancel = () => {
    this.setState({
      addScenario: false
    })
  }

  addScenarioDone = (scenarioName, scenarioValue) => {
    this.props.addScenario(scenarioName, scenarioValue)
    this.setState({ addScenario: false })
  }
  /*******************************************************/

  /**************** EDITING AND DELETING SCENARIOS *****************/
  editingScenarioHandler = value => {
    let selectedScenario = ''
    for (let i = 0, len = this.props.scenarios.length; i < len; i++) {
      if (value === this.props.scenarios[i].name) {
        selectedScenario = this.props.scenarios[i]
        break
      }
    }
    if (selectedScenario !== '') {
      this.setState({
        selectedRadioScenario: selectedScenario,
        editingScenario: true
      })
    } else {
      this.setState({ showSnackbar: true })
      setTimeout(() => this.setState({ showSnackbar: false }), 2000)
    }
  }

  editingScenarioConfirmHandler = (value, name) => {
    let editedScenario = this.state.selectedRadioScenario.name
    if (typeof name === 'undefined') {
      name = editedScenario
    }
    this.props.editScenario(editedScenario, name, value)
    this.setState({ editingScenario: false })
  }

  editingScenarioCancelHandler = () => {
    this.setState({ editingScenario: false })
  }

  deleteScenarioHandler = () => {
    axios
      .post(`${connectingBaseLink}scenarios/checkBeforeDelete.php`, {
        user: getActiveUser(),
        scenario: this.state.selectedRadioScenario.name
      })
      .then(res => {
        let response = res.data.message
        if (response === 'transactions') {
          this.setState({
            showDeleteConfirmation: true,
            enterDeleteMessage: 'This scenario consists of active transactions.'
          })
        } else {
          this.setState({
            showDeleteConfirmation: true,
            enterDeleteMessage: 'This scenario has no data associated with it.'
          })
        }
      })
      .catch(err => this.props.showErrorToaster('Some error occurred'))
  }

  onDeleteScenarioConfirm = () => {
    let onlyActive = false
    let activeScenarios = this.props.scenarios.filter(
      item => item.status === '1'
    )
    if (
      activeScenarios.length === 1 &&
      this.state.selectedRadioScenario.status === '1'
    )
      onlyActive = true

    let scenarioName = this.state.selectedRadioScenario.name

    this.props.deleteScenario(scenarioName, onlyActive)
    this.setState({
      showDeleteConfirmation: false,
      editingScenario: false,
      enterDeleteMessage: ''
    })
    if (onlyActive) {
      setTimeout(() => {
        window.location.reload()
      }, 500)
    }
  }

  onDeleteScenarioCancel = () => {
    this.setState({ showDeleteConfirmation: false })
  }

  render() {
    const {
      steps,
      run,
      selectedRadioScenario,
      editingScenario,
      enterDeleteMessage
    } = this.state
    let scenarios = this.props.scenarios

    return (
      <div id="scenarioCard" style={{ height: '40rem' }}>
        <Modal
          open={this.state.addScenario}
          onClose={this.addScenarioCancel}
          center
          classNames={{ modal: 'modal-add-scenario' }}
        >
          <AddScenario
            addScenarioDone={this.addScenarioDone}
            addScenarioCancel={this.addScenarioCancel}
          />
        </Modal>

        <Modal
          open={editingScenario}
          onClose={this.editingScenarioCancelHandler}
          center
          classNames={{ modal: 'modal-scenario-data-popup' }}
        >
          <ScenarioDataPopUp
            scenarios={selectedRadioScenario}
            deleteScenarioHandler={this.deleteScenarioHandler}
            editingCancelled={this.editingScenarioCancelHandler}
            editingConfirmed={this.editingScenarioConfirmHandler}
          />
        </Modal>

        <Modal
          open={this.state.showDeleteConfirmation}
          onClose={this.onDeleteScenarioCancel}
          center
          classNames={{ modal: 'modal-confirmation-scenarios' }}
        >
          <EnterConfirmationDialog
            message={enterDeleteMessage}
            scenarioName={selectedRadioScenario.name}
            dialogPositive={'Confirm'}
            dialogNegative={'Cancel'}
            onDeleteConfirmClicked={this.onDeleteScenarioConfirm}
            onDeleteCancelClicked={this.onDeleteScenarioCancel}
          />
        </Modal>

        <Tour
          steps={steps}
          isOpen={run}
          showNavigationNumber={false}
          showNumber={false}
          showNavigation={false}
          prevButton={<div style={{ display: 'none' }} />}
          nextButton={<div className={'tour-next-button'}>Next</div>}
          onRequestClose={this.closeTour}
          accentColor="#365478"
          getCurrentStep={this.getCurrentStep}
        />

        <div className="sc-title">
          <h3>Scenarios</h3>
          <div className="clearfix" />
          <div
            className="sc-add-scenario"
            onClick={() => this.addScenarioHandler()}
          >
            <i className="fas fa-plus" />
          </div>
        </div>
        <div style={{ height: '20rem' }}>
          <PerfectScrollbar>
            {scenarios.map(item => (
              <div
                id={
                  item.name === 'Main Account'
                    ? 'scenario_main'
                    : 'scenario_other'
                }
                key={item.name}
                className="sc-scenario-wrap"
              >
                {item.name === this.props.currentScenario[0] ? (
                  <input
                    type="radio"
                    id={item.name + 1}
                    name="scenario_option"
                    value={item.name}
                    defaultChecked
                  />
                ) : (
                  <input
                    type="radio"
                    id={item.name + 1}
                    name="scenario_option"
                    value={item.name}
                  />
                )}
                <div style={{ width: '40%' }}>{item.name}</div>
                <div
                  style={{ width: '40%', textAlign: 'right' }}
                >{`${_getCurrency} ${item.opening}`}</div>
              </div>
            ))}
          </PerfectScrollbar>
        </div>

        <MenuDivider />

        <div className="sc-button-wrap">
          <Link to="/transactions" id="add_transaction" className="sc-add">
            Add Transaction
          </Link>
          <button
            className="sc-modify"
            onClick={() => this.onModifyEntryHandler()}
          >
            Modify Entry
          </button>
        </div>
        <button
          className="sc-modify"
          style={closeBtnStyle}
          onClick={() => this.props.closeScenarioHandler()}
        >
          Close Window
        </button>
        {this.state.showMaxLimit && (
          <Snackbar
            message={'A maximum of 20 scenarios are allowed'}
            show={this.state.showMaxLimit}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ui: state.ui
})

export default connect(mapStateToProps, {
  addScenario,
  editScenario,
  deleteScenario,
  continueGuidedTour,
  showErrorToaster
})(Scenarios)
