import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./reducers";
import reduxThunk from "redux-thunk";

const middleware = [reduxThunk];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));

export default store;
