import React, { Component } from "react";
import MenuDivider from "../common/MenuDivider";

class DateReminder extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showDateSelector: false
		};
		this.optionChanged = this.optionChanged.bind(this);
		this.onConfirmClicked = this.onConfirmClicked.bind(this);
	}

	optionChanged(status) {
		this.setState({ showDateSelector: status });
	}

	onConfirmClicked() {
		let noteText = document.getElementById("reminder_text").value;
		if (this.state.showDateSelector) {
			let date = document.getElementById("reminder_date").value;
			this.props.dateReminderConfirm(date, noteText);
		} else {
			this.props.dateReminderConfirm("calculate", noteText);
		}
	}

	render() {
		return (
			<div id="dateReminder">
				<div className="popup-title">Set Reminder Date</div>
				<label className={"text-area"}>
					<span>Notes:</span>
					<input type="text" id="reminder_text" name="reminderText" defaultValue={this.props.reminderNote} />
				</label>
				<MenuDivider />
				<div className={"dm-radio"}>
					<label className={"dm-radio-label"} onChange={() => this.optionChanged(false)}>
						<input type="radio" id="radio_date" name="optionsRadios" defaultChecked />
						<span className={"dm-radio-button"} />
						Remind me on the date of transaction
					</label>
				</div>
				<div className={"dm-radio"}>
					<label className={"dm-radio-label"} onChange={() => this.optionChanged(true)}>
						<input type="radio" id="radio_before" name="optionsRadios" />
						<span className={"dm-radio-button"} />
						Remind me on a specific date
					</label>
				</div>
				{this.state.showDateSelector ? (
					<div className={"popup-body"}>
						<div className={"text-only"}>Select date:</div>
						<input
							type="date"
							name="reminder_date"
							min={this.props.minDate}
							id="reminder_date"
							defaultValue={this.props.minDate}
							required
						/>
					</div>
				) : null}
				<MenuDivider />
				<div className={"dm-button"}>
					<button className={"dm-cancel"} onClick={() => this.props.dateReminderCancel()}>
						Cancel
					</button>
					<button className={"dm-confirm"} onClick={this.onConfirmClicked}>
						Confirm
					</button>
				</div>
			</div>
		);
	}
}

export default DateReminder;
