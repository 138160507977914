import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import DayPicker from "react-day-picker";
import Modal from "react-responsive-modal";
import styled from "styled-components";
import moment from "moment";

import { addTransaction, editTransaction } from "../../store/actions";
import { frequencyOptions, customTypeOptions, integerOptions } from "../../helpers/data";
import { _expandTransactions, getCategories, _getTodayDate } from "../../helpers/constants";
import DateReminder from "../modals/DateReminder";
import FormSelector from "../common/FormSelector";
import isEmpty from "../../validation/is-empty";

const initialValues = {
  scenarioValue: "",
  categoryValue: "",
  descValue: "",
  amountValue: "",
  dateValue: new Date(),
  displayDateValue: moment().format("YYYY-MM-DD"),
  transactionType: "T",
  typeId: 0,
  fId: "f0",
  fValue: "One Time",
  eTId: 1,
  eTDateValue: new Date(),
  eTDisplayDateValue: moment().format("YYYY-MM-DD"),
  eTPaymentValue: 1,
  millisValue: 0,
  reminderDate: null,
  reminderNote: "",
  xTransValue: null
};

let preValues = Object.assign({}, initialValues);

// let editingCalled = false;
let setMoreTimeState = false;
let setDisableCustom = true;
let formMode = "add";

const StyledDatePicker = styled(DayPicker)`
  .DayPicker-wrapper {
    z-index: 1;
    background: white;
  }

  .DayPicker-Footer {
    text-align: center;
  }
`;

class AddForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amountIcon: true,
      moreTime: false,
      disableCustom: true,
      inputDescription: "",
      inputAmount: "",
      inputPayment: "1",
      selectedRadioOption: "1",
      showDayPicker: false,
      showEndDayPicker: false,
      showFormSelector: false,
      selectedMirroredScenario: "",
      addDisplayedSDate: moment().format("YYYY-MM-DD"),
      addDisplayedEndDate: moment().format("YYYY-MM-DD"),
      addSelectedDate: new Date(),
      addEndSeletedDate: new Date(),
      openReminder: false,
      reminderDate: null,
      reminderNote: "",
      transferScenarios: this.props.scenarios.filter(item => item.name !== this.props.currentScenario[0])
    };

    this.headerRef = React.createRef();
    this.setReminder = this.setReminder.bind(this);
  }

  componentDidMount() {
    const { mode, data } = this.props.misc.formValue;
    formMode = mode;

    if (data || mode === "edit" || mode === "view") {
      this.setEditingDataAfterRender(data);
    }
  }

  componentWillUnmount() {
    this.resetValues();
  }

  resetValues = () => {
    setMoreTimeState = false;
    setDisableCustom = true;
    preValues = Object.assign({}, initialValues);
  };

  renderFormButtons() {
    if (formMode === "view") {
      return (
        <Fragment>
          <div className="col-button" style={{ paddingTop: "1rem" }}>
            Edit Invoice data List from Category Table at Dashboard page
          </div>
          <div className="col-button">
            <input type="button" className="af-button af-button-success" onClick={() => this.props.newTransactionCancelled()} value="OK" />
          </div>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <div className="col-button">
          <input type="button" className="af-button af-button-cancel" onClick={() => this.props.newTransactionCancelled()} value="Cancel" />
        </div>
        <div className="col-button">
          <input type="submit" className="af-button af-button-success" value="Save" />
        </div>
      </Fragment>
    );
  }

  setEditingDataAfterRender = editingData => {
    if (formMode === "edit") this.headerRef.current.focus();
    preValues.scenarioValue = editingData.scenario;
    preValues.categoryValue = editingData.category;
    preValues.descValue = editingData.description;

    if (editingData.amount) preValues.amountValue = Math.abs(editingData.amount);

    if (editingData.start_date) {
      preValues.displayDateValue = editingData.start_date;
      preValues.dateValue = moment(editingData.start_date, "YYYY-MM-DD").toDate();
    }

    preValues.transactionType = editingData.transactionType;
    preValues.typeId = editingData.typeId;

    preValues.fId = editingData.frequency_id;
    preValues.fValue = editingData.frequency_type;
    preValues.eTId = editingData.end_type_id;

    let scenario = document.getElementById("selectScen");
    for (let i = 0; i < scenario.options.length; i++) {
      if (scenario.options[i].text === preValues.scenarioValue) {
        scenario.selectedIndex = i;
        break;
      }
    }

    let category = document.getElementById("selectCate");
    for (let i = 0; i < category.options.length; i++) {
      if (category.options[i].text === preValues.categoryValue) {
        category.selectedIndex = i;
        break;
      }
    }

    let frequencyId = document.getElementById("selectFreq");
    frequencyId.value = preValues.fId;
    if (preValues.fId !== "f0") {
      setMoreTimeState = true;
    } else {
      setMoreTimeState = false;
    }

    if (preValues.fId === "f8") {
      setDisableCustom = false;
      let freqValue = preValues.fValue;
      let cNumberId = document.getElementById("selectCustomNumber");
      let cNumberType = document.getElementById("selectCustomType");
      cNumberId.value = freqValue.substring(1);
      cNumberType.value = freqValue.charAt(0);
    } else {
      setDisableCustom = true;
    }

    if (preValues.eTId === "1") {
      preValues.eTDisplayDateValue = editingData.end_type_value;
      preValues.eTDateValue = moment(preValues.eTDisplayDateValue, "YYYY-MM-DD").toDate();
      document.getElementById("radio_date").checked = true;
    } else if (preValues.eTId === "2") {
      preValues.eTPaymentValue = editingData.end_type_value;
      document.getElementById("radio_times").checked = true;
    } else if (preValues.eTId === "3") {
      document.getElementById("radio_continue").checked = true;
    }

    preValues.millisValue = editingData.millis;
    if (editingData.reminder_date !== "No") preValues.reminderDate = editingData.reminder_date;
    preValues.reminderNote = editingData.reminder_note;
    preValues.xTransValue = editingData.xtrans;

    this.setState({
      moreTime: setMoreTimeState,
      disableCustom: setDisableCustom,
      selectedRadioOption: preValues.eTId,
      inputDescription: preValues.descValue,
      inputAmount: preValues.amountValue,
      inputPayment: preValues.eTPaymentValue,
      addDisplayedSDate: preValues.displayDateValue,
      addDisplayedEndDate: preValues.eTDisplayDateValue,
      addSelectedDate: preValues.dateValue,
      addEndSeletedDate: preValues.eTDateValue,
      reminderDate: preValues.reminderDate,
      reminderNote: preValues.reminderNote,
      amountIcon: editingData.amount > 0 ? true : false
    });
  };

  onScenarioChanged = event => {
    let tempScenarios = this.props.scenarios.map(item => item).filter(item => item.name !== event.target.value);
    this.setState({ transferScenarios: tempScenarios });
  };

  onFrequencyChange = event => {
    if (event.target.value !== "f0") {
      if (event.target.value === "f8") {
        this.setState({ disableCustom: false, moreTime: true });
      } else {
        if (this.state.disableCustom === false) {
          this.setState({ disableCustom: true, moreTime: true });
        } else {
          this.setState({ moreTime: true });
        }
      }
    } else if (this.state.moreTime === true) {
      this.setState({ moreTime: false });
    }
  };

  radioOptionChangedHandler = value => {
    if (value === "radio_date") {
      this.setState({ selectedRadioOption: "1" });
    } else if (value === "radio_times") {
      this.setState({ selectedRadioOption: "2" });
    } else if (value === "radio_continue") {
      this.setState({ selectedRadioOption: "3" });
    }
  };

  toggleDayPicker = () => {
    let newDayPickerState = !this.state.showDayPicker;
    this.setState({ showDayPicker: newDayPickerState });
  };

  toggleEndDayPicker = () => {
    let newEndDayPickerState = !this.state.showEndDayPicker;
    this.setState({ showEndDayPicker: newEndDayPickerState });
  };

  handleDayChange = day => {
    let savedDayFormatDate = moment(day).format("YYYY-MM-DD");
    this.setState({
      addDisplayedSDate: savedDayFormatDate,
      addSelectedDate: day,
      showDayPicker: false
    });
  };

  handleEndDayChange = date => {
    let newDate = moment(date).format("YYYY-MM-DD");
    this.setState({
      addDisplayedEndDate: newDate,
      addEndSeletedDate: date,
      showEndDayPicker: false
    });
  };

  onSaveDataClicked = event => {
    event.preventDefault();
    let formCompleted = true;

    let oldScenarioId = this.props.scenarios.filter(item => item.name === preValues.scenarioValue).map(item => item.id);
    oldScenarioId = oldScenarioId[0];

    let sId = document.getElementById("selectScen");
    let scenario = sId.options[sId.selectedIndex].text;
    let scenarioId = this.props.scenarios.filter(item => item.name === scenario).map(item => item.id);
    scenarioId = scenarioId[0];

    let cId = document.getElementById("selectCate");
    let category = cId.options[cId.selectedIndex].text;
    let categoryArray = getCategories();
    let categoryId = categoryArray.filter(item => item.category_name === category);
    categoryId = categoryId[0].category_id;

    let description = document.getElementById("inputDesc").value;
    if (preValues.transactionType === "X" && preValues.typeId !== "0") description = preValues.descValue;

    let date = preValues.displayDateValue;

    let amount = document.getElementById("inputAmount").value;
    if (!this.state.amountIcon) {
      amount = -1 * amount;
    }
    let balance = amount;

    let fId = document.getElementById("selectFreq");
    let frequencyId = fId.options[fId.selectedIndex].value;
    let frequencyType = fId.options[fId.selectedIndex].text;

    let endTypeValue = "No";
    let endTypeId = preValues.eTId;

    if (frequencyId !== "f0" && endTypeId === "0") {
      formCompleted = false;
    }

    if (frequencyId === "f8") {
      let customNumber = document.getElementById("selectCustomNumber").value;
      let customType = document.getElementById("selectCustomType").value;

      frequencyType = customType + customNumber;
    }

    if (endTypeId === "1") {
      endTypeValue = preValues.eTDisplayDateValue;
    } else if (endTypeId === "2") {
      endTypeValue = document.getElementById("inputETPayment").value;
      if (endTypeValue === "") {
        formCompleted = false;
      }
    } else if (endTypeId === "3") {
      endTypeValue = "Never";
    }

    let d = new Date();
    let millis = d.getTime();

    let setReminder = document.getElementById("reminder").checked;
    let reminder = "No";
    if (setReminder && this.state.reminderDate !== null) {
      reminder = this.state.reminderDate;
      // 	if (this.state.reminderDate === "calculate") {
      // 		if (date > this.props.openingDate) {
      // 			reminder = date;
      // 		} else {
      // 			let momentAdder = "";
      // 			switch (frequencyId) {
      // 				case "f0":
      // 					momentAdder = date;
      // 					break;
      // 				case "f1":
      // 					momentAdder = moment(date, "YYYY-MM-DD").add("1", "d");
      // 					break;
      // 				case "f2":
      // 					momentAdder = moment(date, "YYYY-MM-DD").add("7", "d");
      // 					break;
      // 				case "f3":
      // 					momentAdder = moment(date, "YYYY-MM-DD").add("14", "d");
      // 					break;
      // 				case "f4":
      // 					momentAdder = moment(date, "YYYY-MM-DD").add("1", "M");
      // 					break;
      // 				case "f5":
      // 					momentAdder = moment(date, "YYYY-MM-DD").add("3", "M");
      // 					break;
      // 				case "f6":
      // 					momentAdder = moment(date, "YYYY-MM-DD").add("6", "M");
      // 					break;
      // 				case "f7":
      // 					momentAdder = moment(date, "YYYY-MM-DD").add("1", "Y");
      // 					break;
      // 				case "f8":
      // 					let gap = Number(frequencyType.substring(1));
      // 					let firstCharacter = frequencyType.charAt(0);
      // 					if (firstCharacter === "D") momentAdder = moment(date, "YYYY-MM-DD").add(gap, "d");
      // 					else if (firstCharacter === "M") momentAdder = moment(date, "YYYY-MM-DD").add(gap, "M");
      // 					else if (firstCharacter === "Y") momentAdder = moment(date, "YYYY-MM-DD").add(gap, "Y");
      // 					break;
      // 				default:
      // 					momentAdder = date;
      // 			}
      // 			reminder = moment(momentAdder).format("YYYY-MM-DD");
      // 		}
      // 	} else {
      // 		reminder = this.state.reminderDate;
      // 	}
    }

    let reminder_note = "";
    if (reminder !== "No") reminder_note = this.state.reminderNote;

    let typeOfTransaction = preValues.transactionType;
    if (typeOfTransaction !== "S" && typeOfTransaction !== "X") typeOfTransaction = "T";

    let selectedMirroredScenario = this.state.selectedMirroredScenario;
    let makeCopy = document.getElementById("mirror").checked;
    if (makeCopy) {
      if (isEmpty(selectedMirroredScenario)) {
        selectedMirroredScenario = this.state.transferScenarios[0].id;
      }
    }

    if (formCompleted) {
      if (formMode === "edit") {
        let allTransactions = _expandTransactions(
          scenarioId,
          category,
          categoryId,
          preValues.transactionType,
          preValues.typeId,
          description,
          date,
          amount,
          balance,
          frequencyId,
          frequencyType,
          endTypeId,
          endTypeValue,
          millis,
          reminder,
          reminder_note,
          preValues.xTransValue
        );

        this.props.editTransaction(allTransactions, preValues.millisValue, this.props.scenarioIds, oldScenarioId);

        if (makeCopy && !isEmpty(selectedMirroredScenario) && selectedMirroredScenario !== scenarioId) {
          let mirrorTransactions = _expandTransactions(
            selectedMirroredScenario,
            category,
            categoryId,
            preValues.transactionType,
            preValues.typeId,
            description,
            date,
            amount * -1,
            balance,
            frequencyId,
            frequencyType,
            endTypeId,
            endTypeValue,
            millis + 1,
            reminder,
            reminder_note,
            preValues.xTransValue
          );
          this.props.addTransaction(mirrorTransactions, this.props.scenarioIds);
        }
        this.props.newTransactionEdited();
      } else if (formMode === "add") {
        let allTransactions = _expandTransactions(
          scenarioId,
          category,
          categoryId,
          typeOfTransaction,
          preValues.typeId,
          description,
          date,
          amount,
          balance,
          frequencyId,
          frequencyType,
          endTypeId,
          endTypeValue,
          millis,
          reminder,
          reminder_note,
          preValues.xTransValue
        );

        this.props.addTransaction(allTransactions, this.props.scenarioIds);

        if (makeCopy && !isEmpty(selectedMirroredScenario) && selectedMirroredScenario !== scenarioId) {
          let mirrorTransactions = _expandTransactions(
            selectedMirroredScenario,
            category,
            categoryId,
            preValues.transactionType,
            preValues.typeId,
            description,
            date,
            amount * -1,
            balance,
            frequencyId,
            frequencyType,
            endTypeId,
            endTypeValue,
            millis + 1,
            reminder,
            reminder_note,
            preValues.xTransValue
          );
          this.props.addTransaction(mirrorTransactions, this.props.scenarioIds);
        }

        this.props.newTransactionCancelled();
      }
    }
  };

  changeAmountIcon = () => {
    let tempIcon = this.state.amountIcon;
    this.setState({ amountIcon: !tempIcon });
  };

  /********* BACK UP TARNSACTION *******/
  mirrorTransaction = () => {
    let showFormSelector = !this.state.showFormSelector;
    if (!showFormSelector) {
      this.setState({ showFormSelector, selectedMirroredScenario: "" });
    } else {
      this.setState({ showFormSelector });
    }
  };

  // onMirrorTransactionCancelled = () => {
  // 	this.setState({ showFormSelector: false });
  // };

  onMirrorTransactionSelected = event => {
    this.setState({ selectedMirroredScenario: event.target.value });
  };

  /*********** SET REMINDER *********/
  setReminder() {
    let checkStatus = document.getElementById("reminder").checked;
    if (checkStatus) {
      this.setState({ openReminder: true });
    } else {
      this.setState({ openReminder: false });
    }
  }

  dateReminderConfirm = (value, noteText) => {
    this.setState({
      reminderDate: value,
      reminderNote: noteText,
      openReminder: false
    });
  };

  dateReminderCancel = () => {
    document.getElementById("reminder").checked = false;
    this.setState({ openReminder: false });
  };

  onAmountEntered = e => {
    let pattern = /^\d*(\.\d{1,2})?$/;
    let previousValue = this.state.inputAmount;
    let inputAmount = e.target.value;

    let characterPressed = inputAmount.substr(-1) === ".";

    if (characterPressed) {
      if (previousValue.indexOf(".") === -1) {
        this.setState({ inputAmount });
      }
    }

    if (inputAmount.match(pattern)) {
      this.setState({ inputAmount });
    }
  };

  render() {
    const {
      moreTime,
      disableCustom,
      inputDescription,
      inputAmount,
      inputPayment,
      selectedRadioOption,
      showDayPicker,
      showEndDayPicker,
      addDisplayedSDate,
      addDisplayedEndDate,
      addSelectedDate,
      addEndSeletedDate,
      transferScenarios
    } = this.state;

    const { categoryNames } = this.props.user;
    let categoryData = [];
    for (let i = 0; i < categoryNames.length; i++) {
      categoryData.push({
        id: `c${i}`,
        name: categoryNames[i]
      });
    }

    preValues.displayDateValue = addDisplayedSDate;
    preValues.dateValue = addSelectedDate;
    preValues.eTDisplayDateValue = addDisplayedEndDate;
    preValues.eTDateValue = addEndSeletedDate;
    preValues.eTId = selectedRadioOption;
    preValues.descValue = inputDescription;
    preValues.amountValue = inputAmount;
    preValues.eTPaymentValue = inputPayment;

    let baseClass = "form-group";
    let invoiceDisabledClass = ["form-group", "disable-invoice-group"].join(" ");

    let customRadioGroupClass = ["radio-group", "disable-group"].join(" ");
    if (moreTime) {
      customRadioGroupClass = "radio-group";
    }

    let customDurationClass = "form-group";
    if (disableCustom) {
      customDurationClass = ["form-group", "disable-group"].join(" ");
    }

    let customEADateClass = "end-after-date";
    let customEAPaymentClass = ["input-payment", "disable-group"].join(" ");

    if (selectedRadioOption === "1") {
      customEADateClass = "end-after-date";
      customEAPaymentClass = ["input-payment", "disable-group"].join(" ");
    }
    if (selectedRadioOption === "2") {
      customEAPaymentClass = "input-payment";
      customEADateClass = ["end-after-date", "disable-group"].join(" ");
    }
    if (selectedRadioOption === "3") {
      customEADateClass = ["end-after-date", "disable-group"].join(" ");
      customEAPaymentClass = ["input-payment", "disable-group"].join(" ");
    }

    let formGroupClass = baseClass;
    if (formMode === "view") {
      formGroupClass = invoiceDisabledClass;
    }

    return (
      <div id="addForm" style={{ marginBottom: "2rem" }}>
        <Modal open={this.state.openReminder} classNames={{ modal: "modal-date-reminder" }} center onClose={this.dateReminderCancel}>
          <DateReminder
            minDate={_getTodayDate()}
            reminderNote={preValues.reminderNote}
            dateReminderCancel={this.dateReminderCancel}
            dateReminderConfirm={this.dateReminderConfirm}
          />
        </Modal>

        {/* <Modal
					open={this.state.showFormSelector}
					classNames={{ modal: "modal-date-reminder" }}
					center
					onClose={this.onMirrorTransactionCancelled}
				>
					<FormSelector
						options={this.props.scenarios}
						onMirrorTransactionSelected={this.onMirrorTransactionSelected}
						onMirrorTransactionCancelled={this.onMirrorTransactionCancelled}
					/>
				</Modal> */}

        <div className="af-title">
          <h3>{formMode === "view" ? "Invoice Transaction Details" : formMode === "edit" ? "Edit Transactions Details" : "Add New Transaction"}</h3>
          <div className="clearfix" />
        </div>
        <div className="af-content">
          <form onSubmit={this.onSaveDataClicked}>
            <div className={formMode === "view" ? invoiceDisabledClass : formGroupClass}>
              <label className={["col14", "form-label"].join(" ")}>Scenario</label>
              <div className={"col34"}>
                <select className="form-control" id="selectScen" onChange={event => this.onScenarioChanged(event)}>
                  {this.props.currentScenario.map((item, index) => (
                    <option key={`sc_${index}`} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className={formGroupClass}>
              <label className={["col14", "form-label"].join(" ")}>Select Category</label>
              <div className={"col34"}>
                <select className={"form-control"} tabIndex="-1" id="selectCate">
                  {categoryData.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className={formGroupClass}>
              <label className={["col14", "form-label"].join(" ")} htmlFor="description">
                Description *
              </label>
              <div className={"col34"}>
                <input
                  type="text"
                  id="inputDesc"
                  required
                  ref={this.headerRef}
                  className={"form-control"}
                  value={preValues.descValue}
                  onChange={e => this.setState({ inputDescription: e.target.value })}
                />
              </div>
            </div>

            <div className={formGroupClass}>
              <label className={["col14", "form-label"].join(" ")} htmlFor="amount">
                Amount *
              </label>
              <div className={"col34"}>
                <div className={"amount-icon"} onClick={() => this.changeAmountIcon()}>
                  {this.state.amountIcon ? <i className="fas fa-plus-circle" /> : <i className="fas fa-minus-circle" />}
                </div>
                <div style={{ width: "90%", float: "right" }}>
                  <input
                    type="text"
                    id="inputAmount"
                    required
                    value={preValues.amountValue}
                    className={"form-control"}
                    onChange={e => this.onAmountEntered(e)}
                  />
                </div>
              </div>
            </div>

            <div className={formGroupClass}>
              <label className={["col14", "form-label"].join(" ")}>Start Date</label>
              <div className={"col34"}>
                <div className={["form-control", "day-picker-wrap"].join(" ")} onClick={this.toggleDayPicker} id="divStartDate">
                  {preValues.displayDateValue}
                </div>
                {showDayPicker ? (
                  <div className={"day-picker-overlay"}>
                    <StyledDatePicker todayButton="Today's Date" onDayClick={this.handleDayChange} selectedDays={preValues.dateValue} />
                  </div>
                ) : null}
              </div>
            </div>

            <div className={formMode === "view" ? invoiceDisabledClass : formGroupClass}>
              <label className={["col14", "form-label"].join(" ")}>Select Frequency</label>
              <div className={"col34"}>
                <select className={"form-control"} tabIndex="-1" id="selectFreq" onChange={event => this.onFrequencyChange(event)}>
                  {frequencyOptions.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.freqOption}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className={customDurationClass}>
              <label className={["col13", "form-label"].join(" ")}>Custom Duration</label>
              <div className={["col13", "colm2"].join(" ")}>
                <select className={"form-control"} tabIndex="-1" id="selectCustomNumber">
                  {integerOptions.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.number}
                    </option>
                  ))}
                </select>
              </div>

              <div className={["col13", "colm2"].join(" ")}>
                <select className={"form-control"} tabIndex="-1" id="selectCustomType">
                  {customTypeOptions.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.typeOption}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className={customRadioGroupClass}>
              <div className={"colm05"} />
              <div className={"colm15"}>
                <label className={"form-label"} style={{ textAlign: "left" }}>
                  End After:
                </label>
                <div className={"radio"}>
                  <input
                    type="radio"
                    className={"radio-option"}
                    id="radio_date"
                    name="optionsRadios"
                    defaultChecked
                    onChange={() => this.radioOptionChangedHandler("radio_date")}
                  />
                  <label htmlFor="radio_date" className={"radio-label"}>
                    <span className={"radio-button"} />
                    Date
                  </label>
                </div>
                <div className={"radio"}>
                  <input
                    type="radio"
                    className={"radio-option"}
                    id="radio_times"
                    name="optionsRadios"
                    onChange={() => this.radioOptionChangedHandler("radio_times")}
                  />
                  <label htmlFor="radio_times" className={"radio-label"}>
                    <span className={"radio-button"} />
                    No. of Payments
                  </label>
                </div>
                <div className={"radio"}>
                  <input
                    type="radio"
                    className={"radio-option"}
                    id="radio_continue"
                    name="optionsRadios"
                    onChange={() => this.radioOptionChangedHandler("radio_continue")}
                  />
                  <label htmlFor="radio_continue" className={"radio-label"}>
                    <span className={"radio-button"} />
                    Never
                  </label>
                </div>
              </div>

              <div className={"colm35"}>
                <div className={customEADateClass}>
                  <div className={"end-day-picker-wrap"} onClick={this.toggleEndDayPicker} id="divEndDate">
                    {preValues.eTDisplayDateValue}
                  </div>
                  {showEndDayPicker ? (
                    <div className={"day-picker-overlay"}>
                      <StyledDatePicker onDayClick={this.handleEndDayChange} selectedDays={preValues.eTDateValue} />
                    </div>
                  ) : null}
                </div>
                <input
                  className={customEAPaymentClass}
                  type="number"
                  pattern="\d+(\.\d{1,2})?"
                  min="1"
                  value={preValues.eTPaymentValue}
                  id="inputETPayment"
                  onChange={e => this.setState({ inputPayment: e.target.value })}
                />
              </div>
            </div>

            <div className="row bottom-row">
              <div className="col-md-8">
                <label className="check-container">
                  Transfer this transaction to another account
                  <input
                    onClick={this.mirrorTransaction}
                    type="checkbox"
                    name="mirror"
                    value="Make a copy of this transaction on another account"
                    id="mirror"
                  />
                  <span className="check-mark" />
                </label>
                {this.state.showFormSelector && (
                  <div className="row">
                    <div
                      className="col-4"
                      style={{
                        paddingTop: ".5rem",
                        textAlign: "right",
                        color: "black"
                      }}
                    >
                      Select scenario
                    </div>
                    <div className="col-8">
                      <FormSelector options={transferScenarios} onMirrorTransactionSelected={this.onMirrorTransactionSelected} />
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-4">
                <label className="check-container">
                  Set Reminder
                  {preValues.reminderDate && preValues.reminderDate !== "No" ? (
                    <input onClick={this.setReminder} type="checkbox" name="reminder" value="Set reminder" id="reminder" defaultChecked />
                  ) : (
                    <input onClick={this.setReminder} type="checkbox" name="reminder" value="Set reminder" id="reminder" />
                  )}
                  <span className="check-mark" />
                </label>
              </div>
            </div>

            <div className="clearfix" />
            <div className="solid-line" />

            <div className={"form-group"} style={{ marginBottom: ".5rem" }}>
              {this.renderFormButtons()}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  misc: state.misc
});

export default connect(
  mapStateToProps,
  { addTransaction, editTransaction }
)(AddForm);
