import {
	TOGGLE_NAVIGATION,
	SET_WINDOW_DIMENSIONS,
	SHOW_SUCCESS_TOASTER,
	SHOW_ERROR_TOASTER,
	SHOW_WARNING_TOASTER,
	SET_DATA_LOADING,
	STOP_LOADING,
	CLEAR_TOASTERS,
	CONTINUE_GUIDED_TOUR,
	END_TOUR,
	START_GUIDED_TOUR
} from "./types";

export const setDataLoading = () => {
	return {
		type: SET_DATA_LOADING
	};
};

export const stopLoading = () => {
	return {
		type: STOP_LOADING
	};
};

export const toggleNavigation = value => {
	return {
		type: TOGGLE_NAVIGATION,
		payload: value
	};
};

export const setWindowDimensions = (curveHeight, barHeight) => dispatch => {
	setTimeout(() => {
		dispatch({
			type: SET_WINDOW_DIMENSIONS,
			payload: { curveHeight, barHeight }
		});
	}, 100);
};

export const showSuccessToaster = message => {
	return {
		type: SHOW_SUCCESS_TOASTER,
		payload: message
	};
};

export const showErrorToaster = message => {
	return {
		type: SHOW_ERROR_TOASTER,
		payload: message
	};
};

export const showWarningToaster = message => {
	return {
		type: SHOW_WARNING_TOASTER,
		payload: message
	};
};

export const clearToasters = () => {
	return {
		type: CLEAR_TOASTERS
	};
};

// React Tour
export const startGuidedTour = () => {
	return {
		type: START_GUIDED_TOUR
	};
};
export const continueGuidedTour = element => {
	return {
		type: CONTINUE_GUIDED_TOUR,
		payload: element
	};
};

export const endTour = () => {
	return {
		type: END_TOUR
	};
};
