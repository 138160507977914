export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const VALIDATE_USER = "VALIDATE_USER";
export const SET_DATA_LOADING = "SET_DATA_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const STOP_INITIAL_LOADER = "STOP_INITIAL_LOADER";

// Settings related
export const EDIT_SETTINGS = "EDIT_SETTINGS";
export const EDIT_OPENING = "EDIT_OPENING";

// User interface actions
export const TOGGLE_NAVIGATION = "TOGGLE_NAVIGATION";
export const SET_WINDOW_DIMENSIONS = "SET_WINDOW_DIMENSIONS";
export const SHOW_SUCCESS_TOASTER = "SHOW_SUCCESS_TOASTER";
export const SHOW_ERROR_TOASTER = "SHOW_ERROR_TOASTER";
export const SHOW_WARNING_TOASTER = "SHOW_WARNING_TOASTER";
export const CLEAR_TOASTERS = "CLEAR_TOASTERS";
export const START_GUIDED_TOUR = "START_GUIDED_TOUR";
export const CONTINUE_GUIDED_TOUR = "CONTINUE_GUIDED_TOUR";
export const END_TOUR = "END_TOUR";

// Scenario related actions
export const GET_SCENARIOS = "GET_SCENARIOS";
export const RESET_SCENARIOS = "RESET_SCENARIOS";
export const SET_ACTIVE_SCENARIOS = "SET_ACTIVE_SCENARIOS";
export const ADD_SCENARIO = "ADD_SCENARIO";
export const EDIT_SCENARIO = "EDIT_SCENARIO";
export const GET_REMINDERS = "GET_REMINDERS";

// Transaction related actions
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const ADD_TRANSACTION = "ADD_TRANSACTION";
export const EDIT_TRANSACTION = "EDIT_TRANSACTION";
export const OPEN_FORM = "OPEN_FORM";
export const CLEAR_FORM = "CLEAR_FORM";
export const DESC_TRANSACTIONS = "DESC_TRANSACTIONS";

// Category actions
export const GET_CATEGORY_DATA = "GET_CATEGORY_DATA";
export const ADD_CATEGORY = "ADD_CATEGORY";

// Logging in actions
export const JUST_LOGGED_IN = "JUST_LOGGED_IN";
