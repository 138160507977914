import React, { Component, Fragment } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'
import Tour from 'reactour'

import { _getCurrency, _getDecimal } from '../../helpers/constants'
import Loading from '../common/Loading'

let selectedRow = null
let selectedRowIndex = null
let lastStep = false

function priceFormatter(cell, row) {
  let tempValue = cell
  if (tempValue < 0) cell = -1 * cell
  let returnedValue = parseFloat(
    Math.round(cell + 'e' + _getDecimal()) + 'e-' + _getDecimal()
  ).toFixed(_getDecimal())
  let formattedValue = returnedValue
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  if (isNaN(returnedValue)) return <span />
  else if (tempValue < 0)
    return (
      <span>
        {_getCurrency} ({formattedValue})
      </span>
    )
  else
    return (
      <span>
        {_getCurrency} {formattedValue}
      </span>
    )
}

class TransData extends Component {
  state = {
    run: false,
    steps: [
      {
        selector: '#table_header',
        content:
          'Sort by column (or use search tool above). Select one scenario from toolbar to see one future bank statement.',
        position: 'top'
      },
      {
        content: (
          <div>
            Goto{' '}
            <a
              href="https://zentaflow.com.au"
              style={{
                textDecoration: 'none',
                color: '#000',
                padding: '0 2px'
              }}
            >
              www.Zentaflow.com.au
            </a>{' '}
            for support live chat or FAQ - and/or check out the instructional
            videos (see menu bar)
          </div>
        ),
        position: 'top'
      }
    ],
    finishTour: false,
    invoiceSelected: false
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.guidedTour.status && nextProps.guidedTour.element === 7) {
      this.setState({ run: true })
    }
    selectedRowIndex = null
  }

  componentWillUnmount() {
    selectedRow = null
    selectedRowIndex = null
    lastStep = false
  }

  renderPanelButtons() {
    let panelButtons = (
      <Fragment>
        <div className="td-buttons">
          <button
            className="td-edit"
            onClick={() => this.props.rowInConcern('edit', selectedRow)}
          >
            Edit Transaction
          </button>
          <button
            className="td-delete"
            onClick={() => this.props.rowInConcern('del', selectedRow)}
          >
            Delete Transaction
          </button>
        </div>
      </Fragment>
    )
    if (this.state.invoiceSelected) {
      panelButtons = (
        <div className="td-buttons">
          <button
            className="td-details"
            onClick={() => this.props.rowInConcern('view', selectedRow)}
          >
            View Invoice Details
          </button>
        </div>
      )
    }

    return panelButtons
  }

  closeTour = () => {
    this.setState({ run: false })
    this.props.endTour()
  }

  getCurrentStep = data => {
    if (data === 1 && !lastStep) {
      this.setState({ finishTour: true })
      lastStep = true
    }
    if (data === 2) {
      this.setState({ run: false })
      this.props.endTour()
    }
  }

  finishTour = () => {
    this.setState({ run: false })
    this.props.endTour()
  }

  render() {
    const { finishTour, run, steps } = this.state
    let bootstrapColumns = [
      {
        dataField: 'sNumber',
        text: 'SR',
        sort: true,
        headerClasses: 'header-serial'
      },
      {
        dataField: 'start_date',
        text: 'Date',
        sort: true,
        headerClasses: 'header-date'
      },
      {
        dataField: 'scenario',
        text: 'Scenario',
        sort: true,
        headerClasses: 'header-scenario'
      },
      {
        dataField: 'description',
        text: 'Description',
        sort: true,
        headerClasses: 'header-desc'
      },
      {
        dataField: 'category',
        text: 'Category',
        sort: true,
        headerClasses: 'header-category'
      },
      {
        dataField: 'amount',
        text: 'Amount',
        formatter: priceFormatter,
        sort: true,
        headerClasses: 'header-amount'
      },
      {
        dataField: 'balance',
        text: 'Balance',
        formatter: priceFormatter,
        sort: true,
        headerClasses: 'header-balance'
      }
    ]

    if (this.props.searchActive) {
      bootstrapColumns.pop()
    }

    return (
      <Fragment>
        {finishTour ? (
          <Tour
            steps={steps}
            isOpen={run}
            showNavigationNumber={false}
            showNumber={false}
            showNavigation={false}
            nextStep={this.finishTour}
            prevButton={<div style={{ display: 'none' }} />}
            nextButton={
              <div
                className="tour-next-button"
                onClick={() => this.finishTour()}
              >
                Ok
              </div>
            }
            onRequestClose={this.closeTour}
            accentColor="#365478"
            getCurrentStep={this.getCurrentStep}
          />
        ) : (
          <Tour
            steps={steps}
            isOpen={run}
            showNavigationNumber={false}
            showNumber={false}
            showNavigation={false}
            prevButton={<div style={{ display: 'none' }} />}
            nextButton={<div className="tour-next-button">Next</div>}
            onRequestClose={this.closeTour}
            accentColor="#365478"
            getCurrentStep={this.getCurrentStep}
          />
        )}

        <div id="transData">
          <div className="td-title row">
            <div className="col-12 col-md-4">
              <h3>Transaction Data &nbsp;&nbsp;</h3>
            </div>
            <div className="col-12 col-md-8">
              {this.renderPanelButtons()}
              <button
                className="td-add-transaction"
                onClick={() => this.props.addNewTransaction()}
              >
                <i className="fas fa-plus" />
                Add Transaction
              </button>
              <div className="clearfix" />
            </div>
          </div>
          <div className="td-content" id="table_header">
            {this.props.loadingTransactions ? (
              <Loading
                loaderId="widgetLoader"
                loaderType="spin"
                loaderColor="#111"
                loaderText=""
                widgetLoader={true}
              />
            ) : (
              <BootstrapTable
                keyField="sNumber"
                data={this.props.transactions}
                columns={bootstrapColumns}
                classes="td-table"
                wrapperClasses="td-table-wrap"
                filter={filterFactory()}
                bordered
                hover
                noDataIndication="No Data Yet... Start adding from 'Add Transaction' above"
                selectRow={{
                  mode: 'radio',
                  selected: selectedRowIndex !== null ? [selectedRowIndex] : '',
                  style: {
                    background: '#ececec'
                  },
                  clickToSelect: true,
                  hideSelectColumn: true,
                  onSelect: row => {
                    selectedRow = row
                    selectedRowIndex = row.sNumber
                    if (
                      selectedRow.transactionType === 'X' &&
                      selectedRow.typeId !== '0'
                    ) {
                      this.setState({ invoiceSelected: true })
                    } else {
                      if (this.state.invoiceSelected)
                        this.setState({ invoiceSelected: false })
                    }
                  }
                }}
                pagination={paginationFactory()}
              />
            )}
          </div>
        </div>
      </Fragment>
    )
  }
}

export default TransData
