import React from "react";

const InformationalDialog = props => {
	return (
		<div id="informationDialog">
			<div className="id-title">{props.dialogTitle}</div>
			<div className="id-text">{props.dialogText}</div>
			<button onClick={() => props.dialogClicked()} className="id-positive">
				{props.dialogPositive}
			</button>
		</div>
	);
};

export default InformationalDialog;
