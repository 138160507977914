import { connectingBaseLink, getActiveUser, _getOpeningDate } from "./constants";

export const categoryData = [
	{ id: "c0", name: "Income" },
	{ id: "c1", name: "Wages" },
	{ id: "c2", name: "ATO" },
	{ id: "c3", name: "AP suppliers" },
	{ id: "c4", name: "Consultants and advisors" },
	{ id: "c5", name: "Committed expenses" },
	{ id: "c6", name: "Creditcards and Reimb." },
	{ id: "c7", name: "Miscellaneous" },
	{ id: "c8", name: "Owners deposit/withdraw" },
	{ id: "c9", name: "Savings and Loans" }
];

export const homeCategoryData = [
	{ id: "hc0", name: "Income" },
	{ id: "hc1", name: "Living Expenses" },
	{ id: "hc2", name: "Transport" },
	{ id: "hc3", name: "Health & Sport" },
	{ id: "hc4", name: "Education" },
	{ id: "hc5", name: "Travel & Fun" },
	{ id: "hc6", name: "Business" },
	{ id: "hc7", name: "Miscellaneous" },
	{ id: "hc8", name: "Savings and Loans" }
];

export const frequencyOptions = [
	{ id: "f0", freqOption: "One Time" },
	{ id: "f1", freqOption: "Daily" },
	{ id: "f2", freqOption: "Weekly" },
	{ id: "f3", freqOption: "Fortnightly" },
	{ id: "f4", freqOption: "Monthly" },
	{ id: "f5", freqOption: "Quarterly" },
	{ id: "f6", freqOption: "Half Yearly" },
	{ id: "f7", freqOption: "Yearly" },
	{ id: "f8", freqOption: "Custom" }
];

export const customTypeOptions = [
	{ id: "D", typeOption: "Day(s)" },
	{ id: "M", typeOption: "Month(s)" },
	{ id: "Y", typeOption: "Year(s)" }
];

function integerValues() {
	const arr = [];
	for (let i = 1; i < 32; i++) {
		arr.push({
			id: i,
			number: i
		});
	}
	return arr;
}

export const integerOptions = integerValues();

export const getCAndTrans = async scenarioIds => {
	const response = await fetch(`${connectingBaseLink}categories/readCTrans.php`, {
		method: "post",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({
			user: getActiveUser(),
			opening: _getOpeningDate(),
			scenarios: scenarioIds
		})
	});
	return response.json();
};

export const getTransactionDataGroupedByDate = scenarioIds => {
	return fetch(`${connectingBaseLink}transactions/readGroupedTrans.php`, {
		method: "post",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({
			user: getActiveUser(),
			scenarioIds: scenarioIds
		})
	}).then(response => response.json());
};
