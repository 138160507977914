import React, { Component } from "react";

let numScenarios = 0;
class AddNewSub extends Component {
	onConfirmClicked = event => {
		event.preventDefault();

		let subName = document.getElementById("sub_name").value;
		let scenarioName = this.props.activeScenarios[0];
		if (numScenarios > 1) scenarioName = document.getElementById("scenario").value;
		this.props.addSubConfirm(subName, scenarioName);
	};

	render() {
		let activeScenarios = this.props.activeScenarios;
		numScenarios = activeScenarios.length;
		let title = null,
			scenarioRow = null;
		if (numScenarios === 1) title = "(" + activeScenarios[0] + ")";

		if (numScenarios > 1) {
			scenarioRow = (
				<div className={"ans-row"}>
					<div className={"ans-col12"}>
						<span className={"ans-read-only"}>Select Scenario:</span>
					</div>
					<div className={"ans-col12"}>
						<select className="ans-selected-sub" id="scenario">
							{activeScenarios.map((item, index) => {
								if (index === 0) {
									return (
										<option key={`sc_${index}`} value={item} selected>
											{item}
										</option>
									);
								} else {
									return (
										<option key={`sc_${index}`} value={item}>
											{item}
										</option>
									);
								}
							})}
						</select>
					</div>
				</div>
			);
		}

		return (
			<div id="addNewSub">
				<h4>
					Add a Sub-Category for <strong>{this.props.sendCategoryName}</strong> {title}
				</h4>
				<div className={"ans-divider"} />
				<form onSubmit={event => this.onConfirmClicked(event)}>
					{scenarioRow}

					<div className={"ans-row"}>
						<div className={"ans-col12"}>
							<span className={"ans-read-only"}>Sub Category Name:</span>
						</div>
						<div className={"ans-col12"}>
							<input
								className={"ans-text-input"}
								maxLength="30"
								required
								type="text"
								placeholder="Enter name for sub-category"
								id="sub_name"
							/>
						</div>
					</div>
					<div className={"ans-sub-button-container"}>
						<input type="submit" className={"ans-confirm"} value="Confirm" />
					</div>
				</form>
				<button className={"ans-cancel"} onClick={this.props.addSubCancel}>
					Cancel
				</button>
			</div>
		);
	}
}

export default AddNewSub;
