import React from "react";
import { connect } from "react-redux";
import { toggleNavigation } from "../../store/actions";

import { withRouter } from "react-router-dom";

const NavigationLink = props => {
	const { width } = props.ui;
	let linkClass = "navigation-link";

	if (props.checkActive === "active") {
		linkClass = "navigation-link active";
	}

	return (
		<div
			onClick={() => {
				if (width < 1440) props.toggleNavigation(false);
				props.history.push(`/${props.link}`);
			}}
			className={linkClass}
			title={props.title}
		>
			{props.children}
		</div>
	);
};

const mapStateToProps = state => ({
	ui: state.ui
});

export default connect(
	mapStateToProps,
	{ toggleNavigation }
)(withRouter(NavigationLink));
