import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";

import { getReminders } from "../../store/actions";
import { _getDecimal, _getCurrency, _getTodayDate } from "../../helpers/constants";

function priceFormatter(cell) {
	let tempValue = cell;
	if (tempValue < 0) cell = -1 * cell;
	let returnedValue = parseFloat(Math.round(cell + "e" + _getDecimal()) + "e-" + _getDecimal()).toFixed(_getDecimal());
	let formattedValue = returnedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	if (isNaN(returnedValue)) return <span />;
	else if (tempValue < 0)
		return (
			<span>
				{_getCurrency} ({formattedValue})
			</span>
		);
	else
		return (
			<span>
				{_getCurrency} {formattedValue}
			</span>
		);
}

let checkedReminders = [];

class ReminderDisplay extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showPast: false,
			title: "Upcoming marked transactions",
			pastText: "Show transactions with past reminders",
			reminderTransactions: []
		};
	}

	componentDidMount() {
		this.setState({ reminderTransactions: this.props.misc.reminders });
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ reminderTransactions: nextProps.misc.reminders });
	}

	componentWillUnmount() {
		// Reset reminder transactions to upcoming
		checkedReminders = [];
		this.props.getReminders(_getTodayDate(), this.props.user.activeScIds, false);
	}

	togglePast = () => {
		if (!this.state.showPast) {
			this.setState({
				showPast: true,
				title: "Transactions with previous marked dates"
			});
			document.getElementById("mrd-checkbox").checked = true;
			this.props.getReminders(_getTodayDate(), this.props.user.activeScIds, true);
		} else {
			this.setState({
				showPast: false,
				title: "Upcoming marked transactions"
			});
			document.getElementById("mrd-checkbox").checked = false;
			this.props.getReminders(_getTodayDate(), this.props.user.activeScIds, false);
		}
	};

	onCheckedClicked = e => {
		let value = e.target.value;
		let paidCheckedId = `mrd-paid_${value}`;
		let domElement = document.getElementById(paidCheckedId);

		if (domElement.checked) {
			checkedReminders.push(domElement.value);
		} else {
			_.remove(checkedReminders, item => item === domElement.value);
		}
	};

	render() {
		const { title, pastText, reminderTransactions, showPast } = this.state;
		let transactionTable = null;
		let groupedTransactions = reminderTransactions.slice(0);
		groupedTransactions = groupedTransactions.filter(
			(item, index, self) => index === self.findIndex(t => t.millis === item.millis)
		);

		transactionTable = (
			<table className={"mrd-table"}>
				<thead>
					<tr className={"mrd-header"}>
						{showPast && <th>Paid</th>}
						<th width="5%">SR</th>
						<th width="15%">Date</th>
						<th width="20%">Description</th>
						<th width="15%">Category</th>
						<th width="15%">Amount</th>
						<th width="25%">Notes</th>
					</tr>
				</thead>
				{groupedTransactions.length > 0 ? (
					<tbody>
						{groupedTransactions.map((item, index) => (
							<tr key={`rm_${index + 1}`} className={"mrd-transactions"}>
								{showPast && (
									<td>
										<input
											type="checkbox"
											name="paidCheckbox"
											id={`mrd-paid_${item.id}`}
											value={item.id}
											onClick={e => this.onCheckedClicked(e, item)}
										/>
									</td>
								)}
								<td onClick={() => this.props.transactionClicked(item)}>{index + 1}</td>
								<td onClick={() => this.props.transactionClicked(item)}>{item.start_date}</td>
								<td onClick={() => this.props.transactionClicked(item)}>{item.description}</td>
								<td onClick={() => this.props.transactionClicked(item)}>{item.category}</td>
								<td onClick={() => this.props.transactionClicked(item)}>{priceFormatter(item.amount)}</td>
								<td onClick={() => this.props.transactionClicked(item)}>{item.reminder_note}</td>
							</tr>
						))}
					</tbody>
				) : (
					<tbody>
						<tr>
							<td colSpan="7" className="mrd-nodata">
								No data for selected option
							</td>
						</tr>
					</tbody>
				)}
			</table>
		);

		return (
			<div id="modalReminderDisplay">
				<div className="mrd-title">{title}</div>
				<div className="mrd-table-wrap">{transactionTable}</div>
				<button className="mrd-ok" onClick={() => this.props.reminderConfirmHandler(checkedReminders)}>
					OK
				</button>

				<div className="mrd-see-past" onClick={() => this.togglePast()}>
					<input type="checkbox" name="showPast" id="mrd-checkbox" />
					{pastText}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	misc: state.misc,
	user: state.user
});

export default connect(
	mapStateToProps,
	{ getReminders }
)(ReminderDisplay);
