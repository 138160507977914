import React, { Component } from "react";
import { _getCurrency, _getDecimal } from "../../../helpers/constants";

function priceFormatter(cell) {
	let tempValue = cell;
	if (tempValue < 0) cell = -1 * cell;
	let returnedValue = parseFloat(Math.round(cell + "e" + _getDecimal()) + "e-" + _getDecimal()).toFixed(
		_getDecimal()
	);
	let formattedValue = returnedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	if (isNaN(returnedValue)) return <span />;
	else if (tempValue < 0)
		return (
			<span>
				{_getCurrency} ({formattedValue})
			</span>
		);
	else
		return (
			<span>
				{_getCurrency} {formattedValue}
			</span>
		);
}

class EditingListRow extends Component {
	state = {
		editedData: this.props.selectedForEditing
	};

	onDateChange = evt => {
		const newData = this.state.editedData;
		newData[evt.target.name] = evt.target.value;
		this.setState({
			editedData: newData
		});
	};

	render() {
		const { selectedForEditing, subName } = this.props;
		return (
			<div id="editingListRow">
				<div className={"elr-title"}>Edit Cash Flow Date</div>
				<div className={"elr-details"}>
					<div className={"elr-key"}>Invoice Date</div>
					<div className={"elr-value"}>{selectedForEditing.invoiceDate}</div>
				</div>
				<div className={"elr-details"}>
					<div className={"elr-key"}>Name</div>
					<div className={"elr-value"}>{selectedForEditing.listName}</div>
				</div>
				<div className={"elr-details"}>
					<div className={"elr-key"}>Subcategory</div>
					<div className={"elr-value"}>{subName}</div>
				</div>
				<div className={"elr-details"}>
					<div className={"elr-key"}>Amount</div>
					<div className={"elr-value"}>{priceFormatter(selectedForEditing.listAmount)}</div>
					<div className={"elr-value"} />
				</div>
				<div className={"elr-details"}>
					<div className={"elr-key"}>Due Date</div>
					<div className={"elr-value"}>{selectedForEditing.dueDate}</div>
					<div className={"elr-value"} />
				</div>
				<div className={"elr-details"}>
					<div className={"elr-key"} style={{ paddingTop: ".2rem" }}>
						Cash Flow Pay Date
					</div>
					<input
						type="date"
						name="cashDate"
						min={this.props.openingDate}
						onChange={this.onDateChange}
						defaultValue={selectedForEditing.cashDate}
						required
					/>
				</div>
				<button className={"elr-cancel"} onClick={() => this.props.editingRowCancel()}>
					Cancel Editing
				</button>
				<button className={"elr-confirm"} onClick={() => this.props.editingRowConfirm(this.state.editedData)}>
					Edit Data
				</button>
			</div>
		);
	}
}

export default EditingListRow;
