import axios from "axios";
import { GET_SCENARIOS, EDIT_SETTINGS, GET_TRANSACTIONS, GET_CATEGORY_DATA, EDIT_OPENING, RESET_SCENARIOS, JUST_LOGGED_IN } from "./types";
import { showSuccessToaster, showErrorToaster, setDataLoading, stopLoading } from "./uiActions";
import { connectingBaseLink, getActiveUser, _getOpeningDate, _getTodayDate } from "../../helpers/constants";
import { getReminders } from "./transactionActions";

export const getScenarios = () => dispatch => {
  dispatch(setDataLoading());
  const user = { user: getActiveUser() };
  axios.post(`${connectingBaseLink}scenarios/readSc.php`, user).then(res => {
    if (res.data.success) {
      let response = res.data.success;
      let scenarios = [];
      for (let i = 0; i < response.length - 1; i++) {
        scenarios.push(response[i]);
      }

      const headerTitle = response[response.length - 1].title;

      dispatch({
        type: GET_SCENARIOS,
        payload: { scenarios, headerTitle }
      });

      let activeScIds = scenarios.filter(item => item.status === "1").map(item => item.id);
      dispatch(getTransactions(activeScIds));
      dispatch(getCategoryData(activeScIds));
      dispatch(getReminders(_getTodayDate(), activeScIds, false));
      if (_getOpeningDate() === _getTodayDate()) dispatch(resetOpenings());
    }
  });
};

const resetOpenings = () => dispatch => {
  const data = { user: getActiveUser() };
  axios.post(`${connectingBaseLink}scenarios/resetOpenings.php`, data).then(res => {
    if (res.data.success) {
      let response = res.data.success;
      dispatch({
        type: RESET_SCENARIOS,
        payload: response
      });
    }
  });
};

export const getTransactions = scenarioIds => dispatch => {
  const data = { user: getActiveUser(), opening: _getOpeningDate(), scenarios: scenarioIds };

  axios
    .post(`${connectingBaseLink}categories/readCTrans.php`, data)
    .then(res => {
      if (res.data.success) {
        let response = res.data.success;
        response = response.filter(item => item.start_date >= _getOpeningDate());
        dispatch({
          type: GET_TRANSACTIONS,
          payload: response
        });
      }
    })
    .catch(err => dispatch(showErrorToaster("Some error occurred retrieving transaction(s).")));
};

export const getCategoryData = scenarios => dispatch => {
  const data = { user: getActiveUser(), opening: _getOpeningDate(), scenarios };

  axios
    .post(`${connectingBaseLink}categories/readC.php`, data)
    .then(res => {
      if (res.data.success) {
        let response = res.data.success;
        let temp = [];
        for (let i = 0; i < response.length; i++) {
          if ("category_name" in response[i]) temp.push(response[i].category_name);
        }
        dispatch({
          type: GET_CATEGORY_DATA,
          payload: { categoryData: response, categoryNames: temp }
        });
        setTimeout(() => {
          dispatch(stopLoading());
        }, 300);
      }
    })
    .catch(() => {});
};

export const editSettings = (oldTitle, headerTitle, openingDate, sIds) => dispatch => {
  const data = { user: getActiveUser(), title: headerTitle };
  dispatch({
    type: EDIT_SETTINGS,
    payload: { headerTitle, openingDate }
  });
  axios
    .post(`${connectingBaseLink}home/editTitle.php`, data)
    .then(res => {
      if (res.data.success) {
        dispatch(showSuccessToaster("Header title edited"));
        dispatch(getTransactions(sIds));
        dispatch(getCategoryData(sIds));
      } else {
        dispatch(showErrorToaster("Error editing header title"));
        dispatch({
          type: EDIT_SETTINGS,
          payload: { headerTitle: oldTitle, openingDate }
        });
      }
    })
    .catch(err => {
      dispatch(showErrorToaster(err.response.data));
      dispatch({
        type: EDIT_SETTINGS,
        payload: { headerTitle: oldTitle, openingDate }
      });
    });
};

export const editOpeningDate = (chosenDate, sIds) => dispatch => {
  const data = { user: getActiveUser(), date: chosenDate, todayDate: _getTodayDate() };
  dispatch({
    type: EDIT_OPENING,
    payload: chosenDate
  });
  axios.post(`${connectingBaseLink}scenarios/editXeroOpening.php`, data).then(res => {
    if (res.data.success) {
      let response = res.data.success;
      dispatch({
        type: RESET_SCENARIOS,
        payload: response
      });
    }
  });
  dispatch(getTransactions(sIds));
  dispatch(getCategoryData(sIds));
  dispatch(showSuccessToaster(`Opening date set to ${chosenDate}`));
};

export const justLoggedIn = status => {
  return {
    type: JUST_LOGGED_IN,
    payload: status
  };
};
