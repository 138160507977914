import React from "react";
import ReactLoading from "react-loading";

const Loading = ({ loaderId, loaderType, loaderColor, loaderText, widgetLoader }) => {
	return (
		<div id={loaderId}>
			<div className="le-loading">
				{widgetLoader ? (
					<ReactLoading type={loaderType} color={loaderColor} height={40} width={40} />
				) : (
					<ReactLoading type={loaderType} color={loaderColor} />
				)}
				<span>{loaderText}</span>
			</div>
		</div>
	);
};

export default Loading;
