import React from "react";
import MenuDivider from "../../common/MenuDivider";

const editSub = props => {
	return (
		<div id="modalEditSub">
			<div className="mes-title">
				Edit - <strong>{props.subName.name}</strong>
				&nbsp;(
				{props.subName.category})
			</div>
			<MenuDivider />

			<form
				onSubmit={event => props.editSubConfirm(event, document.getElementById("new_name").value)}
				method="POST"
			>
				<div className="mes-body">
					<div style={{ width: "40%", paddingTop: ".3rem", textAlign: "right" }}>Enter new name</div>
					<input type="text" id="new_name" minLength="3" maxLength="30" required />
				</div>
				<input type="submit" className="mes-confirm" value="Update" />
			</form>

			<button className="mes-cancel" onClick={() => props.editSubCancel()}>
				Cancel
			</button>
		</div>
	);
};

export default editSub;
