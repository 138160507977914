import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { hot } from "react-hot-loader/root";

import Dashboard from "./components/dashboard/Dashboard";
import Transactions from "./components/transaction/Transactions";
import SignIn from "./components/auth/SignIn";
import Register from "./components/auth/Register";
import ForgotPass from "./components/auth/ForgotPass";
import PrivateRoute from "./components/common/PrivateRoute";
import NotFound from "./components/layout/NotFound";

const App = () => {
	return (
		<div className="app site">
			<Switch>
				<Route exact path="/" render={() => <Redirect to="/login" />} />
				<Route exact path="/login" component={SignIn} />
				<Route exact path="/register" component={Register} />
				<Route exact path="/forgotpassword" component={ForgotPass} />
				<Switch>
					<PrivateRoute exact path="/dashboard" component={Dashboard} />
					<PrivateRoute exact path="/transactions" component={Transactions} />
				</Switch>
				<Route exact={true} path="*" component={NotFound} />
			</Switch>
		</div>
	);
};

export default hot(App);
