import React, { Component } from 'react'
import { Line } from 'react-chartjs-2'
import moment from 'moment'
import DayPickerInput from 'react-day-picker/DayPickerInput'
// import Tour from "reactour";

import { getTransactionDataGroupedByDate } from '../../helpers/data'
import { formatDate, parseDate } from 'react-day-picker/moment'

import 'react-day-picker/lib/style.css'
import {
  _getFirstDate,
  // _getLastDate,
  _colorArray,
  _getColorArray,
  _setColorArray
} from '../../helpers/constants'

let newTransactions
let scenariosSet = false
// let continueCurveTour = true;

function shuffle(array) {
  let i = array.length,
    j = 0,
    temp

  while (i--) {
    j = Math.floor(Math.random() * (i + 1))

    // swap randomly chosen element with current element
    temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array
}

function formatDecimal(value, decimals) {
  // return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
  let returnedValue = parseFloat(
    Math.round(value + 'e' + decimals) + 'e-' + decimals
  ).toFixed(decimals)
  if (isNaN(returnedValue)) return value
  else return returnedValue
  // return parseFloat(Math.round(value * 100) / 100).toFixed(decimals);
}

class CurveGraph extends Component {
  constructor(props) {
    super(props)
    this.state = {
      chartData: {},
      from: undefined,
      to: undefined,
      allData: [],
      scenarioSeparatedBalances: [],
      amountArray: [],
      originalDates: [],
      scenarios: []
    }
    this.handleFromChange = this.handleFromChange.bind(this)
    this.handleToChange = this.handleToChange.bind(this)
  }

  componentWillReceiveProps() {
    scenariosSet = false
  }

  componentDidUpdate() {
    if (!scenariosSet) {
      let scenarioIds = this.props.scenarioList.map(item => item.id)
      Promise.resolve(getTransactionDataGroupedByDate(scenarioIds)).then(
        result => {
          let response = result.message
          if (response === 'no') {
            // do nothing
          } else {
            this.plotTheChart(response)
          }
        }
      )
      scenariosSet = true
    }
  }

  plotTheChart = balances => {
    let minimumDate = this.props.openingDate
    let minimumYear = moment(minimumDate, 'YYYY-MM-DD').year()

    let firstDate = _getFirstDate(this.props.selectedYear)
    // let lastDate = _getLastDate(this.props.selectedYear + 1)
    let lastDate = moment(minimumDate)
      .add(1, 'months')
      .endOf('month')
      .format('YYYY-MM-DD')

    let allData = balances.slice(0)
    let tempScenarios = balances.slice(0)

    let confirmedScenarios = []
    for (let i = 0, len = this.props.scenarioList.length; i < len; i++) {
      if (
        this.props.currentScenario.includes(this.props.scenarioList[i].name)
      ) {
        confirmedScenarios.push(this.props.scenarioList[i])
      }
    }

    if (minimumYear === this.props.selectedYear) {
      tempScenarios = tempScenarios.filter(
        item => item.start_date >= minimumDate && item.start_date <= lastDate
      )
    } else {
      tempScenarios = tempScenarios.filter(
        item => item.start_date >= firstDate && item.start_date <= lastDate
      )
    }

    let originalDates = []
    let labelDates = []
    for (let i = 0, len = tempScenarios.length; i < len; i++) {
      let fullDate = tempScenarios[i].start_date
      if (originalDates.indexOf(fullDate) < 0) {
        originalDates.push(fullDate)
      }
    }
    originalDates.sort()
    if (
      originalDates[0] > minimumDate &&
      minimumYear === this.props.selectedYear
    ) {
      originalDates.unshift(minimumDate)
    }

    for (let k = 0, len = originalDates.length; k < len; k++) {
      let fullDate = originalDates[k]
      let dateString = fullDate.substring(8)
      let monthString = fullDate.substring(5, 7)
      // let yearString = fullDate.substring(2, 4);
      let displayedDate = dateString + '/' + monthString
      // + '/' + yearString;
      labelDates.push(displayedDate)
    }

    let scenarioSeparatedBalances = []
    for (
      let i = 0, thisLength = confirmedScenarios.length;
      i < thisLength;
      i++
    ) {
      let checkedScenario = confirmedScenarios[i].name
      scenarioSeparatedBalances.push({
        scenario: checkedScenario,
        data: tempScenarios.filter(function(item) {
          return item.scenario === checkedScenario
        })
      })
    }

    let tempArray = []
    let maxLength = 0
    let numberOfScenarios = scenarioSeparatedBalances.length

    for (let i = 0, len = numberOfScenarios; i < len; i++) {
      let tempData = scenarioSeparatedBalances[i].data
      if (tempData.length > maxLength) {
        maxLength = tempData.length
      }
    }

    let theirLength = confirmedScenarios.length

    for (let i = 0; i < numberOfScenarios; i++) {
      let tempScenario = scenarioSeparatedBalances[i].scenario
      let tempData = scenarioSeparatedBalances[i].data

      let dataArray = []
      let openingAmount = 0
      for (let k = 0; k < theirLength; k++) {
        if (tempScenario === confirmedScenarios[k].name) {
          openingAmount = confirmedScenarios[k].opening
        }
      }
      let balance = +openingAmount
      for (let j = 0; j < originalDates.length; j++) {
        if (
          tempData[j] !== undefined &&
          originalDates[j] === tempData[j].start_date
        ) {
          // do nothing
        } else {
          tempData.splice(j, 0, {
            scenario: tempScenario,
            start_date: originalDates[j],
            sum: 0
          })
        }

        balance += parseFloat(tempData[j].sum)
        tempData[j].sum = balance
        dataArray.push(formatDecimal(tempData[j].sum, 2))
      }
      scenarioSeparatedBalances[i].data = tempData
      tempArray.push(dataArray)
    }

    let cumulativeData = []
    for (let i = 0; i < originalDates.length; i++) {
      let amount = 0
      for (let j = 0; j < numberOfScenarios; j++) {
        if (
          this.props.currentScenario.includes(
            scenarioSeparatedBalances[j].scenario
          )
        ) {
          amount += parseFloat(tempArray[j][i])
        }
      }
      cumulativeData.push(formatDecimal(amount, 2))
    }

    let amountArray = []
    for (let i = 0; i < numberOfScenarios; i++) {
      let anotherArray = []
      for (let j = 0; j < originalDates.length; j++) {
        anotherArray.push(formatDecimal(tempArray[i][j], 2))
      }
      amountArray.push(anotherArray)
    }

    let tempDataSets = this.assignValuesToChart(
      scenarioSeparatedBalances,
      cumulativeData,
      amountArray
    )

    let chartData = {
      labels: labelDates,
      datasets: tempDataSets
    }

    this.setState({
      chartData: chartData,
      allData: allData,
      scenarioSeparatedBalances: scenarioSeparatedBalances,
      amountArray: tempArray,
      originalDates: originalDates
    })
  }

  assignValuesToChart = (balances, cumulativeData, amountArray) => {
    let graphColors = []
    if (_getColorArray() === null) {
      let tempColors = _colorArray
      tempColors = shuffle(tempColors)
      let temp = []
      for (let i = 0; i < 20; i++) {
        temp.push(tempColors[i])
      }
      _setColorArray(temp)
      graphColors = temp
    } else {
      graphColors = _getColorArray()
    }

    let tempDataSets = []

    let counter = 0
    for (let i = 0; i < balances.length; i++) {
      if (this.props.currentScenario.includes(balances[i].scenario)) {
        tempDataSets.push({
          label: balances[i].scenario,
          backgroundColor: 'transparent',
          borderColor: graphColors[counter].color,
          pointBorderColor: graphColors[counter].color,
          pointBackgroundColor: '#ffffff',
          pointHoverBackgroundColor: graphColors[counter].color,
          pointHoverBorderColor: graphColors[counter].color,
          pointBorderWidth: 0,
          pointRadius: 0,
          data: amountArray[i]
        })
        counter++
      }
    }

    tempDataSets.push({
      label: 'Cumulative',
      backgroundColor: 'transparent',
      borderColor: '#444444b3',
      pointBorderColor: '#444444b3',
      pointBackgroundColor: '#ffffff',
      pointHoverBackgroundColor: '#444444b3',
      pointHoverBorderColor: '#444444b3',
      pointBorderWidth: 0,
      pointRadius: 0,
      data: cumulativeData
    })

    return tempDataSets
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  showFromMonth() {
    const { from, to } = this.state
    if (!from) {
      return
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from)
    }

    let fromDate = moment(from).format('YYYY-MM-DD')
    let toDate = moment(to).format('YYYY-MM-DD')
    let tempScenarios = this.state.allData.slice(0)
    let confirmedScenarios = this.props.scenarioList.slice(0)

    tempScenarios = tempScenarios.filter(function(item) {
      return item.start_date >= fromDate && item.start_date <= toDate
    })

    let originalDates = []
    let labelDates = []
    for (let i = 0, len = tempScenarios.length; i < len; i++) {
      let fullDate = tempScenarios[i].start_date
      if (originalDates.indexOf(fullDate) < 0) {
        originalDates.push(fullDate)
      }
    }

    for (let k = 0, len = originalDates.length; k < len; k++) {
      let fullDate = originalDates[k]
      let dateString = fullDate.substring(8)
      let monthString = fullDate.substring(5, 7)
      // let yearString = fullDate.substring(2, 4);
      let displayedDate = dateString + '/' + monthString
      // + '/' + yearString;
      labelDates.push(displayedDate)
    }

    let scenarioSeparatedBalances = []
    for (
      let i = 0, thisLength = confirmedScenarios.length;
      i < thisLength;
      i++
    ) {
      let checkedScenario = confirmedScenarios[i].name
      scenarioSeparatedBalances.push({
        scenario: checkedScenario,
        data: tempScenarios.filter(function(item) {
          return item.scenario === checkedScenario
        })
      })
    }

    let tempArray = []
    let maxLength = 0
    let numberOfScenarios = scenarioSeparatedBalances.length

    for (let i = 0, len = numberOfScenarios; i < len; i++) {
      let tempData = scenarioSeparatedBalances[i].data
      if (tempData.length > maxLength) {
        maxLength = tempData.length
      }
    }

    let theirLength = confirmedScenarios.length

    for (let i = 0; i < numberOfScenarios; i++) {
      let tempScenario = scenarioSeparatedBalances[i].scenario
      let tempData = scenarioSeparatedBalances[i].data

      let dataArray = []
      let openingAmount = 0
      for (let k = 0; k < theirLength; k++) {
        if (tempScenario === confirmedScenarios[k].name) {
          openingAmount = confirmedScenarios[k].opening
        }
      }
      let balance = +openingAmount
      for (let j = 0; j < originalDates.length; j++) {
        if (
          tempData[j] !== undefined &&
          originalDates[j] === tempData[j].start_date
        ) {
          // do nothing
        } else {
          tempData.splice(j, 0, {
            scenario: tempScenario,
            start_date: originalDates[j],
            sum: 0
          })
        }

        balance += parseFloat(tempData[j].sum)
        tempData[j].sum = balance
        dataArray.push(formatDecimal(tempData[j].sum, 2))
      }
      scenarioSeparatedBalances[i].data = tempData
      tempArray.push(dataArray)
    }

    let cumulativeData = []
    for (let i = 0; i < originalDates.length; i++) {
      let amount = 0
      for (let j = 0; j < numberOfScenarios; j++) {
        amount += tempArray[j][i]
      }
      cumulativeData.push(amount)
    }

    let amountArray = []
    for (let i = 0; i < numberOfScenarios; i++) {
      let anotherArray = []
      for (let j = 0; j < originalDates.length; j++) {
        anotherArray.push(formatDecimal(tempArray[i][j], 2))
      }
      amountArray.push(anotherArray)
    }

    let tempDataSets = this.assignValuesToChart(
      scenarioSeparatedBalances,
      cumulativeData,
      amountArray
    )

    let chartData = {
      labels: labelDates,
      datasets: tempDataSets
    }

    this.setState({
      chartData: chartData
    })
  }

  handleFromChange(from) {
    this.setState({ from })
  }

  handleToChange(to) {
    this.setState({ to }, this.showFromMonth)
  }

  getAllTransactions = response => {
    this.resetValues()

    let balancedTransactions = this.setBalancesForTransaction(response)

    for (let i = 0, len = balancedTransactions.length; i < len; i++) {
      let transaction = balancedTransactions[i]
      newTransactions.push(transaction)
    }

    this.groupTransactionByDates()
  }

  resetValues = () => {
    newTransactions = []
  }

  setBalancesForTransaction = transactions => {
    let tempTrans = transactions.slice()
    tempTrans.sort((a, b) => {
      if (a.start_date === b.start_date) {
        return a.id > b.id ? 1 : -1
      } else {
        return a.start_date > b.start_date ? 1 : -1
      }
    })

    let currentBalance = 0
    tempTrans = tempTrans.map(item => {
      currentBalance += item.amount
      item.balance = currentBalance
      return item
    })
    return tempTrans
  }

  render() {
    const { from, to } = this.state
    const modifiers = { start: from, end: to }
    let legendBelow = this.props.legendBelow

    let legendPosition = 'right'
    if (legendBelow) legendPosition = 'bottom'

    return (
      <div id="curve-graph">
        <div className="panel-title">
          <h3>Daily Balances</h3>
          <div className="input-day-picker">
            <DayPickerInput
              value={from}
              placeholder="From"
              format="LL"
              formatDate={formatDate}
              parseDate={parseDate}
              dayPickerProps={{
                selectedDays: [from, { from, to }],
                disabledDays: { after: to },
                toMonth: to,
                modifiers,
                numberOfMonths: 2,
                onDayClick: () => this.to.getInput().focus()
              }}
              onDayChange={this.handleFromChange}
            />{' '}
            -{' '}
            <span className="input-to">
              <DayPickerInput
                ref={el => (this.to = el)}
                value={to}
                placeholder="To"
                format="LL"
                formatDate={formatDate}
                parseDate={parseDate}
                dayPickerProps={{
                  selectedDays: [from, { from, to }],
                  disabledDays: { before: from },
                  modifiers,
                  month: from,
                  fromMonth: from,
                  numberOfMonths: 2
                }}
                onDayChange={this.handleToChange}
              />
            </span>
          </div>
          <div className="clearfix" />
        </div>
        <div className="wide-line" id="curve_graph">
          <Line
            data={this.state.chartData}
            options={{
              legend: {
                position: legendPosition
              },
              maintainAspectRatio: false,
              layout: {
                padding: {
                  left: 10,
                  top: 20,
                  bottom: 10
                }
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      fontSize: this.props.graphFontSize,
                      labelOffset: -5
                    }
                  }
                ],
                xAxes: [
                  {
                    ticks: {
                      fontSize: this.props.graphFontSize,
                      labelOffset: 20,
                      maxRotation: 0,
                      minRotation: 0
                    }
                  }
                ]
              }
            }}
          />
        </div>
        <div className="narrow-line" id="curve_graph">
          <Line
            data={this.state.chartData}
            options={{
              legend: {
                position: 'top',
                labels: {
                  fontSize: this.props.graphFontSize,
                  boxWidth: 16
                }
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      fontSize: this.props.graphFontSize
                    }
                  }
                ],
                xAxes: [
                  {
                    ticks: {
                      fontSize: this.props.graphFontSize,
                      maxRotation: 0,
                      minRotation: 0
                    }
                  }
                ]
              },
              tooltips: {
                enabled: true,
                mode: 'label',
                callbacks: {
                  title: function(tooltipItems, data) {
                    let idx = tooltipItems[0].index
                    return 'Date:' + data.labels[idx]
                  }
                }
              }
            }}
          />
        </div>
      </div>
    )
  }
}

export default CurveGraph
