import axios from "axios";
import { SET_ACTIVE_SCENARIOS, ADD_SCENARIO, EDIT_SCENARIO } from "./types";
import { showSuccessToaster, showWarningToaster, showErrorToaster } from "./uiActions";
import { connectingBaseLink, getActiveUser } from "../../helpers/constants";
import { getTransactions, getCategoryData } from "./userActions";

export const setActiveScenarios = scenarioIds => dispatch => {
  const data = { user: getActiveUser(), ids: scenarioIds };
  axios.post(`${connectingBaseLink}scenarios/active.php`, data).then(res => {
    if (res.data.success) {
      dispatch({
        type: SET_ACTIVE_SCENARIOS,
        payload: res.data.success
      });
      dispatch(getTransactions(scenarioIds));
      dispatch(getCategoryData(scenarioIds));
    }
  });
};

export const addScenario = (scenario, amount) => dispatch => {
  const data = { user: getActiveUser(), scenario, amount };

  axios
    .post(`${connectingBaseLink}scenarios/addSc.php`, data)
    .then(res => {
      if (res.data.success) {
        dispatch({
          type: ADD_SCENARIO,
          payload: res.data.success
        });
        dispatch(showSuccessToaster(`Added New Scenario '${scenario}'`));
      } else if (res.data.message === "present") {
        dispatch(showWarningToaster("That scenario is already present"));
      } else {
        dispatch(showErrorToaster("Error adding new scenario. Please try again."));
      }
    })
    .catch(err => dispatch(showErrorToaster(err.response.data)));
};

export const updateScenarios = scenarioData => dispatch => {
  const data = { user: getActiveUser(), scenarioData };

  axios
    .post(`${connectingBaseLink}scenarios/updateSc.php`, data)
    .then(res => {
      let changedRows = res.data.changed;
      if (changedRows > 0) {
        let suffix = changedRows === 1 ? "opening" : "openings";
        dispatch({
          type: EDIT_SCENARIO,
          payload: res.data.success
        });
        dispatch(showSuccessToaster(changedRows + " " + suffix + " successfully edited"));
      }
    })
    .catch(err => dispatch(showErrorToaster(err.response.data)));
};

export const editScenario = (name, newName, opening) => dispatch => {
  const data = { user: getActiveUser(), name, newName, opening };

  axios
    .post(`${connectingBaseLink}scenarios/editSc.php`, data)
    .then(res => {
      if (res.data.success) {
        dispatch({
          type: EDIT_SCENARIO,
          payload: res.data.success
        });
        dispatch(showSuccessToaster("Scenario is edited"));
      } else {
        dispatch(showErrorToaster("Error editing scenario"));
      }
    })
    .catch(err => dispatch(showErrorToaster(err)));
};

export const deleteScenario = (scenarioName, onlyActive) => dispatch => {
  const data = { user: getActiveUser(), scenario: scenarioName, onlyActive };

  axios
    .post(`${connectingBaseLink}scenarios/deleteSc.php`, data)
    .then(res => {
      if (res.data.success) {
        dispatch({
          type: EDIT_SCENARIO,
          payload: res.data.success
        });
        dispatch(showSuccessToaster(`Scenario '${scenarioName}' deleted`));
      } else {
        dispatch(showErrorToaster("Delete failed. Please try again."));
      }
    })
    .catch(err => dispatch(showErrorToaster(err.response.data)));
};
