import React, { Fragment } from "react";

const NotFound = () => {
	return (
		<Fragment>
			<div id="notFound">
				<div>Page Not Found</div>
				<p>
					Please visit our <a href="https://cash-flow-manager.com.au">CashFlow HomePage</a>
				</p>
			</div>
		</Fragment>
	);
};

export default NotFound;
