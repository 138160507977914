import React, { Component } from "react";
import classnames from "classnames";

export default class EnterConfirmationDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableButton: true,
      scenarioValue: ""
    };
  }

  onInputChanged = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { message, scenarioName } = this.props;
    const { scenarioValue } = this.state;
    return (
      <div id="enterConfirmationDialog">
        <h3>Scenario Confirm Deletion</h3>
        <hr />
        <p>
          Enter the name of scenario (<strong>{scenarioName}</strong>) in the box below and confirm to delete.
        </p>
        <div className="ecd-input">
          <input type="text" name="scenarioValue" id="scenarioValue" onChange={this.onInputChanged} />
        </div>
        <p>*Please note that this process is not reversible and it will permanently delete the scenario and its data.</p>
        <p>*{message}</p>
        <div className="cd-button">
          <button
            className={classnames("cd-confirm", {
              "cd-disable": scenarioValue !== scenarioName
            })}
            onClick={() => this.props.onDeleteConfirmClicked()}
          >
            {this.props.dialogPositive}
          </button>
          <button className="cd-cancel" onClick={() => this.props.onDeleteCancelClicked()}>
            {this.props.dialogNegative}
          </button>
        </div>
      </div>
    );
  }
}
