import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import ReactLoading from "react-loading";

import {
  setActiveScenarios,
  getScenarios,
  toggleNavigation,
  clearToasters
} from "../../store/actions";

import SideDrawer from "../navigation/SideDrawer";
import Toolbar from "../navigation/Toolbar";
import Loading from "../common/Loading";

import Footer from "../navigation/Footer";

class Layout extends Component {
  state = {
    loading: false,
    showTour: false,
    continueTour: false,
    continueTransTour: false
  };

  componentDidMount() {
    this.props.getScenarios();
    const { width } = this.props.ui;
    if (width >= 1440) {
      this.props.toggleNavigation(true);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { toasters } = nextProps.ui;

    if (toasters.success) {
      this.displayToaster(toasters.success, "success-toaster");
    }
    if (toasters.errors) {
      this.displayToaster(toasters.errors, "error-toaster");
    }
    if (toasters.warning) {
      this.displayToaster(toasters.warning, "warning-toaster");
    }
  }

  showLoading = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.hideLoading();
    }, 10000);
  };

  hideLoading = () => {
    this.setState({ loading: false });
  };

  /**************** Defining Toasters **********************/
  displayToaster = (displayText, toasterClass) => {
    toast(displayText, {
      className: toasterClass
    });
    this.props.clearToasters();
  };

  // React Tour
  continueTour = () => {
    this.setState({ continueTour: true });
  };

  continueTransTour = () => {
    this.setState({ continueTransTour: true });
  };

  render() {
    const { userLoading } = this.props.user;
    const { navigationOn, guidedTour } = this.props.ui;

    let contentClass = "";
    let backClasses = "";
    if (navigationOn) {
      backClasses = "back-drop back-shown";
      contentClass = "content";
    } else {
      contentClass = "wide-content";
      backClasses = "back-drop back-hidden";
    }

    return (
      <Fragment>
        <div id="layout">
          <div className="body">
            {this.state.loading && (
              <Loading
                loaderId="loadingElement"
                loaderType="spinningBubbles"
                loaderColor="#fafafa"
                loaderText="Connecting to Xero..."
              />
            )}
            <div
              className={backClasses}
              onClick={() => this.props.toggleNavigation(false)}
            />
            <Toolbar
              connectToXero={this.props.connectToXero}
              navigationOn={navigationOn}
              showTour={this.props.showTour}
              continueTour={this.props.continueTour}
              showLoading={this.showLoading}
              hideLoading={this.hideLoading}
            />
            <SideDrawer
              navigationOn={navigationOn}
              guidedTour={guidedTour}
              continueTransTour={this.props.continueTransTour}
            />
            <main className={contentClass}>
              <div style={{ opacity: userLoading ? "0" : "1" }}>
                {this.props.children}
              </div>
              <div
                className="layout-loading-bar"
                style={{ display: userLoading ? "block" : "none" }}
              >
                <ReactLoading type="spinningBubbles" color="#fafafa" />
                <span>Loading...</span>
              </div>
            </main>
          </div>
          <div style={{ height: "32rem" }} />
        </div>
        {!userLoading && <Footer />}
        <ToastContainer
          position="bottom-right"
          autoClose={4000}
          hideProgressBar={true}
          closeOnClick
          rtl={false}
          draggable={false}
          pauseOnHover
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  ui: state.ui,
  user: state.user
});

export default connect(
  mapStateToProps,
  { setActiveScenarios, getScenarios, toggleNavigation, clearToasters }
)(Layout);
