import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const grid = ".2rem";

const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: "none",
	padding: ".8rem",
	margin: `0 0 ${grid} 0`,
	display: "flex",

	// change background colour if dragging
	background: isDragging ? "#ececec" : "#fafafa",

	// styles we need to apply on draggables
	...draggableStyle
});

const getListStyle = isDraggingOver => ({
	background: "transparent",
	padding: grid
});

class ReorderCategory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			items: this.props.categories
		};
		this.onDragEnd = this.onDragEnd.bind(this);
	}

	onDragEnd(result) {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		const items = reorder(this.state.items, result.source.index, result.destination.index);

		this.setState({
			items
		});
	}

	render() {
		return (
			<div id="modalReorderCategory">
				<div className="mrc-title"> Drag and drop categories to reorder:</div>
				<DragDropContext onDragEnd={this.onDragEnd}>
					<Droppable droppableId="droppable">
						{(provided, snapshot) => (
							<div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
								{this.state.items.map((item, index) => (
									<Draggable key={item.category_id} draggableId={item.category_id} index={index}>
										{(provided, snapshot) => (
											<div
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
											>
												{item.category_name}
											</div>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
				<div className="mrc-sbutton">
					<button className="mrc-cancel" onClick={() => this.props.reorderCategoryCancel()}>
						Cancel
					</button>
					<button className="mrc-confirm" onClick={() => this.props.reorderCategoryConfirm(this.state.items)}>
						Confirm
					</button>
				</div>
			</div>
		);
	}
}

export default ReorderCategory;
