import React, { Fragment } from "react";

const helpSection = props => {
	return (
		<Fragment>
			<div className="hs-title">Help</div>
			<div id="helpSection">
				<div className="hs-sub">
					For support, email us at: <br /> <a href="https://zentaflow.com.au/faq">support@zentaflow.com.au</a>
				</div>
				<div className="hs-sub" style={{ margin: "0" }}>
					<a href="https://zentaflow.com.au/faq">Frequency Asked Questions</a>
				</div>
			</div>
		</Fragment>
	);
};

export default helpSection;
