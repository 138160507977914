import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connectingBaseLink } from "../../helpers/constants";
import AuthHeader from "./AuthHeader";

class ForgotPass extends Component {
	state = {
		showForgotError: false,
		showForgotSuccess: false,
		messageForgot: ""
	};

	onSubmitForgotP = (event, email) => {
		event.preventDefault();
		fetch(`${connectingBaseLink}home/forgotP.php`, {
			method: "post",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				email: email
			})
		})
			.then(response => response.json())
			.then(result => {
				let data = result.message;
				if (data === "no") {
					this.setState({
						showForgotError: true,
						showForgotSuccess: false,
						messageForgot: "That user is not in our database. Go to Sign up for new account."
					});
				} else if (data === "yes") {
					this.setState({
						showForgotError: false,
						showForgotSuccess: true,
						messageForgot: "We've send you an email. Check your email inbox for further instructions."
					});
				} else if (data === "cant") {
					this.setState({
						showForgotError: false,
						showForgotSuccess: true,
						messageForgot: "Network error"
					});
				} else {
					this.setState({
						showForgotError: false,
						showForgotSuccess: true,
						messageForgot: data
					});
				}
			});
	};

	render() {
		return (
			<AuthHeader>
				<div>
					<form
						onSubmit={event => this.onSubmitForgotP(event, document.getElementById("email").value)}
						id="loginForm"
						style={{ minHeight: "40vh" }}
						method="POST"
					>
						<span className="login-title">Forgot Password</span>
						<div className="standard-text">
							<div>Please enter your email address below and</div>
							<p style={{ paddingBottom: "1.5rem" }}>we will send you a reset link</p>
						</div>

						<span className="label-input" style={{ opacity: ".3" }}>
							Enter your email here
						</span>
						<div className="input-wrap" style={{ height: "4.4rem" }}>
							<input
								className="input"
								style={{ paddingTop: "1.4rem", fontSize: "1.2em" }}
								id="email"
								type="email"
								name="email"
								required
							/>
						</div>

						{this.state.showForgotError ? (
							<div className="error-message">{this.state.messageForgot}</div>
						) : null}

						<div className="button-container" style={{ paddingTop: "2rem" }}>
							<input
								type="submit"
								className="button-login"
								style={{ fontSize: "1em", height: "36px", width: "50%" }}
								value="Send Reset Link"
							/>
						</div>

						<div style={{ textAlign: "center", paddingTop: "4rem" }}>
							<p>
								<Link to="/login" className="link">
									BACK TO LOGIN
								</Link>
							</p>
						</div>
					</form>
				</div>
			</AuthHeader>
		);
	}
}

export default ForgotPass;
