import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Tour from "reactour";
import moment from "moment";

import {
  editSubCategory,
  deleteSubCategory,
  continueGuidedTour,
  openTransactionForm,
  clearTransactionForm
} from "../../store/actions";

import EditSubList from "./sub-data/EditSubList";
import TempTable from "./sub-data/TempTable";
import {
  _tableMonthHeaders,
  _getMonthNameId,
  _getCurrency,
  connectingBaseLink,
  getActiveUser
} from "../../helpers/constants";
import Modal from "react-responsive-modal";
import EditSub from "./sub-data/EditSub";

import ConfirmationDialog from "../dialogs/ConfirmationDialog";
import SubDataTable from "./SubDataTable";
import AddForm from "../layout/AddForm";

let categoryMonthlySums = [];
let categoryWeeklySums = [];
let categoryYearlySums = [];

let grandTotalMonthly = [];
let grandTotalYearly = [];
let grandTotalWeekly = [];

let carryForwardYears = [];
let carryForwardBalances = [];

let openingYear;

let subToBeDeleted;

let dataSet = false;

function formatDecimal(value, decimals) {
  let tempValue = value;
  if (tempValue < 0) value = -1 * value;
  let returnedValue = Math.round(value);
  let formattedValue = returnedValue
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (isNaN(returnedValue)) return "";
  else if (tempValue < 0) return _getCurrency + " (" + formattedValue + ")";
  else return _getCurrency + " " + formattedValue;
}

class CategoryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      selectedCategoryId: -1,
      showEntry: false,
      showAddOptions: false,
      tableHeaders: _tableMonthHeaders,
      dataWeekly: [],
      dataMonthly: [],
      dataYearly: [],
      monthlyBalances: [],
      weeklyBalances: [],
      yearlyBalances: [],
      transactionData: [],
      carryForward: [],
      showNewSubEdit: false,
      showConfirmation: false,
      showNewSubTrans: false,
      editSub: false,
      subScenario: "",
      subCategory: "",
      subName: "",
      subId: "",
      selectedSub: "",
      subTrans: [],
      leftTableWidth: 0,
      subListData: [],
      listData: [],
      invalidData: false,
      run: false,
      steps: [
        {
          selector: "#category_names",
          content:
            "Change name and/or reorder as desired by clicking on a Category name.",
          position: "top"
        },
        {
          selector: "#category_names",
          content:
            "Change name and/or reorder as desired by clicking on a Category name.",
          position: "top"
        }
      ]
    };
    this.leftRef = React.createRef(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    setTimeout(() => {
      if (window.innerWidth >= 900) {
        this.setState({
          width: window.innerWidth,
          leftTableWidth: this.leftRef.clientWidth
        });
      } else {
        this.setState({
          width: window.innerWidth,
          leftTableWidth: 400
        });
      }
    }, 100);
  };

  componentWillReceiveProps(nextProps) {
    dataSet = false;
    openingYear = moment(nextProps.openingDate, "YYYY-MM-DD").year();
    if (
      nextProps.ui.guidedTour.status &&
      nextProps.ui.guidedTour.element === 4
    ) {
      this.setState({ run: true });
    }
  }

  componentDidUpdate() {
    let temp = this.props.transactionData.slice(0);
    temp = temp.filter(item =>
      this.props.currentScenario.includes(item.scenario)
    );

    let type = this.props.selectedOption;
    if (!dataSet) {
      if (type === "W") {
        this.distributeBalancesByWeek(temp.slice());
      } else if (type === "Y") {
        this.distributeBalancesByYear(temp.slice());
      } else if (type === "M") {
        this.distributeBalancesByMonth(temp.slice());
      }
      dataSet = true;
    }
  }

  distributeBalancesByWeek = allData => {
    let categoriesList = this.props.categoriesList.slice(0);
    let amountList = [];
    grandTotalWeekly = [];
    for (let i = 0; i < 53; i++) {
      grandTotalWeekly.push(0);
      amountList.push(0);
    }

    categoryWeeklySums = categoriesList.map(item => {
      return { category: item, data: amountList.slice() };
    });

    let numberCategories = categoriesList.length;
    let minimumDate = this.props.openingDate;
    let minimumWeekNumber = moment(minimumDate, "YYYY-MM-DD").isoWeek();

    let currentYear = this.props.selectedYear;
    let tempHeaders = [];
    let startingIndex = 0;

    if (openingYear === currentYear) startingIndex = minimumWeekNumber - 1;
    for (let i = startingIndex; i < 53; i++) {
      let testDay = moment()
        .day("Sunday")
        .isoWeek(i + 1);
      let testDate = testDay.format("DD/MM");
      tempHeaders.push({
        id: `week_${i + 1}`,
        content: `Week #${i + 1} (end ${testDate})`
      });
    }

    for (let k = 0; k < numberCategories; k++) {
      for (let i = allData.length - 1; i >= 0; i--) {
        let date = allData[i].start_date;
        let weekNumber = moment(date, "YYYY-MM-DD").isoWeek();
        let year = +date.substring(0, 4);

        if (year === currentYear) {
          if (categoryWeeklySums[k].category === allData[i].category) {
            let amountToAdd = parseFloat(allData[i].amount);
            let preAmount = categoryWeeklySums[k].data[weekNumber - 1];
            let newAmount = preAmount + amountToAdd;
            categoryWeeklySums[k].data[weekNumber - 1] = newAmount;

            let preTotal = grandTotalWeekly[weekNumber - 1];
            let newTotal = preTotal + amountToAdd;
            grandTotalWeekly[weekNumber - 1] = newTotal;
            allData.splice(i, 1);
          }
        }
      }
    }

    let amount = 0;
    for (let i = 0, len = this.props.scenarios.length; i < len; i++) {
      if (this.props.currentScenario.includes(this.props.scenarios[i].name)) {
        amount += +this.props.scenarios[i].opening;
      }
    }

    let amountTotalForYear = grandTotalWeekly.reduce((a, b) => a + b, 0);
    let forwardBalance = amountTotalForYear;
    if (currentYear === openingYear)
      forwardBalance = amountTotalForYear + amount;

    if (carryForwardYears.includes(currentYear)) {
      let findIndex = carryForwardYears.indexOf(currentYear);
      if (currentYear === openingYear)
        carryForwardBalances[findIndex] = forwardBalance;
      else {
        let temp =
          carryForwardBalances[carryForwardYears.indexOf(currentYear - 1)];
        carryForwardBalances[findIndex] = forwardBalance + temp;
      }
    } else {
      carryForwardYears.push(currentYear);
      if (currentYear === openingYear)
        carryForwardBalances.push(forwardBalance);
      else {
        let temp =
          carryForwardBalances[carryForwardYears.indexOf(currentYear - 1)];
        carryForwardBalances.push(forwardBalance + temp);
      }
    }

    let weeklyProducts = this.addBalancesByWeek(
      categoriesList.length,
      minimumWeekNumber,
      amount
    );

    this.setState({
      weeklyBalances: categoryWeeklySums,
      tableHeaders: tempHeaders,
      transactionData: weeklyProducts,
      showEntry: false
    });
  };

  addBalancesByWeek(quantity, minWeek, amount) {
    let currentYear = this.props.selectedYear;
    let subCategoryData = this.props.subCategoryData.slice(0);

    let weeklyProducts = [];
    for (let i = 0; i < quantity; i++) {
      weeklyProducts.push({
        category: subCategoryData[i].name,
        id: i,
        expand: subCategoryData[i].sub
      });
      for (let j = 0; j < 53; j++) {
        let newKey = `week_${j + 1}`;
        weeklyProducts[i][newKey] = categoryWeeklySums[i].data[j];
      }
    }

    let modified = [];
    let carryFWeekly = 0;
    weeklyProducts.push({
      category: "Monthly Total",
      id: "sum"
    });
    if (currentYear !== openingYear) {
      let previousYear = currentYear - 1;
      let findIndex = carryForwardYears.indexOf(previousYear);
      carryFWeekly = carryForwardBalances[findIndex];
    }

    for (let j = 0; j < grandTotalWeekly.length; j++) {
      if (j === 0) modified.push(grandTotalWeekly[j] + carryFWeekly);
      else modified.push(grandTotalWeekly[j] + modified[j - 1]);

      let newKey = `week_${j + 1}`;
      weeklyProducts[quantity][newKey] = grandTotalWeekly[j];
    }

    if (currentYear === openingYear) {
      for (let i = 0; i < modified.length; i++) {
        modified[i] += amount;
      }
    }

    weeklyProducts.push({
      category: "Cash Available",
      id: "total"
    });

    for (let j = 0; j < modified.length; j++) {
      let newKey = `week_${j + 1}`;
      weeklyProducts[quantity + 1][newKey] = modified[j];
    }

    weeklyProducts.push({
      category: "Opening",
      id: "opening"
    });

    for (let j = 0; j < modified.length; j++) {
      let newKey = `week_${j + 1}`;
      weeklyProducts[quantity + 2][newKey] = "";
    }
    let newKey = `week_${minWeek}`;
    if (currentYear === openingYear) {
      weeklyProducts[quantity + 2][newKey] = amount;
    }

    return weeklyProducts;
  }

  addEntriesByWeek(quantity) {
    let entries = [];
    for (let i = 0; i < quantity; i++) {
      entries.push({
        pic: ">",
        subc: `Entry ${i}`,
        id: i
      });
      for (let j = 0; j < 53; j++) {
        let newKey = `week_${j + 1}`;
        entries[i][newKey] = Math.floor(Math.random() * 10);
      }
    }
    return entries;
  }

  distributeBalancesByMonth = allData => {
    let categoriesList = this.props.categoriesList.slice();
    let amountList = [];
    grandTotalMonthly = [];
    for (let i = 0; i < 12; i++) {
      grandTotalMonthly.push(0);
      amountList.push(0);
    }

    categoryMonthlySums = categoriesList.map(item => {
      return { category: item, data: amountList.slice() };
    });

    let numberCategories = categoriesList.length;
    let minimumDate = this.props.openingDate;
    let minimumMonthNumber = moment(minimumDate, "YYYY-MM-DD").month();
    let currentYear = this.props.selectedYear;

    let allMonths = _tableMonthHeaders;
    let tempHeaders = [];
    if (openingYear !== currentYear) {
      for (let i = 0; i < 12; i++) {
        tempHeaders.push(allMonths[i]);
      }
    } else {
      for (let i = minimumMonthNumber; i < 12; i++) {
        tempHeaders.push(allMonths[i]);
      }
    }

    for (let k = 0; k < numberCategories; k++) {
      for (let i = allData.length - 1; i >= 0; i--) {
        let date = allData[i].start_date;
        let monthNumber = Number(date.substring(5, 7));
        let year = +date.substring(0, 4);

        if (year === currentYear) {
          if (categoryMonthlySums[k].category === allData[i].category) {
            let amountToAdd = parseFloat(allData[i].amount);
            let preAmount = categoryMonthlySums[k].data[monthNumber - 1];
            let newAmount = preAmount + amountToAdd;

            categoryMonthlySums[k].data[monthNumber - 1] = newAmount;

            let preTotal = grandTotalMonthly[monthNumber - 1];
            let newTotal = preTotal + amountToAdd;
            grandTotalMonthly[monthNumber - 1] = newTotal;
            allData.splice(i, 1);
          }
        }
      }
    }

    let amount = 0;
    for (let i = 0, len = this.props.scenarios.length; i < len; i++) {
      if (this.props.currentScenario.includes(this.props.scenarios[i].name)) {
        amount += +this.props.scenarios[i].opening;
      }
    }

    let amountTotalForYear = grandTotalMonthly.reduce((a, b) => a + b, 0);
    let forwardBalance = amountTotalForYear;
    if (currentYear === openingYear)
      forwardBalance = amountTotalForYear + amount;

    if (carryForwardYears.includes(currentYear)) {
      let findIndex = carryForwardYears.indexOf(currentYear);
      if (currentYear === openingYear)
        carryForwardBalances[findIndex] = forwardBalance;
      else {
        let temp =
          carryForwardBalances[carryForwardYears.indexOf(currentYear - 1)];
        carryForwardBalances[findIndex] = forwardBalance + temp;
      }
    } else {
      carryForwardYears.push(currentYear);
      if (currentYear === openingYear)
        carryForwardBalances.push(forwardBalance);
      else {
        let temp =
          carryForwardBalances[carryForwardYears.indexOf(currentYear - 1)];
        carryForwardBalances.push(forwardBalance + temp);
      }
    }

    let monthlyProducts = this.addProducts(
      categoriesList.length,
      minimumMonthNumber,
      amount
    );

    this.setState({
      monthlyBalances: categoryMonthlySums,
      tableHeaders: tempHeaders,
      transactionData: monthlyProducts,
      showEntry: false
    });
  };

  addProducts(quantity, minMonth, amount) {
    let currentYear = this.props.selectedYear;
    let subCategoryData = this.props.subCategoryData.slice(0);

    let products = [];
    for (let i = 0; i < quantity; i++) {
      products.push({
        category: subCategoryData[i].name,
        id: i,
        jan: categoryMonthlySums[i].data[0],
        feb: categoryMonthlySums[i].data[1],
        mar: categoryMonthlySums[i].data[2],
        apr: categoryMonthlySums[i].data[3],
        may: categoryMonthlySums[i].data[4],
        jun: categoryMonthlySums[i].data[5],
        jul: categoryMonthlySums[i].data[6],
        aug: categoryMonthlySums[i].data[7],
        sep: categoryMonthlySums[i].data[8],
        oct: categoryMonthlySums[i].data[9],
        nov: categoryMonthlySums[i].data[10],
        dec: categoryMonthlySums[i].data[11],
        expand: subCategoryData[i].sub
      });
    }

    let modified = [];
    let carryFMonthly = 0;
    if (currentYear !== openingYear) {
      let previousYear = currentYear - 1;
      let findIndex = carryForwardYears.indexOf(previousYear);
      carryFMonthly = carryForwardBalances[findIndex];
    }

    for (let j = 0; j < grandTotalMonthly.length; j++) {
      if (j === 0) modified.push(grandTotalMonthly[j] + carryFMonthly);
      else modified.push(grandTotalMonthly[j] + modified[j - 1]);
    }

    if (currentYear === openingYear) {
      for (let i = 0; i < modified.length; i++) {
        modified[i] += amount;
      }
    }

    products.push({
      category: "Monthly Total",
      id: "sum",
      jan: grandTotalMonthly[0],
      feb: grandTotalMonthly[1],
      mar: grandTotalMonthly[2],
      apr: grandTotalMonthly[3],
      may: grandTotalMonthly[4],
      jun: grandTotalMonthly[5],
      jul: grandTotalMonthly[6],
      aug: grandTotalMonthly[7],
      sep: grandTotalMonthly[8],
      oct: grandTotalMonthly[9],
      nov: grandTotalMonthly[10],
      dec: grandTotalMonthly[11]
    });

    products.push({
      category: "Cash Available",
      id: "total",
      jan: modified[0],
      feb: modified[1],
      mar: modified[2],
      apr: modified[3],
      may: modified[4],
      jun: modified[5],
      jul: modified[6],
      aug: modified[7],
      sep: modified[8],
      oct: modified[9],
      nov: modified[10],
      dec: modified[11]
    });

    let keyValue = _getMonthNameId(minMonth + 1);
    products.push({
      category: "Opening",
      id: "opening",
      jan: "",
      feb: "",
      mar: "",
      apr: "",
      may: "",
      jun: "",
      jul: "",
      aug: "",
      sep: "",
      oct: "",
      nov: "",
      dec: ""
    });

    if (currentYear === openingYear) {
      products[quantity + 2][keyValue] = amount;
    }
    return products;
  }

  addEntries(quantity) {
    let entries = [];
    for (let i = 0; i < quantity; i++) {
      entries.push({
        pic: ">",
        subc: `Entry ${i}`,
        id: i
      });
      for (let j = 0; j < 12; j++) {
        let newKey = `month${j + 1}`;
        entries[i][newKey] = Math.floor(Math.random() * 23);
      }
    }
    return entries;
  }

  distributeBalancesByYear = allData => {
    let categoriesList = this.props.categoriesList.slice();
    let amountList = [];
    grandTotalYearly = [];
    for (let i = 0; i < 15; i++) {
      grandTotalYearly.push(0);
      amountList.push(0);
    }

    categoryYearlySums = categoriesList.map(item => {
      return { category: item, data: amountList.slice() };
    });

    let numberCategories = categoriesList.length;

    for (let k = 0; k < numberCategories; k++) {
      for (let i = allData.length - 1; i >= 0; i--) {
        let date = allData[i].start_date;
        let year = date.substring(0, 4);
        let yearNumber = Number(year) - 2015;

        if (categoryYearlySums[k].category === allData[i].category) {
          let amountToAdd = parseFloat(allData[i].amount);
          let preAmount = categoryYearlySums[k].data[yearNumber];
          let newAmount = preAmount + amountToAdd;
          categoryYearlySums[k].data[yearNumber] = newAmount;

          let preTotal = grandTotalYearly[yearNumber];
          let newTotal = preTotal + amountToAdd;
          grandTotalYearly[yearNumber] = newTotal;
          allData.splice(i, 1);
        }
      }
    }

    let currentYearCode = openingYear - 2000;
    let tempHeaders = [];
    for (let i = currentYearCode; i < 30; i++) {
      tempHeaders.push({ id: `year_${i}`, content: `20${i}` });
    }

    let yearlyProducts = this.addBalancesByYear(
      categoriesList.length,
      currentYearCode
    );
    this.setState({
      yearlyBalances: categoryYearlySums,
      tableHeaders: tempHeaders,
      transactionData: yearlyProducts,
      showEntry: false
    });
  };

  addBalancesByYear(quantity, minYear) {
    let amount = 0;
    for (let i = 0, len = this.props.scenarios.length; i < len; i++) {
      if (this.props.currentScenario.includes(this.props.scenarios[i].name)) {
        amount += +this.props.scenarios[i].opening;
      }
    }

    let subCategoryData = this.props.subCategoryData.slice(0);
    let yearlyProducts = [];
    for (let i = 0; i < quantity; i++) {
      yearlyProducts.push({
        category: subCategoryData[i].name,
        id: i,
        expand: subCategoryData[i].sub
      });
      for (let j = 0; j < 15; j++) {
        let newKey = `year_${j + 15}`;
        yearlyProducts[i][newKey] = categoryYearlySums[i].data[j];
      }
    }

    yearlyProducts.push({
      category: "Monthly Total",
      id: "sum"
    });
    let yearlyModified = [];
    for (let j = 0; j < grandTotalYearly.length; j++) {
      if (j === 0) yearlyModified.push(grandTotalYearly[j]);
      else yearlyModified.push(grandTotalYearly[j] + yearlyModified[j - 1]);

      let newKey = `year_${j + 15}`;
      yearlyProducts[quantity][newKey] = grandTotalYearly[j];
    }

    yearlyProducts.push({
      category: "Cash Available",
      id: "total"
    });
    let modified = [];
    for (let j = 0; j < grandTotalYearly.length; j++) {
      if (j === 0) modified.push(grandTotalYearly[j] + amount);
      else modified.push(grandTotalYearly[j] + modified[j - 1]);

      let newKey = `year_${j + 15}`;
      yearlyProducts[quantity + 1][newKey] = modified[j];
    }

    yearlyProducts.push({
      category: "Opening",
      id: "opening"
    });
    let newKey = `year_${minYear}`;
    yearlyProducts[quantity + 2][newKey] = amount;

    return yearlyProducts;
  }

  addEntriesByYear(quantity) {
    let entries = [];
    for (let i = 0; i < quantity; i++) {
      entries.push({
        pic: ">",
        subc: `Entry ${i}`,
        id: i
      });
      for (let j = 0; j < 15; j++) {
        let newKey = `year_${j + 15}`;
        entries[i][newKey] = Math.floor(Math.random() * 10);
      }
    }
    return entries;
  }

  openEntries = (category, categoryId) => {
    setTimeout(
      () =>
        this.setState({
          leftTableWidth: this.leftRef.clientWidth
        }),
      10
    );

    let sortedTransaction = this.props.transactionData.filter(
      item => item.transactionType === "S" || item.transactionType === "X"
    );

    let displayEntry = !this.state.showEntry;

    this.setState({
      showEntry: displayEntry,
      selectedCategoryId: categoryId,
      subTrans: sortedTransaction
    });
  };

  chooseOptionsHandler = () => {
    let newOption = this.state.showAddOptions;
    this.setState({ showAddOptions: !newOption });
  };

  cancelAddNew = () => {
    this.setState({ showAddOptions: false });
  };

  /************** SUB CATEGORY TRANSACTION ************/
  editListHandler = (subDetails, isInvoices) => {
    if (isInvoices) {
      fetch(`${connectingBaseLink}lists/readList.php`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          user: getActiveUser(),
          opening: this.props.openingDate,
          subId: subDetails.subId
        })
      })
        .then(response => response.json())
        .then(result => {
          let data = result.message;
          if (data.constructor === Array) {
            this.setState({
              showNewSubEdit: true,
              subListData: subDetails,
              listData: data
            });
          }
        });
    } else {
      const subData = {
        scenario: subDetails.scenario,
        category: subDetails.category,
        description: `${subDetails.name}`,
        transactionType: "S",
        typeId: subDetails.subId,
        frequency_id: "f0",
        frequency_type: "One Time",
        end_type_id: 1,
        millis: new Date().getTime(),
        xtrans: null
      };
      this.props.openTransactionForm("add", subData);
      this.setState({
        showNewSubTrans: true
      });
    }
  };

  addSubTransactionCancel = () => {
    this.props.clearTransactionForm();
    this.setState({ showNewSubTrans: false });
  };

  editListConfirm = () => {
    window.location.reload();
    this.setState({ showNewSubEdit: false });
  };

  editListCancel = () => {
    this.setState({ showNewSubEdit: false });
  };

  /************** SUB CATEGORY EDIT & DELETE ************/
  editSubHandler = data => {
    this.setState({ editSub: true, selectedSub: data });
  };

  editSubConfirm = (event, newName) => {
    event.preventDefault();
    let scenarioId = this.props.scenarios.filter(
      item => item.name === this.state.selectedSub.scenario
    );
    scenarioId = scenarioId[0].id;
    let selectedSub = this.state.selectedSub;

    this.props.editSubCategory(
      selectedSub.subId,
      newName,
      scenarioId,
      selectedSub.category,
      this.props.activeScIds
    );
    this.setState({ editSub: false });
  };

  editSubCancel = () => {
    this.setState({ editSub: false });
  };

  deleteSubHandler = data => {
    subToBeDeleted = data;
    this.setState({
      showConfirmation: true
    });
  };

  deleteSubCancel = () => {
    this.setState({
      showConfirmation: false
    });
  };

  deleteSubConfirm = () => {
    this.props.deleteSubCategory(subToBeDeleted, this.props.activeScIds);
    this.setState({ showConfirmation: false });
  };

  /**************** React Tour **********************/
  closeTour = () => {
    this.setState({ run: false });
    this.props.continueGuidedTour(6);
  };

  getCurrentStep = data => {
    if (data === 1) {
      this.setState({ run: false });
      this.props.continueGuidedTour(5);
    }
  };

  render() {
    const {
      showEntry,
      selectedCategoryId,
      tableHeaders,
      transactionData,
      subListData,
      listData,
      width,
      steps,
      run
    } = this.state;

    const { selectedOption } = this.props;

    let sampleData = [];
    for (let i = 0; i < 12; i++) {
      sampleData.push(0);
    }

    return (
      <Fragment>
        <Modal
          classNames={{ modal: "modal-edit-list" }}
          center
          open={this.state.showNewSubEdit}
          onClose={this.editListCancel}
        >
          <EditSubList
            editListCancel={this.editListCancel}
            editListConfirm={this.editListConfirm}
            subListData={subListData}
            listData={listData}
            invalidData={this.state.invalidData}
            scenarios={this.props.scenarios}
            openingDate={this.props.openingDate}
          />
        </Modal>

        <Modal
          open={this.state.editSub}
          center
          onClose={this.editSubCancel}
          classNames={{ modal: "modal-edit-sub" }}
        >
          <EditSub
            subName={this.state.selectedSub}
            editSubCancel={this.editSubCancel}
            editSubConfirm={this.editSubConfirm}
          />
        </Modal>

        <Modal
          open={this.state.showConfirmation}
          classNames={{ modal: "modal-confirm-dialog" }}
          center
          onClose={this.deleteSubCancel}
        >
          <ConfirmationDialog
            dialogMessage={
              "Are you sure you want to delete this sub-category? The transactions related to this sub-category will not be deleted though. For deleting them, use option available in the Transactions table."
            }
            dialogPositive={"Cancel"}
            dialogNegative={"Delete"}
            onDeleteConfirmClicked={this.deleteSubCancel}
            onDeleteCancelClicked={this.deleteSubConfirm}
          />
        </Modal>

        <Modal
          classNames={{ modal: "modal-transactions" }}
          center
          open={this.state.showNewSubTrans}
          onClose={this.addSubTransactionCancel}
        >
          <AddForm
            scenarioIds={this.props.activeScIds}
            scenarios={this.props.scenarios}
            currentScenario={this.props.currentScenario}
            newTransactionCancelled={this.addSubTransactionCancel}
            newTransactionEdited={this.addSubTransactionCancel}
            openingDate={this.props.openingDate}
          />
        </Modal>

        <Tour
          steps={steps}
          isOpen={run}
          showNavigationNumber={false}
          showNumber={false}
          showNavigation={false}
          prevButton={<div style={{ display: "none" }} />}
          nextButton={<div className={"tour-next-button"}>Next</div>}
          onRequestClose={this.closeTour}
          accentColor="#365478"
          getCurrentStep={this.getCurrentStep}
        />

        {width >= 900 ? (
          <div id="category-table" className="table-container">
            <table className="left-table" id="category_names">
              <thead className="header-container">
                <tr>
                  <th />
                  <th className="category-header">Categories</th>
                </tr>
              </thead>

              <tbody className="body-container">
                {transactionData.map(item => {
                  return (
                    <Fragment key={item.id}>
                      <tr
                        className={
                          item.id === "total" ||
                          item.id === "opening" ||
                          item.id === "sum"
                            ? "disable-interactions"
                            : "blank"
                        }
                      >
                        {item.id !== "total" &&
                        item.id !== "opening" &&
                        item.id !== "sum" ? (
                          <td
                            className="plus-icon"
                            onClick={() =>
                              this.openEntries(item.category, item.id)
                            }
                          >
                            <i className="fas fa-plus" />
                          </td>
                        ) : (
                          <td
                            className={
                              item.id === "total"
                                ? "row-total"
                                : item.id === "sum"
                                ? "row-grand"
                                : "none"
                            }
                          />
                        )}
                        <td
                          className={
                            item.id === "total"
                              ? "row-total"
                              : item.id === "sum"
                              ? "row-grand"
                              : "category-header"
                          }
                        >
                          <div
                            onClick={() =>
                              this.props.onCategoryClicked(item.category)
                            }
                          >
                            {item.category}
                          </div>
                        </td>
                      </tr>
                      {showEntry && item.id === selectedCategoryId ? (
                        <Fragment>
                          <tr>
                            <td
                              className="entries-table-container"
                              colSpan={tableHeaders.length + 2}
                            >
                              <TempTable
                                editListHandler={this.editListHandler}
                                editSubHandler={this.editSubHandler}
                                deleteSubHandler={this.deleteSubHandler}
                                category={item.category}
                                entriesData={transactionData[item.id].expand}
                                displayedColumns={tableHeaders.length}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td className="no-border" />
                            <td
                              className="no-border"
                              colSpan={tableHeaders.length + 1}
                              style={{
                                textAlign: "left"
                              }}
                            >
                              <button
                                onClick={() =>
                                  this.props.addSubHandler(item.category)
                                }
                                className="add-sub"
                              >
                                Add New Subcategory
                              </button>
                            </td>
                          </tr>
                        </Fragment>
                      ) : null}
                    </Fragment>
                  );
                })}
              </tbody>
            </table>

            <table
              className="right-table"
              // style={{ marginLeft: this.state.width < 900 ? 0 : leftTableWidth }}
            >
              <thead className="header-container week-header">
                <tr>
                  {tableHeaders.map(item => {
                    return (
                      <th
                        key={item.id}
                        title={item.content}
                        style={{
                          fontSize: selectedOption === "W" ? "1.0em" : "1.2em"
                        }}
                      >
                        {item.content}
                      </th>
                    );
                  })}
                </tr>
              </thead>

              <tbody className="body-container">
                {transactionData.map(item => {
                  return (
                    <Fragment key={item.id}>
                      <tr
                        className={
                          item.id === "total" ||
                          item.id === "opening" ||
                          item.id === "sum"
                            ? "disable-interactions"
                            : "blank"
                        }
                      >
                        {tableHeaders.map(header => {
                          let value = header.id;
                          if (item.id !== "opening")
                            return (
                              <td
                                key={header.id}
                                className={
                                  item.id === "total"
                                    ? "row-total"
                                    : item.id === "sum"
                                    ? "row-grand"
                                    : "column-data"
                                }
                                onClick={() =>
                                  this.props.columnDataClicked(
                                    item,
                                    header.id,
                                    selectedOption
                                  )
                                }
                              >
                                {formatDecimal(item[value], 2)}
                              </td>
                            );
                          else
                            return (
                              <td key={header.id} className="column-data">
                                {item[value] !== ""
                                  ? formatDecimal(item[value], 2)
                                  : "."}
                              </td>
                            );
                        })}
                      </tr>
                      {showEntry && item.id === selectedCategoryId ? (
                        <Fragment>
                          <tr>
                            <td
                              className="entries-table-container"
                              colSpan={tableHeaders.length + 2}
                            >
                              <SubDataTable
                                selectedYear={this.props.selectedYear}
                                subTrans={this.state.subTrans}
                                selectedOption={selectedOption}
                                editSubHandler={this.editSubHandler}
                                deleteSubHandler={this.deleteSubHandler}
                                category={item.category}
                                entriesData={transactionData[item.id].expand}
                                displayedColumns={tableHeaders.length}
                                openingDate={this.props.openingDate}
                              />
                            </td>
                          </tr>
                          <tr
                            className="entry"
                            style={{ opacity: "0", visibility: "hidden" }}
                          >
                            <td className="no-border" />
                            <td
                              className="no-border"
                              colSpan={tableHeaders.length + 1}
                            >
                              <button
                                onClick={() => this.chooseOptionsHandler()}
                                className="add-new"
                              >
                                + Add
                              </button>
                            </td>
                          </tr>
                        </Fragment>
                      ) : null}
                    </Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="table-container" id="category-table">
            {/* <table
							ref={divElement => (this.leftRef = divElement)}
							style={{ width: "40rem", opacity: "0", visibility: "hidden", position: "absolute" }}
						/> */}
            <table className="left-table" id="category_names">
              <thead className="header-container">
                <tr>
                  <th />
                  <th className={"category-header"}>
                    <div>Categories</div>
                  </th>
                  {tableHeaders.map(item => {
                    return (
                      <th
                        key={item.id}
                        style={{
                          fontSize: selectedOption === "W" ? "1.0em" : "1.2em"
                        }}
                      >
                        {item.content}
                      </th>
                    );
                  })}
                </tr>
              </thead>

              <tbody className="body-container">
                {transactionData.map(item => {
                  return (
                    <Fragment key={item.id}>
                      <tr
                        className={
                          item.id === "total" ||
                          item.id === "opening" ||
                          item.id === "sum"
                            ? "disable-interactions"
                            : "blank"
                        }
                      >
                        {item.id !== "total" &&
                        item.id !== "opening" &&
                        item.id !== "sum" ? (
                          <td
                            className="plus-icon"
                            onClick={() =>
                              this.openEntries(item.category, item.id)
                            }
                          >
                            <i className="fas fa-plus" />
                          </td>
                        ) : (
                          <td
                            className={
                              item.id === "total"
                                ? "row-total"
                                : item.id === "sum"
                                ? "row-grand"
                                : "none"
                            }
                          />
                        )}
                        <td
                          className={
                            item.id === "total"
                              ? "row-total"
                              : item.id === "sum"
                              ? "row-grand"
                              : "category-header"
                          }
                        >
                          <div
                            onClick={() =>
                              this.props.onCategoryClicked(item.category)
                            }
                          >
                            {item.category}
                          </div>
                        </td>
                        {tableHeaders.map(header => {
                          let value = header.id;
                          if (item.id !== "opening")
                            return (
                              <td
                                key={header.id}
                                className={
                                  item.id === "total"
                                    ? "row-total"
                                    : item.id === "sum"
                                    ? "row-grand"
                                    : "column-data"
                                }
                                onClick={() =>
                                  this.props.columnDataClicked(
                                    item,
                                    header.id,
                                    selectedOption
                                  )
                                }
                              >
                                {formatDecimal(item[value], 2)}
                              </td>
                            );
                          else
                            return (
                              <td key={header.id} className="column-data">
                                {item[value] !== ""
                                  ? formatDecimal(item[value], 2)
                                  : "."}
                              </td>
                            );
                        })}
                      </tr>
                      {showEntry && item.id === selectedCategoryId ? (
                        <Fragment>
                          <tr>
                            <td className="no-border" />
                            <td
                              className="entries-table-container"
                              // colSpan={tableHeaders.length + 2}
                            >
                              <TempTable
                                editListHandler={this.editListHandler}
                                editSubHandler={this.editSubHandler}
                                deleteSubHandler={this.deleteSubHandler}
                                category={item.category}
                                entriesData={transactionData[item.id].expand}
                                displayedColumns={tableHeaders.length}
                              />
                            </td>
                            <td
                              className="entries-table-container"
                              colSpan={tableHeaders.length}
                            >
                              <SubDataTable
                                selectedYear={this.props.selectedYear}
                                subTrans={this.state.subTrans}
                                selectedOption={selectedOption}
                                editSubHandler={this.editSubHandler}
                                deleteSubHandler={this.deleteSubHandler}
                                category={item.category}
                                entriesData={transactionData[item.id].expand}
                                displayedColumns={tableHeaders.length}
                                openingDate={this.props.openingDate}
                              />
                            </td>
                          </tr>

                          <tr>
                            {/* <td className="no-border" /> */}
                            <td />
                            <td
                              className="no-border"
                              // colSpan={tableHeaders.length + 1}
                              style={{
                                textAlign: "left"
                              }}
                            >
                              <button
                                onClick={() =>
                                  this.props.addSubHandler(item.category)
                                }
                                className="add-sub"
                              >
                                Add New Subcategory
                              </button>
                            </td>
                          </tr>
                        </Fragment>
                      ) : null}
                    </Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  ui: state.ui
});

export default connect(
  mapStateToProps,
  {
    editSubCategory,
    deleteSubCategory,
    continueGuidedTour,
    openTransactionForm,
    clearTransactionForm
  }
)(CategoryTable);
