import React, { Component } from "react";
import { Bar } from "react-chartjs-2";

// let graphRendered = false;

class BarGraph extends Component {
	constructor(props) {
		super(props);
		this.state = {
			chartData: {},
			isHovering: false,
			amounts: [],
			descriptions: []
		};
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			amounts: nextProps.amounts,
			descriptions: nextProps.descriptions,
			chartData: {
				labels: nextProps.descriptions,
				datasets: [
					{
						label: "Amount by Description",
						backgroundColor: "rgba(91, 123, 160, 0.3)",
						borderColor: "rgba(91, 123, 160, 0.70)",
						pointBorderColor: "rgba(91, 123, 160, 0.70)",
						pointBackgroundColor: "rgba(91, 123, 160, 0.70)",
						pointHoverBackgroundColor: "#fff",
						pointHoverBorderColor: "rgba(151,187,205,1)",
						pointBorderWidth: 1,
						data: nextProps.amounts
					}
				]
			}
		});
		// graphRendered = true;
	}

	// componentDidUpdate() {
	// 	if (graphRendered) {
	// 		this.setState({
	// 			amounts: this.props.amounts,
	// 			descriptions: this.props.descriptions,
	// 			chartData: {
	// 				labels: this.props.descriptions,
	// 				datasets: [
	// 					{
	// 						label: "Amount by Description",
	// 						backgroundColor: "rgba(91, 123, 160, 0.3)",
	// 						borderColor: "rgba(91, 123, 160, 0.70)",
	// 						pointBorderColor: "rgba(91, 123, 160, 0.70)",
	// 						pointBackgroundColor: "rgba(91, 123, 160, 0.70)",
	// 						pointHoverBackgroundColor: "#fff",
	// 						pointHoverBorderColor: "rgba(151,187,205,1)",
	// 						pointBorderWidth: 1,
	// 						data: this.props.amounts
	// 					}
	// 				]
	// 			}
	// 		});
	// 		graphRendered = false;
	// 	}
	// }

	render() {
		const { chartData } = this.state;
		let min = this.props.barMax * -1.2;
		let max = this.props.barMax * 1.2;
		let variableHeight = this.props.curveHeight;

		return (
			<div id="bar-graph" style={{ minHeight: variableHeight }}>
				<div className="panel-title">
					<h3>By Description</h3>
					<div className="clearfix" />
				</div>
				<div className="bar-wrapper">
					<Bar
						data={chartData}
						getElementAtEvent={elem => this.props.onBarClicked(elem)}
						options={{
							legend: {
								display: false,
								position: "right"
							},
							maintainAspectRatio: false,
							layout: {
								padding: {
									left: 10,
									top: 20
								}
							},
							tooltips: {
								enabled: false
							},
							scales: {
								yAxes: [
									{
										ticks: {
											suggestedMin: min,
											suggestedMax: max,
											fontSize: this.props.graphFontSize
										}
									}
								],
								xAxes: [
									{
										ticks: {
											fontSize: this.props.graphFontSize,
											maxRotation: 0,
											minRotation: 0
										}
									}
								]
							}
						}}
					/>
				</div>
			</div>
		);
	}
}

export default BarGraph;
