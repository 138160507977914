import moment from 'moment'

export const connectingBaseLink = process.env.REACT_APP_API_URL
export const connectingXeroLink = process.env.REACT_APP_XERO_URL

export const _storageActiveUser = 'storage_active_user'

let _categoryData = ''
export const setCategoryData = categoryData => {
  _categoryData = categoryData
}

export const getCategories = () => {
  return _categoryData
}

export const _openingCategory = 'Opening'

let _openingDate = moment(new Date()).format('YYYY-MM-DD')
export const _setOpeningDate = newDate => {
  _openingDate = newDate
}
export const _getOpeningDate = () => {
  return _openingDate
}

export const _getTodayDate = () => {
  return moment(new Date()).format('YYYY-MM-DD')
}

export const _getFirstDate = year => {
  return year + '-01-01'
}

export const _getLastDate = year => {
  return year + '-12-31'
}

let _decimalPlaces = 2
export const _setDecimal = value => {
  _decimalPlaces = value
}
export const _getDecimal = () => {
  return _decimalPlaces
}

/******** LOCAL STORAGE VALUES ************/
export const getActiveUser = () => {
  return JSON.parse(localStorage.getItem(_storageActiveUser))
}

let currencySymbol = '$'
export const setCurrency = currency => {
  currencySymbol = currency
}
export const _getCurrency = currencySymbol

export const _todayDate = moment().format('YYYY-MM-DD')

export const _selectedScenario = 'Main Account'

export const _minYearDefined = 2015
export const _maxYearDefined = 2029

export const _colorArray = [
  { id: 'color_1', color: '#365478b3' },
  { id: 'color_2', color: '#486890b3' },
  { id: 'color_3', color: '#5784b9b3' },
  { id: 'color_4', color: '#782235b3' },
  { id: 'color_5', color: '#880909b3' },
  { id: 'color_6', color: '#98a4b2b3' },
  { id: 'color_7', color: '#45d1d8b3' },
  { id: 'color_8', color: '#367864b3' },
  { id: 'color_9', color: '#537836b3' },
  { id: 'color_10', color: '#747836b3' },
  { id: 'color_11', color: '#ac8d54b3' },
  { id: 'color_12', color: '#ac5454b3' },
  { id: 'color_13', color: '#454545b3' },
  { id: 'color_14', color: '#9144a1b3' },
  { id: 'color_15', color: '#5d44a1b3' },
  { id: 'color_16', color: '#4464a1b3' },
  { id: 'color_17', color: '#98bc09b3' },
  { id: 'color_18', color: '#22bb98b3' },
  { id: 'color_19', color: '#2222feb3' },
  { id: 'color_20', color: '#fea000b3' }
]

let selectedColors = null

export const _setColorArray = colors => {
  selectedColors = colors
}

export const _getColorArray = () => {
  return selectedColors
}

export const _getMonthNameId = number => {
  if (number === 1) return 'jan'
  if (number === 2) return 'feb'
  if (number === 3) return 'mar'
  if (number === 4) return 'apr'
  if (number === 5) return 'may'
  if (number === 6) return 'jun'
  if (number === 7) return 'jul'
  if (number === 8) return 'aug'
  if (number === 9) return 'sep'
  if (number === 10) return 'oct'
  if (number === 11) return 'nov'
  if (number === 12) return 'dec'
}

export const _getMonthLocation = monthId => {
  if (monthId === 'jan') return '01'
  if (monthId === 'feb') return '02'
  if (monthId === 'mar') return '03'
  if (monthId === 'apr') return '04'
  if (monthId === 'may') return '05'
  if (monthId === 'jun') return '06'
  if (monthId === 'jul') return '07'
  if (monthId === 'aug') return '08'
  if (monthId === 'sep') return '09'
  if (monthId === 'oct') return '10'
  if (monthId === 'nov') return '11'
  if (monthId === 'dec') return '12'
}

export const _tableMonthHeaders = [
  { id: 'jan', content: 'January' },
  { id: 'feb', content: 'February' },
  { id: 'mar', content: 'March' },
  { id: 'apr', content: 'Apr' },
  { id: 'may', content: 'May' },
  { id: 'jun', content: 'June' },
  { id: 'jul', content: 'July' },
  { id: 'aug', content: 'August' },
  { id: 'sep', content: 'September' },
  { id: 'oct', content: 'October' },
  { id: 'nov', content: 'November' },
  { id: 'dec', content: 'December' }
]

let newTransactions = []
export const _expandTransactions = (
  scenarioId,
  category,
  categoryId,
  transactionType,
  typeId,
  description,
  date,
  amount,
  balance,
  frequencyId,
  frequencyType,
  endTypeId,
  endTypeValue,
  millis,
  reminder,
  reminderNote,
  xTrans
) => {
  newTransactions = []

  let modifiedDesc = description
  if (transactionType === 'S') {
    modifiedDesc = `${description} (${category})`
  }

  let transaction = {
    scenario_id: scenarioId,
    category_id: categoryId,
    transaction_type: transactionType,
    type_id: typeId,
    description: modifiedDesc,
    start_date: date,
    amount: amount,
    balance: balance,
    frequency_id: frequencyId,
    frequency_type: frequencyType,
    end_type_id: endTypeId,
    end_type_value: endTypeValue,
    millis: millis,
    reminder: reminder,
    reminder_note: reminderNote,
    xtrans: xTrans
  }

  addNewTransactionObject(transaction, transaction.start_date)

  switch (transaction.frequency_id) {
    case 'f1':
      if (transaction.end_type_id === '1') {
        let times = durationBetweenDateCalculator(
          transaction.end_type_value,
          transaction.start_date
        )
        repeatByDay(times, transaction.start_date, transaction, 1)
      } else if (transaction.end_type_id === '2') {
        repeatByDay(
          transaction.end_type_value - 1,
          transaction.start_date,
          transaction,
          1
        )
      } else if (transaction.end_type_id === '3') {
        repeatByDay(100, transaction.start_date, transaction, 1)
      }
      break

    case 'f2':
      if (transaction.end_type_id === '1') {
        let times =
          durationBetweenDateCalculator(
            transaction.end_type_value,
            transaction.start_date
          ) /
            7 -
          1
        repeatByDay(times, transaction.start_date, transaction, 7)
      } else if (transaction.end_type_id === '2') {
        repeatByDay(
          transaction.end_type_value - 1,
          transaction.start_date,
          transaction,
          7
        )
      } else if (transaction.end_type_id === '3') {
        repeatByDay(50, transaction.start_date, transaction, 7)
      }
      break

    case 'f3':
      if (transaction.end_type_id === '1') {
        let times =
          durationBetweenDateCalculator(
            transaction.end_type_value,
            transaction.start_date
          ) /
            14 -
          1
        repeatByDay(times, transaction.start_date, transaction, 14)
      } else if (transaction.end_type_id === '2') {
        repeatByDay(
          transaction.end_type_value - 1,
          transaction.start_date,
          transaction,
          14
        )
      } else if (transaction.end_type_id === '3') {
        repeatByDay(30, transaction.start_date, transaction, 14)
      }
      break

    case 'f4':
      if (transaction.end_type_id === '1') {
        let times =
          durationBetweenMonthsCalculator(
            transaction.end_type_value,
            transaction.start_date
          ) - 1

        repeatByMonth(times, transaction.start_date, transaction, 1)
      } else if (transaction.end_type_id === '2') {
        repeatByMonth(
          transaction.end_type_value - 1,
          transaction.start_date,
          transaction,
          1
        )
      } else if (transaction.end_type_id === '3') {
        repeatByMonth(50, transaction.start_date, transaction, 1)
      }
      break

    case 'f5':
      if (transaction.end_type_id === '1') {
        let times =
          durationBetweenMonthsCalculator(
            transaction.end_type_value,
            transaction.start_date
          ) /
            3 -
          1

        repeatByMonth(times, transaction.start_date, transaction, 3)
      } else if (transaction.end_type_id === '2') {
        repeatByMonth(
          transaction.end_type_value - 1,
          transaction.start_date,
          transaction,
          3
        )
      } else if (transaction.end_type_id === '3') {
        repeatByMonth(30, transaction.start_date, transaction, 3)
      }
      break

    case 'f6':
      if (transaction.end_type_id === '1') {
        let times =
          durationBetweenMonthsCalculator(
            transaction.end_type_value,
            transaction.start_date
          ) /
            6 -
          1

        repeatByMonth(times, transaction.start_date, transaction, 6)
      } else if (transaction.end_type_id === '2') {
        repeatByMonth(
          transaction.end_type_value - 1,
          transaction.start_date,
          transaction,
          6
        )
      } else if (transaction.end_type_id === '3') {
        repeatByMonth(20, transaction.start_date, transaction, 6)
      }
      break

    case 'f7':
      if (transaction.end_type_id === '1') {
        let times = durationBetweenYearsCalculator(
          transaction.end_type_value,
          transaction.start_date
        )

        repeatByYear(times, transaction.start_date, transaction, 1)
      } else if (transaction.end_type_id === '2') {
        repeatByYear(
          transaction.end_type_value - 1,
          transaction.start_date,
          transaction,
          1
        )
      } else if (transaction.end_type_id === '3') {
        repeatByYear(50, transaction.start_date, transaction, 1)
      }
      break

    case 'f8':
      let gap = Number(transaction.frequency_type.substring(1))
      let firstCharacter = transaction.frequency_type.charAt(0)
      if (transaction.end_type_id === '1') {
        let endTypeValue = transaction.end_type_value
        if (firstCharacter === 'D') {
          let times =
            durationBetweenDateCalculator(
              endTypeValue,
              transaction.start_date
            ) /
              gap -
            1
          repeatByDay(times, transaction.start_date, transaction, gap)
        } else if (firstCharacter === 'M') {
          let times =
            durationBetweenMonthsCalculator(
              endTypeValue,
              transaction.start_date
            ) /
              gap -
            1
          repeatByMonth(times, transaction.start_date, transaction, gap)
        } else if (firstCharacter === 'Y') {
          let times =
            durationBetweenYearsCalculator(
              endTypeValue,
              transaction.start_date
            ) /
              gap -
            1
          repeatByYear(times, transaction.start_date, transaction, gap)
        }
      } else if (transaction.end_type_id === '2') {
        let endTypeValue = Number(transaction.end_type_value) - 1
        if (firstCharacter === 'D') {
          repeatByDay(endTypeValue, transaction.start_date, transaction, gap)
        } else if (firstCharacter === 'M') {
          repeatByMonth(endTypeValue, transaction.start_date, transaction, gap)
        } else if (firstCharacter === 'Y') {
          repeatByYear(endTypeValue, transaction.start_date, transaction, gap)
        }
      } else if (transaction.end_type_id === '3') {
        if (firstCharacter === 'D') {
          repeatByDay(100, transaction, gap)
        } else if (firstCharacter === 'M') {
          repeatByMonth(50, transaction.start_date, transaction, gap)
        } else if (firstCharacter === 'Y') {
          repeatByYear(20, transaction.start_date, transaction, gap)
        }
      }
      break
    default:
    // do nothing
  }
  newTransactions = newTransactions.map(item => {
    if (item.reminder === 'calculate') item.reminder = item.start_date
    return item
  })
  return newTransactions
}

const durationBetweenDateCalculator = (date2, date1) => {
  let endDate = moment(date2, 'YYYY-MM-DD')
  let startDate = moment(date1, 'YYYY-MM-DD')
  return endDate.diff(startDate, 'days')
}

const durationBetweenMonthsCalculator = (date2, date1) => {
  let endDateMonth = Number(moment(date2).format('MM'))
  let endDateYear = Number(moment(date2).format('YYYY'))
  let startDateMonth = Number(moment(date1).format('MM'))
  let startDateYear = Number(moment(date1).format('YYYY'))
  return endDateMonth + endDateYear * 12 - (startDateMonth + startDateYear * 12)
}

const durationBetweenYearsCalculator = (date2, date1) => {
  let endDateMonth = Number(moment(date2).format('MM'))
  let endDateYear = Number(moment(date2).format('YYYY'))
  let startDateMonth = Number(moment(date1).format('MM'))
  let startDateYear = Number(moment(date1).format('YYYY'))
  if (endDateMonth >= startDateMonth) {
    return endDateYear - startDateYear
  } else {
    return endDateYear - startDateYear - 1
  }
}

const repeatByDay = (repetition, date, transaction, gap) => {
  if (repetition < 0) {
    repetition *= -1
  }
  for (let i = 0; i < repetition; i++) {
    let newMoment = moment(date).add((i + 1) * gap, 'd')
    let newDate = moment(newMoment).format('YYYY-MM-DD')
    addNewTransactionObject(transaction, newDate)
  }
}

const repeatByMonth = (repetition, date, transaction, gap) => {
  if (repetition < 0) {
    repetition *= -1
  }
  for (let i = 0; i < repetition; i++) {
    let newMoment = moment(date).add((i + 1) * gap, 'M')
    let newDate = moment(newMoment).format('YYYY-MM-DD')
    addNewTransactionObject(transaction, newDate)
  }
}

const repeatByYear = (repetition, date, transaction, gap) => {
  if (repetition < 0) {
    repetition *= -1
  }
  for (let i = 0; i < repetition; i++) {
    let newMoment = moment(date).add((i + 1) * gap, 'Y')
    let newDate = moment(newMoment).format('YYYY-MM-DD')
    addNewTransactionObject(transaction, newDate)
  }
}

const addNewTransactionObject = (transaction, date) => {
  const temporaryTransaction = {
    ...transaction,
    start_date: date
  }
  newTransactions.push(temporaryTransaction)
}
