import React, { Component, Fragment } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { _getCurrency, _getDecimal } from "../../helpers/constants";

const bootstrapColumns = [
	{
		dataField: "sNumber",
		text: "SR",
		sort: true,
		headerClasses: "header-serial"
	},
	{
		dataField: "start_date",
		text: "Date",
		sort: true,
		headerClasses: "header-date"
	},
	{
		dataField: "scenario",
		text: "Scenario",
		sort: true,
		headerClasses: "header-scenario"
	},
	{
		dataField: "description",
		text: "Description",
		sort: true,
		headerClasses: "header-desc"
	},
	{
		dataField: "category",
		text: "Category",
		sort: true,
		headerClasses: "header-category"
	},
	{
		dataField: "amount",
		text: "Amount",
		formatter: priceFormatter,
		sort: true,
		headerClasses: "header-amount"
	}
];

function priceFormatter(cell, row) {
	let tempValue = cell;
	if (tempValue < 0) cell = -1 * cell;
	let returnedValue = parseFloat(Math.round(cell + "e" + _getDecimal()) + "e-" + _getDecimal()).toFixed(
		_getDecimal()
	);
	let formattedValue = returnedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	if (isNaN(returnedValue)) return <span />;
	else if (tempValue < 0)
		return (
			<span>
				{_getCurrency} ({formattedValue})
			</span>
		);
	else
		return (
			<span>
				{_getCurrency} {formattedValue}
			</span>
		);
}

let selectedRow = null;
let selectedRowIndex = null;

class DescriptionDetails extends Component {
	state = {
		invoiceSelected: false
	};

	componentWillReceiveProps() {
		selectedRowIndex = null;
	}

	componentWillUnmount() {
		selectedRow = null;
		selectedRowIndex = null;
	}

	renderPanelButtons() {
		let panelButtons = (
			<Fragment>
				<div className="desc-buttons">
					<button className="desc-edit" onClick={() => this.props.rowInConcern("edit", selectedRow)}>
						Edit Transaction
					</button>
					<button className="desc-delete" onClick={() => this.props.rowInConcern("del", selectedRow)}>
						Delete Transaction
					</button>
				</div>
			</Fragment>
		);
		if (this.state.invoiceSelected) {
			panelButtons = (
				<div className="desc-buttons">
					<button className="desc-details" onClick={() => this.props.rowInConcern("view", selectedRow)}>
						View Invoice Details
					</button>
				</div>
			);
		}

		return panelButtons;
	}

	render() {
		return (
			<div id="descriptionDetails">
				<div className="desc-title">
					<h3>
						Details (Amounts By Description) for&nbsp; <strong>'{this.props.description}'</strong>
					</h3>
					{this.renderPanelButtons()}
					<div className="clearfix" />
				</div>
				<div className="desc-content">
					<BootstrapTable
						keyField="sNumber"
						data={this.props.transactions}
						columns={bootstrapColumns}
						classes="desc-table"
						wrapperClasses="desc-table-wrap"
						filter={filterFactory()}
						bordered
						hover
						noDataIndication="No Data for selected description"
						selectRow={{
							mode: "radio",
							selected: selectedRowIndex !== null ? [selectedRowIndex] : "",
							style: {
								background: "#ececec"
							},
							clickToSelect: true,
							hideSelectColumn: true,
							onSelect: row => {
								selectedRow = row;
								selectedRowIndex = row.sNumber;
								if (selectedRow.transactionType === "X" && selectedRow.typeId !== "0") {
									this.setState({ invoiceSelected: true });
								} else {
									if (this.state.invoiceSelected) this.setState({ invoiceSelected: false });
								}
							}
						}}
						pagination={paginationFactory()}
					/>
				</div>
			</div>
		);
	}
}

export default DescriptionDetails;
